import { CartaoContasService } from './../../../../shared/components/cartao-gestante/cartao-contas.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';
import { MedicoService } from '../../medico.service';
import { AlertsService } from 'app/shared/alerts.service';
import { CartaoGestante, ConsultaPaciente, Paciente } from 'app/core/interfaces/pacient-info.interface';
import { FormGroup, FormControl } from '@angular/forms';
import { ModalsService } from 'app/shared/modals.service';
import { Medico } from 'app/core/interfaces/medico.interface';
import { MatDialog } from '@angular/material';
import { ModalAdicionarExameComponent } from '../../components/modal-adicionar-exame/modal-adicionar-exame.component';
import { Events } from 'app/core/events';
import { MedicoEncerrarCartaoComponent } from '../medico-encerrar-cartao/medico-encerrar-cartao.component';
import { APIGestaCardService } from 'app/core/api/gestacard.service';

@Component({
    selector: 'app-medico-alterar-cartao-gestante',
    templateUrl: './medico-alterar-cartao-gestante.component.html',
    styleUrls: ['./medico-alterar-cartao-gestante.component.scss']
})
export class MedicoAlterarCartaoGestanteComponent implements OnInit, AfterViewInit {

  medico: Medico;
  cartao_id: string;
  email_paciente: string;

  examesLabArr = ['ABO/Rh', 'Hepatite B', 'Hepatite C', 'Plaquetas', 'HT/HB', 'CMV IgG', 'CMV IgM', 'TSH', 'Glicose em Jejum', 'TTOG (GTT)', 'HIV', 'VDRL', 'HBsAG', 'Coombs Ind', 'Toxo IgM', 'Toxo IgG', 'Rubéola IgM', 'Rubéola IgG', 'Urina I', 'Urocultura', 'Parasitológico', 'Cultura Streptococcus', 'Outros'];

  paciente: Paciente;

  cartao_gestante_obj: CartaoGestante;

  consultas_cartao: ConsultaPaciente[] = [];

  loading = true;
  showCard = false;

  dados_graficos_prontos = false;
  dados_dos_graficos: any = [];

  @ViewChild('cartao_gestante_componente') componente_cartao_gestante: any;

  form_info_cartao: FormGroup = new FormGroup({
      data_concepcao: new FormControl(''),
      previsao_parto_dum: new FormControl(''),
      previsao_parto_ultrassom: new FormControl(''),
  });
  formulario_cartao_gestante: FormGroup;

  formulario_cartao_alterado: boolean;

  constructor(
    private router: Router, 
    private medicoService: MedicoService, 
    private snackService: AlertsService, 
    private modalService: ModalsService, 
    private matDialog: MatDialog,
    private events:Events,
    private _apigestacard: APIGestaCardService,  // apagar
    public cartaoService: CartaoContasService
  ) {
    
    try {

      const navigation = this.router.getCurrentNavigation();

      if (!navigation) this.router.navigateByUrl('/medico');
      if (!navigation.extras || !navigation.extras.state) this.router.navigateByUrl('/medico');
      
      const state = navigation.extras.state;
      if (!state.cartao_id || !state.email_paciente) this.router.navigateByUrl('/medico');
      
      this.cartao_id = state ? state.cartao_id : null;
      this.email_paciente = state ? state.email_paciente : null;

    } catch(err) {

      console.log('MedicoAlterarCartaoGestanteComponent -> constructor -> err', err);
      this.router.navigateByUrl('/medico');

    }

  }

  AddModalExame() {

    const dialogRef = this.matDialog.open(ModalAdicionarExameComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      
      this.addModalExameOnload(res);

    });
  }

  addModalExameOnload(res) {

    switch (res) {
      case 'clinico':
          this.InserirClinico();
          break;
      case 'laboratorial':
          this.InserirLab();
          break;
      case 'ultrassom':
          this.InserirUSG();
          break;
      case 'outro':
          this.InserirOutro();
          break;
      default:
          break;
    }

  }

  /** @todo IMPLEMENTAR O MODAL QUE IRÁ ALTERAR O VALOR  */
  SubindoEdicaoCelularExames(event) {

    const trimestre_clicado = event.trimestre;

    if (event.exame == null && event.anotherField == 'data') { // Edição de data

      const dataTrimestre = this.cartao_gestante_obj.exames.laboratorio[0].datas[`data${trimestre_clicado}`];

      this.modalService.AbrirModalDataTrimestre(dataTrimestre).subscribe((res: any) => {

        if (res == null || res == undefined) { return; }

        const aux = res ? res : '';

        this._apigestacard.AlterarTrimestreExameLab(this.cartao_id, trimestre_clicado, aux, this.cartao_gestante_obj.exames).then((result) => {

          this.cartao_gestante_obj.exames.laboratorio = result;

          this.events.publish('ChangeDataTrimestre');

          this.snackService.SnackMessage('Data do trimestre alterada!');

        }).catch((err) => {

          console.log('MedicoAlterarCartaoGestanteComponent -> AlterarTrimestreExameLab -> err', err);

        });

      });

    } else {

      const data_limite_aux = event.exame[`data_limite_tri${trimestre_clicado}`];
      const data_limite: Date = !data_limite_aux || data_limite_aux == '' ? null : new Date(data_limite_aux.seconds * 1000);
      const hoje = new Date();

      // ** FUNCAO DE CHECAGEM DE DATA LIMITE DESABILITADA
      this.modalService.AbrirModalResultadoExame(event.exame.name).subscribe((res: any) => {
        const aux = res ? res : '';

        this._apigestacard.AlterarValorExameLab(this.cartao_id, event.trimestre, event.allTrimestre, aux, event.exame, this.cartao_gestante_obj.exames).then((result) => {

        }).catch((err) => {

          console.log('MedicoAlterarCartaoGestanteComponent -> SubindoEdicaoCelularExames -> err', err);

        });

      });

    }

  }

  Voltar() {
    this.router.navigateByUrl('/medico');
  }

  EncerrarCartao() {

    const dialogEncerrar = this.matDialog.open(MedicoEncerrarCartaoComponent, { 
      maxWidth: '80%',
      data: { 
        paciente: this.paciente, 
        cartao: this.cartao_gestante_obj
      }
    });

    dialogEncerrar.afterClosed().subscribe((formData?) => {

      if (formData !== undefined) {

        this.medicoService.RegistrarEncerramento(this.cartao_id, formData).finally(() => {

          this.snackService.SnackMessage('Cartão encerrado com sucesso!');
          this.BuscarDadosCartao();

        });

      }

    });

  }

  initData() {
    this.medicoService.GetPacientePorEmail(this.email_paciente, ).then((res) => {
      
      this.paciente = res;
        
    }).catch((err) => {

      console.log('MedicoAlterarCartaoGestanteComponent -> initData -> err', err);

    });

    this.medicoService.GetUserData().subscribe((res) => {
      this.medico = res;
    });

  }

  ngOnInit() {

    this.initData();

  }

  ngAfterViewInit() {

    // Buscando o formulário do componente cartão gestante
    this.formulario_cartao_gestante = this.componente_cartao_gestante.cartao_gestante;

    if (this.email_paciente && this.cartao_id) {
      this.BuscarDadosCartao();
    }

    this.events.unsubscribe('CarregarExames'); // EXAMES LABORATORIAIS
    this.events.subscribe('CarregarExames', () => {

      const aux = this.cartao_gestante_obj.sobre_a_gestacao;
      const DppUS = this.cartaoService.PrevisaoUltrassom(aux.us1, aux.us_semanas, aux.us_dias);

      const trimestres = this.cartaoService.CalcularTrimestres(DppUS);

      const vetor_exames = this.examesLabArr.map((item) => {
        return {
          name: item,
          data_limite_tri1: '',
          data_limite_tri2: '',
          data_limite_tri3: '',
          datas: {
              data1: trimestres[0] ? trimestres[0] : '',
              data2: trimestres[1] ? trimestres[1] : '',
              data3: trimestres[2] ? trimestres[2] : '',
              res1: '',
              res2: '',
              res3: '',
          }
        };
      });

      this._apigestacard.InserirLab(this.cartao_id, vetor_exames, this.cartao_gestante_obj.exames).then((result) => {
        
        this.InserirExameRefresh();

      }).catch((err) => {

        console.log('MedicoAlterarCartaoGestanteComponent -> InserirLab -> err', err);

      });

    }); // end event CarregarExames

    this.events.unsubscribe('ExameUltrassonografiaIncluir'); // Evitando Loop de subscribe ao sair e voltar no componente
    this.events.subscribe('ExameUltrassonografiaIncluir', data => {
      this.InserirUSG();
    });

    this.events.unsubscribe('ExameOutrosIncluir'); // Evitando Loop de subscribe ao sair e voltar no componente
    this.events.subscribe('ExameOutrosIncluir', data => {
      
      this.InserirOutro();

    });

    this.events.unsubscribe('ExameClinicoIncluir'); // Evitando Loop de subscribe ao sair e voltar no componente
    this.events.subscribe('ExameClinicoIncluir', data => {
      
      this.InserirClinico();

    });

    this.events.unsubscribe('ExameClinicoEditar'); // Evitando Loop de subscribe ao sair e voltar no componente
    this.events.subscribe('ExameClinicoEditar', data => {
      
      this.EditarClinico(data);

    });

    this.formulario_cartao_alterado = false;

  }

  RecarregarPaginaDoCartao() {

    const navigationExtras: NavigationExtras = {
      state: {
        cartao_id: this.cartao_id,
        email_paciente: this.email_paciente,
      }
    };

    this.router.navigate(['/medico-alterar-cartao-gestante'], navigationExtras);

  }

  InserirClinico() {

    const sobre_a_gestacao = this.cartao_gestante_obj.sobre_a_gestacao;

    this.cartaoService.AbrirModalClinicos(sobre_a_gestacao.dum, sobre_a_gestacao.us1, sobre_a_gestacao.us_semanas, sobre_a_gestacao.us_dias).subscribe((res) => {

        if (res) {

          this._apigestacard.InserirExameClinico(this.cartao_id, res, this.cartao_gestante_obj.exames).then((result) => {

            this.InserirExameRefresh();
            
          }).catch((err) => {

              console.log('MedicoAlterarCartaoGestanteComponent -> InserirClinico -> err', err);

          });

        }

    });
  }

  /**
   * 
   * @param data Valores atuais do exame clinico que serão modificados
   */
  EditarClinico(data) {

    const sobre_a_gestacao = data;
    
    this.cartaoService.AbrirModalClinicosEditar(data).subscribe((res) => {
      
      if (res) {
        this._apigestacard.EditarExameClinico(this.cartao_id, data, res, this.cartao_gestante_obj.exames).then((result) => {
          
          this.InserirExameRefresh();

        }).catch((err) => {

          console.log('MedicoAlterarCartaoGestanteComponent -> InserirClinico -> err', err);
            
        });
      }
    });

  }

  InserirLab() {

    this.cartaoService.AbrirModalLab(this.cartao_gestante_obj.exames.laboratorio).subscribe((res) => {
      
      if (res) {
        const vetor_exames = res.exames_selecionados.map((item) => {
          return {
            name: item,
            data_limite_tri1: res.trimestre == 1 ? res.data_limite_edicao : '',
            data_limite_tri2: res.trimestre == 2 ? res.data_limite_edicao : '',
            data_limite_tri3: res.trimestre == 3 ? res.data_limite_edicao : '',
            datas: {
                data1: res.trimestre == '1' ? res.data : '',
                data2: res.trimestre == '2' ? res.data : '',
                data3: res.trimestre == '3' ? res.data : '',
                res1: '',
                res2: '',
                res3: '',
            }
          };
        });

        this._apigestacard.InserirLab(this.cartao_id, vetor_exames, this.cartao_gestante_obj.exames).then((result) => {
          
          this.InserirExameRefresh();

        }).catch((err) => {

          console.log('MedicoAlterarCartaoGestanteComponent -> InserirLab -> err', err);

        });

      }

    });

  }

  InserirUSG() {

    this.cartaoService.AbrirModalUSG().subscribe((res) => {

      if (res) {
        
        this._apigestacard.InserirUSG(this.cartao_id, res, this.cartao_gestante_obj.exames).then(() => {
          
            this.InserirExameRefresh();

        }).catch((err) => {

            console.log('MedicoAlterarCartaoGestanteComponent -> InserirUSG -> err', err);

        });

      }

    });

  }

  InserirOutro() {

    this.cartaoService.AbrirModalOutros().subscribe((res) => {

      if (res) {

        this._apigestacard.InserirOutros(this.cartao_id, res, this.cartao_gestante_obj.exames).then((result) => {
          
          this.InserirExameRefresh();

        }).catch((err) => {

          console.log('MedicoAlterarCartaoGestanteComponent -> InserirOutro -> err', err);
          
        });

      }

    });

  }

  InserirExameRefresh() {
    // Evitando perde a edição do formulário ao adicionar um exame no meio, salvando antes
    // console.log('InserirExameRefresh', this.formulario_cartao_alterado);
    
    if (this.formulario_cartao_alterado) {
      
      this.SalvarAlteracoes(() => {
        
        this.BuscarDadosCartao();

      });

    } else {
      
      this.BuscarDadosCartao();

    }

  }

  async EventoClicado(item) {

    try {
      
      const extras: NavigationExtras = {
        state: {
          paciente: this.paciente,
          consulta: item
        }
      };

      this.router.navigate(['/medico-nova-consulta'], extras);

    } catch (error) {

    }
    
  }

  BuscarDadosCartao() {
    
    this.loading = true;
    this.medicoService.LerUmCartao(this.cartao_id).then((res) => {

      this.cartao_gestante_obj = res;

      // Atualizando os formulários 
      const aux1 = {
        data_concepcao: this.TimeStampToDate(this.cartao_gestante_obj.data_concepcao),
        previsao_parto_dum: this.TimeStampToDate(this.cartao_gestante_obj.previsao_parto_dum),
        previsao_parto_ultrassom: this.TimeStampToDate(this.cartao_gestante_obj.previsao_parto_ultrassom)
      };

      this.form_info_cartao.setValue(aux1);

      this.setFormValue(this.formulario_cartao_gestante.controls.historico_clinico, this.cartao_gestante_obj.historico_clinico);
      this.setFormValue(this.formulario_cartao_gestante.controls.historico_familiar, this.cartao_gestante_obj.historico_familiar);

      this.setFormValue(this.formulario_cartao_gestante.controls.antecedentes_obstetricos, this.cartao_gestante_obj.antecedentes_obstetricos);
      this.setFormValue(this.formulario_cartao_gestante.controls.sobre_a_gestacao, this.cartao_gestante_obj.sobre_a_gestacao);
      this.setFormValue(this.formulario_cartao_gestante.controls.vacinas, this.cartao_gestante_obj.vacinas);

      if (this.cartao_gestante_obj.exames.hasOwnProperty('clinicos')) {
        this.dados_graficos_prontos = true;
        this.dados_dos_graficos = this.cartao_gestante_obj.exames.clinicos;
      }

      // Tentando forçar a atualização
      this.componente_cartao_gestante.cartao_gestante_obj = this.cartao_gestante_obj;

      this.events.publish('CartaoGestanteDataLoad');

      this.formulario_cartao_alterado = false;

      this.formulario_cartao_gestante.valueChanges.subscribe(res => {

        this.formulario_cartao_alterado = true;

        const formValue = this.formulario_cartao_gestante.value;

        this.cartao_gestante_obj.sobre_a_gestacao = formValue.sobre_a_gestacao;
        this.cartao_gestante_obj.historico_clinico = formValue.historico_clinico;
        this.cartao_gestante_obj.historico_familiar = formValue.historico_familiar;

      });

    }).catch((err) => {

      this.loading = false;

      this.snackService.SnackMessage('Cartão indisponivel para alteração');

      this.router.navigateByUrl('/medico');

    }).finally(() => {
        this.loading = false;
        this.showCard = true;

    });

  }

  /**
   * Seta o form value a partir do novo layout do form, para nao dar erro com mudanças de estrutura no firebase
   * @param form 
   * @param obj 
   */

  setFormValue(form, obj) {

      const layout = form.value;
      for (const z in layout) {
          if (obj.hasOwnProperty(z)) { layout[z] = obj[z]; }
      }

      // console.log('setFormValue', obj, layout);

      form.patchValue(layout);

  }

  TimeStampToDate(obj): Date {
      const date = new Date(obj.seconds * 1000);
      return date;
  }

  AtivarDesativarCartao() {

    if (!this.cartao_gestante_obj.ativado) {
      
      this.medicoService.AtivarUmCartao(this.medico.id, this.paciente.id, this.cartao_id).then(() => {

        this.snackService.SnackMessage('Cartão ativado com sucesso!');
        this.BuscarDadosCartao();

      }).catch((err) => {

        this.snackService.SnackMessage(err);

      });

    } else {

      this.modalService.ConfirmarDesativarCartao().subscribe((res: boolean) => {

        if (res) {

          this.medicoService.DesativarUmCartao(this.cartao_id).finally(() => {

            this.BuscarDadosCartao();

          });

        }

      });

    }

  }

  SalvarAlteracoes(callback?: () => any) {

    const cartao_gestante = this.formulario_cartao_gestante.value;
    const cartao_atualizado = { ...cartao_gestante };

    this.medicoService.AtualizarCartao(this.cartao_id, cartao_atualizado).then(() => {

      this.snackService.SnackMessage('Cartão atualizado com sucesso!');
      
      if (callback) callback()

    }).catch((err) => {

      this.snackService.SnackMessage('Erro ao atualizar o cartão');

    });

  }

  DeletarCartao() {

    this.modalService.AbrirModalDeConfirmacao('Deletar Cartão Gestante', 'Essa ação não poderá ser desfeita, deseja excluir o cartão?').subscribe((res) => {

      if (res) {

        this.medicoService.DeletarCartaoGestante(this.cartao_id).then(() => {

          this.snackService.SnackMessage('Cartão deletado com sucesso!');
          this.router.navigateByUrl('/medico');

        }).catch((err) => {

          this.snackService.SnackMessage('Erro ao deletar o cartão');

        });

      }

    });

  }

  get dataCriacao() {

    if (!this.cartao_gestante_obj.criado_em) return ''

    const data = new Date(this.cartao_gestante_obj.criado_em);
    return data;

  }


  Imprimir() {
    window.print();
  }

}
