import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AlertsService } from 'app/shared/alerts.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;

    navigationExtra;

    erro_email_n_encontrado = false;
    enviando_email = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private snackService: AlertsService
    ) {
        this.navigationExtra = this.router.getCurrentNavigation().extras.state;

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        const aux = this.navigationExtra;
        if (aux && aux.email) {
            this.forgotPasswordForm.setValue({
                email: aux.email
            });
        }
    }

    EnviarEmail() {
        if (this.forgotPasswordForm.valid) {
            this.enviando_email = true;
            this.authService.EnviarEmailRecuperacaoDeSenha(this.forgotPasswordForm.controls.email.value).then(() => {
                this.snackService.SnackMessage('Sua senha foi redefinida com sucesso! Faça login passando uma nova senha.');

                setTimeout(() => {
                    this.router.navigateByUrl('/auth/login');
                    this.enviando_email = false;
                }, 2000);
            }).catch((err) => {
                this.snackService.SnackMessage(err.message)
                        
                this.enviando_email = false; 
            })
        }
    }
}
