import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-boxes-dashboard',
    templateUrl: './boxes-dashboard.component.html',
    styleUrls: ['./boxes-dashboard.component.scss']
})
export class BoxesDashboardComponent implements OnInit {

    @Input() valor1: string | number;
    @Input() valor2: string | number;

    @Input() texto1: string;
    @Input() texto2: string;

    @Input() tipo_usuario = 'admin';
    @Input() font_size = 24;

    constructor() { }

    ngOnInit() {
    }

}
