import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatButtonModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatTooltipModule, MatCardModule } from '@angular/material';

import { MedicoRoutingModule } from './medico-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { CalendarModule } from 'app/shared/components/calendario/calendar.module';

import { PipeModule } from 'app/core/pipes/pipes.module';
import { CoreModule } from 'app/core/core.module';

import { MedicoPacientesComponent } from './subpages/medico-pacientes/medico-pacientes.component';
import { MedicoConsultasComponent } from './subpages/medico-consultas/medico-consultas.component';
import { MedicoCadastrarPacienteComponent } from './subpages/medico-cadastrar-paciente/medico-cadastrar-paciente.component';
import { MedicoEditarPerfilComponent } from './subpages/medico-editar-perfil/medico-editar-perfil.component';
import { MedicoAlterarPacienteComponent } from './subpages/medico-alterar-paciente/medico-alterar-paciente.component';
import { MedicoAlterarCartaoGestanteComponent } from './subpages/medico-alterar-cartao-gestante/medico-alterar-cartao-gestante.component';
import { MedicoNovaConsultaComponent } from './subpages/medico-nova-consulta/medico-nova-consulta.component';
import { MedicoEncerrarCartaoComponent } from './subpages/medico-encerrar-cartao/medico-encerrar-cartao.component';
import { MedicoSecretariasComponent } from './subpages/medico-secretarias/medico-secretarias.component';
import { ModalAdicionarExameComponent } from './components/modal-adicionar-exame/modal-adicionar-exame.component';
import { ModalConsultasDiariaComponent } from './components/modal-consultas-diaria/modal-consultas-diaria.component';
import { MedicoAlterarSecretariaComponent } from './subpages/medico-alterar-secretaria/medico-alterar-secretaria.component';
import { MedicoCadastrarSecretariaComponent } from './subpages/medico-cadastrar-secretaria/medico-cadastrar-secretaria.component';

import { RbFabSpeedDialModule } from 'rb-fab-speed-dial';

@NgModule({
    declarations: [
        MedicoPacientesComponent, 
        MedicoConsultasComponent, 
        MedicoCadastrarPacienteComponent, 
        MedicoCadastrarSecretariaComponent,
        MedicoEditarPerfilComponent, 
        MedicoAlterarPacienteComponent, 
        MedicoAlterarSecretariaComponent, 
        MedicoAlterarCartaoGestanteComponent, 
        MedicoNovaConsultaComponent, 
        ModalConsultasDiariaComponent, 
        ModalAdicionarExameComponent, 
        MedicoEncerrarCartaoComponent, 
        MedicoSecretariasComponent
    ],

    imports: [
        CommonModule,
        MedicoRoutingModule,
        SharedModule,
        CalendarModule,
        CoreModule,
        PipeModule,

        /** FormsModule */
        FormsModule,
        ReactiveFormsModule,

        RbFabSpeedDialModule,

        /** Angular material */
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatCardModule,
    ],

    entryComponents: [
        ModalConsultasDiariaComponent,
        ModalAdicionarExameComponent,
        MedicoEncerrarCartaoComponent
    ]
})
export class MedicoModule { }
