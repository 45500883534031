// --> IMC --> const p10 = [20, 20.2, 20.3, 20.4, 20.5, 20.7, 20.8, 20.9, 21.1, 21.2, 21.3, 21.5, 21.6, 21.8, 21.9, 22.1, 22.3, 22.5, 22.7, 22.8, 23, 23.2, 23.4, 23.5, 23.7, 23.9, 24, 24.2, 24.3, 24.5, 24.6, 24.8, 25, 25.1, 25.1],
const p10 = [// Dados de peso abaixo do normal
    { x: 12, y: 0 },
    { x: 13, y: 0 },
    { x: 14, y: 0.3 },
    { x: 15, y: 0.6 },
    { x: 16, y: 1 },
    { x: 17, y: 1.4 },
    { x: 18, y: 1.7 },
    { x: 19, y: 2.1 },
    { x: 20, y: 2.4 },
    { x: 21, y: 2.7 },
    { x: 22, y: 3 },
    { x: 23, y: 3.3 },
    { x: 24, y: 3.7 },
    { x: 25, y: 4 },
    { x: 26, y: 4.3 },
    { x: 27, y: 4.6 },
    { x: 28, y: 4.9 },
    { x: 29, y: 5.2 },
    { x: 30, y: 5.5 },
    { x: 31, y: 5.7 },
    { x: 32, y: 5.9 },
    { x: 33, y: 6.1 },
    { x: 34, y: 6.3 },
    { x: 35, y: 6.5 },
    { x: 36, y: 6.7 },
    { x: 37, y: 6.8 },
    { x: 38, y: 6.9 },
    { x: 39, y: 6.7 },
    { x: 40, y: 6.5 },
]

// --> IMC --> const p25 = [25, 25.1, 25.3, 25.4, 25.5, 25.7, 25.8, 25.9, 26, 26.1, 26.2, 26.3, 26.4, 26.5, 26.7, 26.9, 27, 27.1, 27.3, 27.4, 27.6, 27.7, 27.9, 28, 28.1, 28.2, 28.4, 28.5, 28.6, 28.8, 28.9, 29, 29.2, 29.3, 29.3], 
const p25 = [// Dados de peso adequado
    { x: 12, y: 0.4 },
    { x: 13, y: 0.8 },
    { x: 14, y: 1.3 },
    { x: 15, y: 1.8 },
    { x: 16, y: 2.3 },
    { x: 17, y: 2.8 },
    { x: 18, y: 3.3 },
    { x: 19, y: 3.7 },
    { x: 20, y: 4.1 },
    { x: 21, y: 4.6 },
    { x: 22, y: 5 },
    { x: 23, y: 5.4 },
    { x: 24, y: 5.8 },
    { x: 25, y: 6.2 },
    { x: 26, y: 6.6 },
    { x: 27, y: 6.9 },
    { x: 28, y: 7.2 },
    { x: 29, y: 7.5 },
    { x: 30, y: 7.75 },
    { x: 31, y: 8 },
    { x: 32, y: 8.2 },
    { x: 33, y: 8.4 },
    { x: 34, y: 8.6 },
    { x: 35, y: 8.7 },
    { x: 36, y: 8.8 },
    { x: 37, y: 8.7 },
    { x: 38, y: 8.6 },
    { x: 39, y: 8.4 },
    { x: 40, y: 8 }
]

// --> IMC --> const p50 = [30.1, 30.2, 30.3, 30.4, 30.4, 30.5, 30.6, 30.7, 30.8, 30.9, 31, 31, 31.1, 31.2, 31.3, 31.4, 31.6, 31.7, 31.8, 31.9, 32, 32.1, 32.2, 32.3, 32.4, 32.5, 32.6, 32.7, 32.8, 32.9, 33, 33.1, 33.2, 33.3, 33.3], 
const p50 = [// Dados de sobrepeso
    { x: 12, y: 1 },
    { x: 13, y: 1.6 },
    { x: 14, y: 2.2 },
    { x: 15, y: 2.8 },
    { x: 16, y: 3.4 },
    { x: 17, y: 4 },
    { x: 18, y: 4.5 },
    { x: 19, y: 5 },
    { x: 20, y: 5.5 },
    { x: 21, y: 6 },
    { x: 22, y: 6.4 },
    { x: 23, y: 6.9 },
    { x: 24, y: 7.3 },
    { x: 25, y: 7.7 },
    { x: 26, y: 8.1 },
    { x: 27, y: 8.5 },
    { x: 28, y: 8.9 },
    { x: 29, y: 9.3 },
    { x: 30, y: 9.7 },
    { x: 31, y: 10 },
    { x: 32, y: 10.2 },
    { x: 33, y: 10.4 },
    { x: 34, y: 10.6 },
    { x: 35, y: 10.8 },
    { x: 36, y: 11 },
    { x: 37, y: 11.2 },
    { x: 38, y: 11.4 },
    { x: 39, y: 11.2 },
    { x: 40, y: 11 }
]

// --> IMC --> const p90 = [30.1, 30.2, 30.3, 30.4, 30.4, 30.5, 30.6, 30.7, 30.8, 30.9, 31, 31, 31.1, 31.2, 31.3, 31.4, 31.6, 31.7, 31.8, 31.9, 32, 32.1, 32.2, 32.3, 32.4, 32.5, 32.6, 32.7, 32.8, 32.9, 33, 33.1, 33.2, 33.3, 33.3], 
const p90 = [// Dados de obeso
    { x: 12, y: 3.4 },
    { x: 13, y: 4.2 },
    { x: 14, y: 4.8 },
    { x: 15, y: 5.4 },
    { x: 16, y: 6.2 },
    { x: 17, y: 6.8 },
    { x: 18, y: 7.4 },
    { x: 19, y: 8 },
    { x: 20, y: 8.6 },
    { x: 21, y: 9.2 },
    { x: 22, y: 9.8 },
    { x: 23, y: 10.3 },
    { x: 24, y: 10.8 },
    { x: 25, y: 11.3 },
    { x: 26, y: 11.8 },
    { x: 27, y: 12.3 },
    { x: 28, y: 12.8 },
    { x: 29, y: 13.3 },
    { x: 30, y: 13.7 },
    { x: 31, y: 14.1 },
    { x: 32, y: 14.5 },
    { x: 33, y: 14.9 },
    { x: 34, y: 15.3 },
    { x: 35, y: 15.6 },
    { x: 36, y: 15.8 },
    { x: 37, y: 15.9 },
    { x: 38, y: 16 },
    { x: 39, y: 15.8 },
    { x: 40, y: 15.5 },
]

export { p10, p25, p50, p90 }