import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { MaintenanceComponent } from 'app/pages/authentication/blocked-session/maintenance.component';
import { MatButtonModule } from '@angular/material';

const routes = [
    {
        path: 'maintenance',
        component: MaintenanceComponent
    }
];

@NgModule({
    declarations: [
        MaintenanceComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        MatButtonModule
    ]
})
export class MaintenanceModule {
}
