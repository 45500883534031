export const locale = {
    lang: 'pt',
    data: {
        'NAV': {
            'APPLICATIONS': 'Programlar',
            'SAMPLE': {
                'TITLE': 'Amostra',
                'BADGE': '15'
            }
        }
    }
};
