import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { navigation, secretaria, paciente, medico } from 'app/config/fuse-config/navigation/navigation';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';


@Injectable({
    providedIn: 'root'
})
export class RolesManagerService {

    navigation: any;

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfig: FuseConfigService,
        private router: Router
    ) {
        this._fuseNavigationService.register('medico', medico);
        this._fuseNavigationService.register('paciente', paciente);
        this._fuseNavigationService.register('secretaria', secretaria);
    }

  SetarAmbiente(tipo_acesso: string) : string {

    if(tipo_acesso && tipo_acesso !== 'super') {
      this._fuseNavigationService.setCurrentNavigation(tipo_acesso)

      var tema = {
        medico: { colorTheme: 'theme-medico', layout: { navbar: { secondaryBackground: 'fuse-navy-A400', hidden: false  }, toolbar: { background: 'fuse-navy-A400', hidden: false  }}},
        secretaria: { colorTheme: 'theme-medico', layout: { navbar: { secondaryBackground: 'fuse-navy-A400', hidden: false  }, toolbar: { background: 'fuse-navy-A400', hidden: false  }}},
        paciente: { colorTheme: 'theme-paciente', layout: { navbar: { secondaryBackground: 'fuse-navy-A700', hidden: false  }, toolbar: { background: 'fuse-navy-A700',hidden: false  }}},
      };

      this._fuseConfig.config = tema[tipo_acesso];

      this.router.navigateByUrl(`/${tipo_acesso}`); 
      
      return 'sucesso'; 
    } else {
      this.router.navigateByUrl(`/auth/blocked`); 
      return null; 
    }

  }
  
}
