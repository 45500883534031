import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Events } from 'app/core/events';

@Component({
  selector: 'app-outros-exames',
  templateUrl: './outros-exames.component.html',
  styleUrls: ['./outros-exames.component.scss', './../tabelas.scss']
})
export class OutrosExamesComponent implements OnInit {
  displayedColumns: string[] = ['exame', 'data', 'res'];
  dataSource = [];

  @Input() outros_exames;
  @Input('edit_mode') edit;

  constructor(private events: Events) { }

  ngOnInit() {

    this.dataSource = this.outros_exames;

  }

  InserirOutrosExames() {

    this.events.publish('ExameOutrosIncluir');

  }

  ngOnChanges(changes: SimpleChanges) {

    this.dataSource = this.outros_exames;

  }

}
