import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MedicoService } from '../../medico.service';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { OverlayService } from 'app/shared/overlay.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { Secretaria } from 'app/core/interfaces/secretaria.interface';

@Component({
  selector: 'app-medico-cadastrar-secretaria',
  templateUrl: './medico-cadastrar-secretaria.component.html',
  styleUrls: ['./medico-cadastrar-secretaria.component.scss']
})
export class MedicoCadastrarSecretariaComponent implements OnInit, AfterViewInit {

  form: FormGroup; 
  usuario_medico: any; 

  loading: boolean = false; 

  @ViewChild('cadastro_secretaria') componente_cadastro_secretaria; 
  @ViewChild('imageUploader') file_uploader_component;

  constructor(
    private medicoService: MedicoService, 
    private snackService: AlertsService, 
    private router: Router, 
    private overlayService: OverlayService,
    private _apigestacard: APIGestaCardService
    ) { }

  ngOnInit() {

  }

  BuscarPerfilMedico() {

    this.medicoService.GetUserData().subscribe((res) => {
      
      this.usuario_medico = res; 
      
      if(res) {
        this.form.patchValue({
          medico: `${res.primeiro_nome} ${res.sobrenome}`, 
          telefone_medico: res.telefone, 
        }); 
      }

    }); 

  }

  ngAfterViewInit() {
    this.form = this.componente_cadastro_secretaria.form; 

    this.BuscarPerfilMedico(); 
  }

  SalvarPaciente() {

    if (this.form.valid) {

      this.loading = true;
      this.overlayService.show(); 

      var aux = this.form.value; 
      aux.medicos = [{ id: this.usuario_medico.id }];
      var secretariaCriada = Secretaria.create(aux); 

      this.medicoService.CadastrarSecretaria(secretariaCriada).then(async (res) => {

        try {

          var imagem_aux = this.file_uploader_component.imagePath;

          if (imagem_aux) { 
            var imagem = imagem_aux[0];
            var url_imagem = await this._apigestacard.UploadImage(imagem);

            await this._apigestacard.AtualizarUrlPerfil(res.id, url_imagem);
          }

          this.snackService.SnackMessage('Cadastro feito com sucesso!'); 
          this.router.navigate(['/medico-secretarias']);
          
        } catch (error) {

          console.log("MedicoCadastrarPacienteComponent -> SalvarPaciente -> error", error);

        }

      }).catch((err) => {

        this.snackService.SnackMessage('Erro ao cadastrar paciente.'+(err.hasOwnProperty('message') ? ' '+err.message : '' )); 

      }).finally(() => {

        this.loading = false;
        this.overlayService.hide(); 

      })

    } else {
      this.snackService.SnackMessage('Formulário invalido'); 
    }
  }

}
