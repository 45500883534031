import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timestampToDate'
})
export class TimestampToDate implements PipeTransform {

    transform(ts: any): string {
        if (!ts) {
            return ``;
        }

        const date = new Date(ts.seconds * 1000);
        return date.toLocaleDateString();
    }

}
