import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CoreModule } from '@angular/flex-layout';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBarModule, MatGridListModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { AngularFireModule } from 'angularfire2';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuth, AngularFireAuthModule } from 'angularfire2/auth';

import { fuseConfig } from 'app/config/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthenticationModule } from './pages/authentication/authentication.module';
import { environment } from 'environments/environment';
import { MedicoModule } from './pages/medico/medico.module';
import { SecretariaModule } from './pages/secretaria/secretaria.module';
import { PacienteModule } from './pages/paciente/paciente.module';
import { SharedModule } from './shared/shared.module';
import { isObject } from 'util';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) { return target; }
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return mergeDeep(target, ...sources);
  }


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const appRoutes: Routes = [
    // {
    //     path      : '**',
    //     redirectTo: 'auth'
    // }, 
    {
        path: ``, 
        redirectTo: `auth`, 
        pathMatch: 'full'
    },
    {
        path: `auth`, 
        redirectTo: `auth`
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {useHash: true}),

        // Inicializando o firebase
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFirestoreModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatNativeDateModule,
        MatGridListModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Core Module 
        CoreModule,
        SharedModule,

        // App modules
        LayoutModule,
        AuthenticationModule,  
        MedicoModule, 
        SecretariaModule, 
        PacienteModule
    ],
    bootstrap   : [
        AppComponent,
    ], 
    providers: [
        AngularFireAuth, 
        AngularFireAuthGuard, 
        AngularFirestore, 
        AngularFireStorage,
        { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        }
    ], 
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule
{
}
