import { Component, OnInit } from '@angular/core';
import { MedicoService } from '../../medico.service';
import { ModalsService } from 'app/shared/modals.service';
import { ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { NavigationExtras, Router } from '@angular/router';
import { CalendarService } from 'app/shared/components/calendario/calendar.service';
import { MatDialog } from '@angular/material';
import { ModalConsultasDiariaComponent } from '../../components/modal-consultas-diaria/modal-consultas-diaria.component';

@Component({
    selector: 'app-medico-consultas',
    templateUrl: './medico-consultas.component.html',
    styleUrls: ['./medico-consultas.component.scss']
})
export class MedicoConsultasComponent implements OnInit {

    proximas_consultas: ConsultaPaciente[] = [];
    consultas_anteriores: ConsultaPaciente[] = [];

    constructor(
        private medicoService: MedicoService,
        private router: Router,
        private calendarService: CalendarService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.medicoService.GetConsultas().then((res) => {
            // 
            this.proximas_consultas = res.proximas;
            this.consultas_anteriores = res.anteriores;

            const todas_as_consultas = this.proximas_consultas.concat(this.consultas_anteriores);
            this.calendarService.setEvents(todas_as_consultas);
        }).catch((err) => {
        });
    }

    async EventoClicado(item) {

        try {
            const dados_paciente = await this.medicoService.GetPacientePorId(item.paciente_id);
            const extras: NavigationExtras = {
                state: {
                    paciente: dados_paciente,
                    consulta: item
                }
            };
            this.router.navigate(['/medico-nova-consulta'], extras);
        } catch (error) {

        }
    }

    NovaConsulta() {

    }

    DiaClicadoNoCalendario(event) {
        if (event.events.length > 0) {
            this.dialog.open(ModalConsultasDiariaComponent, {
                width: '600px',
                data: event
            });
        }
    }
}
