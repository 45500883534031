import { Component, OnInit } from '@angular/core';
import { CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { PacienteService } from '../../paciente.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-cartao-anteriores',
    templateUrl: './cartao-anteriores.component.html',
    styleUrls: ['./cartao-anteriores.component.scss']
})
export class CartaoAnterioresComponent implements OnInit {


    todos_cartoes: CartaoGestante[] = [];

    constructor(private pacienteService: PacienteService, private router: Router) {
        this.pacienteService.GetTodosCartoes().then((cartoes: CartaoGestante[]) => {
            this.todos_cartoes = cartoes;

        });
    }

    ngOnInit() {

    }

    AbrirCartao(cartao?: CartaoGestante) {

        const extras: NavigationExtras = {
            state: {
                cartao: cartao
            }
        };

        this.router.navigate(['/paciente-informacao'], extras);

    }

}
