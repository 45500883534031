import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-editar-consulta',
    templateUrl: './editar-consulta.component.html',
    styleUrls: ['./editar-consulta.component.scss']
})
export class EditarConsultaComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditarConsultaComponent>
    ) { }

    ngOnInit() {
        console.log(this.data);
    }

    Alterar() {
        console.log('Alterando a consulta');
    }

    Cancelar() {
        this.dialogRef.close(false);
    }

}
