import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SecretariaService } from '../../secretaria.service';
import { Secretaria } from 'app/core/interfaces/secretaria.interface';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { ModalsService } from 'app/shared/modals.service';
import { AuthService } from 'app/core/auth/auth.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';

@Component({
  selector: 'app-secretaria-editar-perfil',
  templateUrl: './secretaria-editar-perfil.component.html',
  styleUrls: ['./secretaria-editar-perfil.component.scss']
})
export class SecretariaEditarPerfilComponent implements OnInit {

  formulario: FormGroup; 
  perfil: Secretaria; 

  form: FormGroup = new FormGroup({
    primeiro_nome: new FormControl('', [Validators.required]), 
    sobrenome: new FormControl('', [Validators.required]), 
    idade: new FormControl('', [Validators.required]), 
    endereco: new FormControl('', [Validators.required]), 
    cidade: new FormControl('', [Validators.required]), 
    estado: new FormControl('', [Validators.required]), 
    telefone: new FormControl('', ),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  /** Formulário de atualizar senha */
  atualizarSenha: FormGroup = new FormGroup({
    senha_atual: new FormControl('', Validators.required), 
    nova_senha: new FormControl('', Validators.required), 
    confirmar_senha: new FormControl('', Validators.required), 
  });

  senhas_nao_iguais: boolean = false; 

  /** Formulário para atualizar o email */
  atualizarEmail: FormGroup = new FormGroup({
    novo_email: new FormControl('', Validators.required), 
    confirmar_email: new FormControl('', Validators.required), 
    password: new FormControl('', Validators.required)
  }); 

  email_nao_iguais: boolean = false; 
  visiblePassword = false;

  loading: boolean = true; 
  outras_opcoes: boolean = false; 

  // @ViewChild('form_perfil') componente_formulario_perfil; 
  @ViewChild('imageUploader') image_uploader;

  constructor(
    private secretariaService: SecretariaService, 
    private snackService: AlertsService, 
    private router: Router, 
    private modalService: ModalsService, 
    private authService: AuthService,
    private _apigestacard: APIGestaCardService,
  ) { }

  /** Lyfe cicle hooks */

  ngOnInit() {
    this.BuscarPerfilSecretaria();
  }

  setVisiblePassword(visiblePassword: boolean) {
    this.visiblePassword = visiblePassword;
  }

  /** Funções do componente */
  BuscarPerfilSecretaria() {
    this.loading = true;

    this.secretariaService.GetUserData().subscribe((res:Secretaria) => {

      this.perfil = res; 

      this.loading = false;
      
      this.PreencherFormulario();

    });
  }
  
  PreencherFormulario() {

    this.form.patchValue(this.perfil); 

    this.form.controls.email.disable(); 

  }

  async SalvarAlteracoes() {

    if (this.form.valid) {

      var novo_obj_usuario = { ...this.perfil, ...this.form.getRawValue() };

      if (this.image_uploader.foto_mudou) {

        try {

          var imagem = this.image_uploader.imagePath;
          var image_to_upload = imagem[0];

          var url_imagem = await this._apigestacard.UploadImage(image_to_upload);

          novo_obj_usuario['url'] = url_imagem;

        } catch (err) {

          this.snackService.SnackMessage('Erro ao atualizar a imagem do paciente');
          return;

        }
      }

      this.secretariaService.AtualizarPerfilSecretaria(novo_obj_usuario).then((res) => {

        this.snackService.SnackMessage('Perfil Atualizado com sucesso!'); 

        this.authService.AtualizarDadosDoUsuario(this.perfil.email, 'secretaria');

        this.router.navigate(['/secretaria']); 
        
      }).catch((err) => {

        this.snackService.SnackMessage(err); 
      
      })

    }
    
  }

  Voltar() {
    this.router.navigateByUrl('/secretaria');
  }

  AlterarSenha() {

    if (this.atualizarSenha.valid) {
      var senha_atual = this.atualizarSenha.controls.senha_atual.value; 
      var nova_senha = this.atualizarSenha.controls.nova_senha.value; 
      var confirmar_senha = this.atualizarSenha.controls.confirmar_senha.value; 

      if (confirmar_senha != nova_senha) {

        this.senhas_nao_iguais = true; 

      } else {

        this.senhas_nao_iguais = false; 

        this.authService.UpdatePassword(senha_atual, nova_senha, confirmar_senha).then(() => {

          this.snackService.SnackMessage('Senha alterada com sucesso!');

        }).catch((err) => {

          this.snackService.SnackMessage(`Erro ao atualizar senha: ${JSON.stringify(err)}`); 

        });

      }
    }

  }

  AlterarEmail() {

    if (this.atualizarEmail.valid) {

      var novo_email = this.atualizarEmail.controls.novo_email.value; 
      var confirmar_email = this.atualizarEmail.controls.confirmar_email.value; 
      var password = this.atualizarEmail.controls.password.value; 

      if(novo_email != confirmar_email) {

        this.email_nao_iguais = true; 

      } else {

        this.email_nao_iguais = false; 

        this.authService.UpdateEmail(novo_email, password).then(() => {

          this.snackService.SnackMessage('E-mail atualizado com sucesso!, Faça o login novamente'); 

          setTimeout(() => this.authService.Logout(), 1000);

        }).catch((err) => {

          this.snackService.SnackMessage(err); 

        });

      }

    }

  }

  DeletarPerfil() {
    this.modalService.AbrirModalDeConfirmacao('Deseja Excluir perfil?', 'Todos os seus dados serão apagados, e não será possível recuperá-los, deseja continuar?').subscribe((res: boolean) => {
      if(res) {
         

      }
    })
  }

  get primeiro_nome() { return this.form.get('primeiro_nome'); }
  get sobrenome() { return this.form.get('sobrenome'); }
  get idade() { return this.form.get('idade'); }
  get endereco() { return this.form.get('endereco'); }
  get cidade() { return this.form.get('cidade'); }
  get estado() { return this.form.get('estado'); }
  get telefone() { return this.form.get('telefone'); }
  get email() { return this.form.get('email'); }
}
