import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalEdicaoExamesComponent } from '../modal-edicao-exames/modal-edicao-exames.component';

@Component({
    selector: 'app-alterar-email',
    templateUrl: './alterar-email.component.html',
    styleUrls: ['./alterar-email.component.scss']
})
export class AlterarEmailComponent implements OnInit {

    form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.email, Validators.required])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalEdicaoExamesComponent>
    ) { }

    ngOnInit() {
        if (this.data.email) { this.form.controls.email.setValue(this.data.email); }
    }

    Alterar() {
        const email = this.form.value.email;
        if (email != '') {
            this.dialogRef.close(email);
        }
    }


}
