import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
    selector: 'signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SignupComponent implements OnInit {

    navigationExtra;

    loading: boolean;

    perfil = '';

    form: FormGroup = new FormGroup({
        nome: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        telefone: new FormControl('', [Validators.required]),
        mensagem: new FormControl(),
    });

    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private sharedAlerts: AlertsService,
        private http: HttpClient

    ) {
        this.loading = false;

        this.navigationExtra = this.router.getCurrentNavigation().extras.state;

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }

    ngOnInit() {


    }

    MedicoLead() {

        if (this.form.valid) {
            const params: HttpParams = new HttpParams({ 'fromObject': { 'fromapp': 1, ...this.form.value } });

            const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

            this.loading = true;

            this.http.request('POST', 'https://gestacard.app/forms/lead', { body: params, headers: headers }).subscribe(result => {

                if (result['status'] == 'success') {

                    this.sharedAlerts.SnackMessage('Solicitação enviada com sucesso!', 'OK');
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.form.reset();

                } else {

                    this.sharedAlerts.SnackMessage('Ocorreu um erro ao enviar!', 'Erro');

                }
                
                this.loading = false;

            }, error => {

                this.sharedAlerts.SnackMessage('Ocorreu um erro ao enviar!', 'Erro');

                this.loading = false;
            
            });
        
        }
    
    }

    abriSite() {

        window.open('https://gestacard.app/cadastre-se', '_blank', 'closebuttoncaption=Cancelar,hidenavigationbuttons=yes,footer=yes,footercolor=#47b377,closebuttoncolor=#FFFFFF');

    }

    get nome() { return this.form.get('nome'); }
    get email() { return this.form.get('email'); }
    get telefone() { return this.form.get('telefone'); }
    get mensagem() { return this.form.get('mensagem'); }

}
