import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { MedicoService } from 'app/pages/medico/medico.service';
import { PacienteService } from '../../paciente.service';
import { Paciente, CartaoGestante, ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-dados-consulta-paciente',
    templateUrl: './dados-consulta.component.html',
    styleUrls: ['./dados-consulta.component.scss']
})
export class DadosConsultaComponent implements OnInit {

    paciente: Paciente;
    cartao: CartaoGestante;

    consulta: ConsultaPaciente;

    @ViewChild('dados_consulta') componente_dados_consulta: any;

    form_data: FormGroup;
    form_dados_consulta: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private snackService: AlertsService,
        private pacienteService: PacienteService
    ) {

        try {

            const navigation = this.router.getCurrentNavigation();

            if (!navigation) this.router.navigateByUrl('/medico');
            if (!navigation.extras || !navigation.extras.state) this.router.navigateByUrl('/medico');
            
            const state = navigation.extras.state;
            if (!state.paciente || !state.cartao || !state.consulta) this.router.navigateByUrl('/medico');

            this.paciente = state.paciente ? state.paciente : null;
            this.cartao = state.cartao ? state.cartao : null;
            this.consulta = state.consulta ? state.consulta : null;

        } catch(err) {

            console.log('MedicoAlterarCartaoGestanteComponent -> constructor -> err', err);
            this.router.navigateByUrl('/medico');

        }
     
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.consulta != null) {
            this.form_data = this.componente_dados_consulta.dataConsulta;
            this.form_dados_consulta = this.componente_dados_consulta.dadosConsulta;

            this.form_data.setValue({
                data: this.consulta.data,
                hora: this.consulta.hora ? this.consulta.hora : ''
            });

            this.form_dados_consulta.setValue({
                ig_dum: this.consulta.ig_dum,
                ig_usg: this.consulta.ig_usg,
                peso: this.consulta.peso,
                pa: this.consulta.pa,
                au: this.consulta.au,
                bcf: this.consulta.bcf,
                mf: this.consulta.mf,
                edema: this.consulta.edema,
                anotacoes_gerais: this.consulta.anotacoes_gerais,
            });

            this.form_data.disable();
            this.form_dados_consulta.disable();
        }
    }

    Voltar() {
        this.router.navigate(['/paciente-consultas']);
    }
}
