import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Paciente, CartaoGestante, ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { ListaConsulta } from 'app/core/interfaces/listaconsulta.interface';

@Injectable({
    providedIn: 'root'
})
export class PacienteService {

  usuario: Paciente;

  constructor(
    private authService: AuthService,
    private _apigestacard: APIGestaCardService
  ) {
    this.GetUserInfo();
  }

  GetUserInfo(): Promise<Paciente> {
    return new Promise((resolve, reject) => {
      this.authService.LerComoObs().subscribe(async (res) => {
        if (res && res.id != undefined) {
          try {
            var usuario = await this.PegarPacientePorId(res.id);
            this.usuario = usuario;
            resolve(usuario);
          } catch (error) {
            reject(error);
          }
        }
      })

    });
  }

  UpdateProfile(paciente: Paciente): Promise<Paciente> {
    
    return new Promise((resolve, reject) => {
      
      this._apigestacard.AtualizarPaciente(paciente).then(async (result) => {

        await this.authService.AtualizarDadosDoUsuario(paciente.email, 'paciente');

        resolve(result);

      }).catch((err) => {
        reject(err);
      });

    });

  }

  PegarPacientePorId(id: string): Promise<Paciente> {
    return this._apigestacard.PegarPacientePorIdCompletasso(id);
  }

  GetConsultasPaciente(): Promise<ListaConsulta | ConsultaPaciente[]> {
    
    return this._apigestacard.ListaConsultasPaciente(this.usuario.id);

  }

  GetCartaoAtivado(): Promise<CartaoGestante> {
    return new Promise(async (resolve, reject) => {
      try {
        var paciente = await this.GetUserInfo();

        var cartao_ativo = paciente.cartao_gestante.filter((cartao) => {
          return cartao.ativado == true;
        });

        //Resolvendo os cartoes ativado = false mas que foram encerrados (fim da gestação)
        var cartao_encerrado = paciente.cartao_gestante.filter((cartao) => {
          return cartao.encerrado == true;
        });

        resolve(cartao_ativo.length ? cartao_ativo[0] : (cartao_encerrado.length ? cartao_encerrado[0] : null));


      } catch (error) {
        reject(error);
      }
    });
  }

  GetTodosCartoes(): Promise<CartaoGestante[]> {
    return new Promise(async (resolve, reject) => {
      try {
        var aux = await this.GetUserInfo();
        resolve(aux.cartao_gestante);
      } catch (error) {
        reject(error)
      }
    });
  }
}
