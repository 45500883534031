import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CartaoGestante } from 'app/core/interfaces/pacient-info.interface';

@Component({
    selector: 'app-botao-cartao-gestante',
    templateUrl: './botao-cartao-gestante.component.html',
    styleUrls: ['./botao-cartao-gestante.component.scss']
})
export class BotaoCartaoGestanteComponent implements OnInit {

    @Input() cartao: CartaoGestante;
    @Output() abrir_cartao = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    ConvertTimeStampToDate(timestamp: { seconds: number, nanoseconds: number }): string {
        const date = new Date(timestamp.seconds * 1000);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

}
