import { Usuario } from './usuario.interface';

export class Secretaria implements Usuario {
  id?: string;
  primeiro_nome: string; 
  sobrenome: string; 
  idade: string | Date; 
  endereco:string; 
  cidade: string; 
  estado: string; 
  telefone: string; 
  email: string;
  tipo_usuario: string; 
  url?: string;
  medicos: Array<{ id: string}>;
  bloqueado: boolean;

  // Os atributos abaixo são do formato antigo pode estar sendo usado por algum usuario, por isso não foram removidos
  medico?: {
    id: string;
    email: string;
    permissao: string 
  }
  ativa?: boolean;
  hash?: string;
  nome?: string;
  data_criacao?: string | Date


  static create(secretaria: {
    primeiro_nome: string,
    sobrenome: string,
    idade: string,
    endereco: string,
    cidade: string,
    estado: string,
    telefone: string,
    email: string,
    ativado: boolean,
    bloqueado: boolean,
    medicos: Array<{ id: string}>
  }) {
    return {
      primeiro_nome: secretaria.primeiro_nome,
      sobrenome: secretaria.sobrenome,
      idade: secretaria.idade,
      endereco: secretaria.endereco,
      cidade: secretaria.cidade,
      estado: secretaria.estado,
      telefone: secretaria.telefone,
      email: secretaria.email,
      ativado: false,
      bloqueado: false,
      tipo_usuario: 'secretaria',
      medicos: secretaria.medicos
    };
  }
}