import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Events {
    private _channels: { [key: string]: ((...args: any[]) => void)[] } = {};
    public subscribe(topic: string, handler: (...args: any[]) => void) {
        if (!this._channels[topic]) {
            this._channels[topic] = [];
        }
        this._channels[topic].push(handler);
    }
    public publish(topic: string, ...args: any[]) {
        if (this._channels[topic] && this._channels[topic].length) {
            this._channels[topic].forEach(handler => handler(...args));
        }
    }
    public unsubscribe(topic: string, handler: ((...args: any[]) => void) = null) {
        if (!handler) {
            if (this._channels[topic]) { delete this._channels[topic]; }
            return;
        }
        const index = this._channels[topic].indexOf(handler);
        if (index > 0) {
            this._channels[topic].splice(index, 1);
        }
        if (!this._channels[topic].length) {
            delete this._channels[topic];
        }
    }
}
