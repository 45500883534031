import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  imgURL: any = null;
  public imagePath;
  public message: string;
  public tipo_usuario: string;

  public foto_mudou = false;

  @Input() url_foto_antiga: string = null;
  @Input() showAsCard = true;

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this.tipo_usuario = this._authService.tipo_usuario;
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {

      this.imgURL = reader.result;
      this.foto_mudou = true;

    };
  }

}
