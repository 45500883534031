import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/core/auth/auth.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { OverlayService } from 'app/shared/overlay.service';

@Component({
  selector: 'alter-password',
  templateUrl: './alter-password.component.html',
  styleUrls: ['./alter-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AlterPasswordComponent implements OnInit {
  email: string;
  alterPasswordForm: FormGroup;
  code: string;
  isCode: boolean = true;
  isPassword: boolean = false;
  rememberMe: boolean = false;
  visiblePassword: boolean = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private sharedAlerts: AlertsService,
    private overlayService: OverlayService,
  ) {
    const state = this.router.getCurrentNavigation().extras.state;

    this.email = state ? state.email : null; 

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.alterPasswordForm = this._formBuilder.group({
      code: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  EnviarForm() {

    if (this.alterPasswordForm.valid) {

      this.overlayService.show();

      let code = this.alterPasswordForm.controls.code.value;
      let password = this.alterPasswordForm.controls.password.value;
      let confirmPassword = this.alterPasswordForm.controls.confirmPassword.value;

      if (password != confirmPassword) {
        this.sharedAlerts.SnackMessage('As senhas não coincidem!');
        this.overlayService.hide();
        return;
      }

      this.auth.ChangePassword(this.email, code, password).then((res) => {

        this.sharedAlerts.SnackMessage(`Senha atualizada com sucesso!`);
        this.router.navigateByUrl('/auth/login');

      }).catch((error) => {

        this.sharedAlerts.SnackMessage(error.message);
        this.overlayService.hide();
        
      }).finally(() => {

        this.overlayService.hide();

      })

    } else {

      this.sharedAlerts.SnackMessage('Preencha todos os campos corretamente!');

      if (this.alterPasswordForm.controls.code.invalid) {
        this.alterPasswordForm.controls.code.markAsTouched();
      }

      if (this.alterPasswordForm.controls.password.invalid) {
        this.alterPasswordForm.controls.password.markAsTouched();
      }

      if (this.alterPasswordForm.controls.confirmPassword.invalid) {
        this.alterPasswordForm.controls.confirmPassword.markAsTouched();
      }

    }

  }

  EsqueceuASenha() {
    var email = this.alterPasswordForm.controls.email.value
    if (email && email != '') {
      var extras: NavigationExtras = {
        state: {
          email
        }
      };
      this.router.navigate(['/auth/esqueceu-senha'], extras);

    } else {
      this.router.navigateByUrl('/auth/esqueceu-senha');
    }
  }

  register() {
    this.router.navigate(['/auth/signup']);
  }

  setVisiblePassword(visiblePassword: boolean) {
      this.visiblePassword = visiblePassword;
  }

}
