import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-historico-clinico',
    templateUrl: './historico-clinico.component.html',
    styleUrls: ['./../cartao-gestante.component.scss']
})
export class HistoricoClinicoComponent implements OnInit {

    @Input('showOpen') showOpen;

    form: FormGroup = new FormGroup({
        tabagismo: new FormControl(''),
        alcoolismo: new FormControl(''),
        hipertensao: new FormControl(''),
        alergia: new FormControl(''),
        aque: new FormControl(''),
        diabetes: new FormControl(''),
        anemia: new FormControl(''),
        cardiopatia: new FormControl(''),
        outros: new FormControl('')
    });
    constructor() { }

    ngOnInit() {
    }

}
