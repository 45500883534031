import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { getParto, getCartaoStatus } from 'app/shared/functions.shared';

@Component({
  selector: 'app-item-usuario',
  templateUrl: './item-usuario.component.html',
  styleUrls: ['./item-usuario.component.scss']
})
export class ItemUsuarioComponent implements OnInit {

  @Output() bloquear = new EventEmitter();
  @Output() desbloquear = new EventEmitter();
  @Output() editar = new EventEmitter();
  @Output() deletar = new EventEmitter();
  @Output() agendar = new EventEmitter();
  @Output() ver = new EventEmitter();

  @Input('tipo_usuario') usuario = 'medico';

  @Input('foto') url_foto = '';

  @Input() numero_paciente = 1;
  @Input() nome = 'nome do médico';
  @Input() numero_pacientes: number | string = 0;
  @Input() semanas_gestacao_dum = 0;
  @Input() semanas_gestacao_us = 0;

  @Input() email: string = '';
  @Input() telefone: string = '';
  @Input() medicos: Array<{ id: string }> = [];

  @Input() ativado: boolean = true;
  @Input() encerrado: boolean = false;

  @Input() fim_data: Date = new Date();
  @Input() fim_hora = '';
  @Input() fim_parto = '';

  @Input() previsao_gestacao_dum = 0;
  @Input() previsao_gestacao_us = 0;

  @Input() usuario_bloqueado = false;

  @Input() proxima_consulta: Date = new Date();

  @Input() maximo_cartoes = 0;
  @Input() cartoes_usados = 0;

  secretariaLogado: boolean;

  public getParto = getParto;
  public getCartaoStatus = getCartaoStatus;
  public tipo_usuario = 'paciente';


  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit() {

    this._authService.usuario_as_obs.subscribe(usuario => {
      if (!usuario) return;

      this.tipo_usuario = usuario.tipo_usuario

      this.secretariaLogado = usuario.tipo_usuario === 'secretaria';

    }, error => {

      console.log(error)

    });

  }

  Acesso() {
    if (this.usuario_bloqueado) {
      this.desbloquear.emit();
    } else {
      this.bloquear.emit();
    }

  }
}
