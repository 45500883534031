import { AfterViewInit, Component, OnInit, Input, ViewChild, EventEmitter, Output, ViewEncapsulation, ElementRef } from '@angular/core';
import { HistoricoClinicoComponent } from './historico-clinico/historico-clinico.component';
import { FormGroup } from '@angular/forms';
import { HistoricoFamiliarComponent } from './historico-familiar/historico-familiar.component';
import { AntecedentesObstetricosComponent } from './antecedentes-obstetricos/antecedentes-obstetricos.component';
import { SobreAGestacaoComponent } from './sobre-a-gestacao/sobre-a-gestacao.component';
import { VacinasComponent } from './vacinas/vacinas.component';
import { CartaoGestante, ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { Events } from 'app/core/events';

@Component({
    selector: 'app-cartao-gestante',
    templateUrl: './cartao-gestante.component.html',
    styleUrls: ['./cartao-gestante.component.scss'],
    encapsulation: ViewEncapsulation.None // <------
})
export class CartaoGestanteComponent implements OnInit, AfterViewInit {

  cartao_gestante: FormGroup;
  // Os componentes filho
  @ViewChild('histclinico') private historico_clinico;
  @ViewChild('histfamiliar') private historico_familiar;
  @ViewChild('antecedentes') private antecedentes_obstetricos;
  @ViewChild('sobre') public sobre_gestacao;
  @ViewChild('vacinas') private vacinas;
  @ViewChild('resumo') private resumo;

  @ViewChild('htmlData') htmlData: ElementRef;

  @Input() paciente;
  @Input() examesLabArr;

  @Input() cartao_gestante_obj: CartaoGestante;

  @Input() dados_graficos_prontos = false;
  @Input() dados_dos_graficos: any = [];

  @Input() consultas: any;

  @Input() exames_edit_mode = false;

  @Input() url_paciente = false;

  dados_prontos2 = false;
  dados_dos_graficos2: any = [];

  // Altura uterina
  labels_uterina: string[] = [];
  data_uterina: { data: number[], label: string };

  @Output() exames_lab_edit_cell = new EventEmitter();

  flexPosCartao = 1;
  flexPosExames = 2;
  flexPosGraficos = 3;

  constructor(private events: Events) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

    this.cartao_gestante = new FormGroup({
      historico_clinico: this.historico_clinico.form,
      historico_familiar: this.historico_familiar.form,
      antecedentes_obstetricos: this.antecedentes_obstetricos.form,
      sobre_a_gestacao: this.sobre_gestacao.form,
      vacinas: this.vacinas.form,
    });

    this.events.unsubscribe('CartaoGestanteDataLoad');
    this.events.subscribe('CartaoGestanteDataLoad', () => {

      if (this.resumo && 'cartao_gestante_obj' in this.resumo) this.resumo.cartao_gestante_obj = this.cartao_gestante_obj; 
      
      // Validando a posição dos exames, deixando acima quando o cartão estiver preenchido, e abaixo quando os dados do cartão ainda não tiverem sido preenchidos.
      if (this.dadosCartaoPreenchidos()) {

        this.flexPosExames = 1;
        this.flexPosCartao = 2;

      } else {

        this.flexPosCartao = 1;
        this.flexPosExames = 2;

      }

      if (this.cartao_gestante_obj && this.cartao_gestante_obj.ativado == false) this.DisableForm();
      else this.cartao_gestante.enable();

      if (this.url_paciente) this.DisableForm();

      this.OrdenarUltrassom();

    });

  }

  dadosCartaoPreenchidos() {

    if (this.testEmptyForm(this.sobre_gestacao.form)) { return false; }
    if (this.testEmptyForm(this.historico_clinico.form)) { return false; }
    if (this.testEmptyForm(this.historico_familiar.form)) { return false; }

    return true;

  }

  testEmptyForm(form: FormGroup) {

    const ignore = ['alteracoes', 'aque', 'outros', 'a', 'complicacoes'];

    for (const key in form.controls) {

      if (ignore.indexOf(key) > -1 || typeof form.controls[key].value == 'object') { continue; }

      if (form.controls[key].value === null || form.controls[key].value === '') {
          // console.log('testEmptyForm -> '+key, typeof form.controls[key], form.controls[key].value);
          return true;
      }

    }

    return false;
  }

  ShowResult() {

  }

  SetData() {

  }

  DisableForm() {

    this.cartao_gestante.disable();

  }

  OrdenarUltrassom() {

    if (!this.cartao_gestante_obj || !this.cartao_gestante_obj.hasOwnProperty('exames') || !this.cartao_gestante_obj.exames.hasOwnProperty('ultrasom')) { return; }

    this.cartao_gestante_obj.exames['ultrasom'].sort((a, b) => (<any>new Date(a.data) - <any>new Date(b.data)));

  }
}
