import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Events } from 'app/core/events';
import { AlertsService } from 'app/shared/alerts.service';


@Component({
  selector: 'app-exames-clinicos',
  templateUrl: './exames-clinicos.component.html',
  styleUrls: ['./exames-clinicos.component.scss', './../tabelas.scss']
})
export class ExamesClinicosComponent implements OnInit {

  displayedColumns = ['data', 'igdum', 'igusg', 'peso', 'pa', 'auca', 'apres', 'bcf', 'mov_fetal', 'edema', 'diag'];
  dataSource = [];

  @Input() exames_clinicos;
  @Input('edit_mode') edit;

  constructor(
    private events: Events,
    private snackService: AlertsService,
  ) { }

  ngOnInit() {

    this.dataSource = this.exames_clinicos;

  }

  InserirClinico() {

    this.events.publish('ExameClinicoIncluir');

  }

  editarExameClinico(linha, index, arr) {
    const data = new Date();
    const dataExame = Date.parse(linha.data);
    const dataHoje = data.setDate(data.getDate() - 1);
    
    if (dataHoje - dataExame < 86400000) {
      this.events.publish('ExameClinicoEditar', linha);
    } else {
      this.snackService.SnackMessage('Expirou o tempo para alterar esse exame');
    }

  }


  ngOnChanges(changes: SimpleChanges) {

    this.dataSource = this.exames_clinicos;

  }

} 
