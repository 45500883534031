import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Events } from 'app/core/events';

@Component({
  selector: 'app-ultrasonografias',
  templateUrl: './ultrasonografias.component.html',
  styleUrls: ['./ultrasonografias.component.scss', './../tabelas.scss']
})
export class UltrasonografiasComponent implements OnInit {

  displayedColumns: string[] = ['data', 'ig', 'peso_fetal', 'placenta', 'outros'];
  dataSource = [];
  @Input() ultrasom;
  @Input('edit_mode') edit;

  constructor(private events: Events) { }

  ngOnInit() {
    this.dataSource = this.ultrasom;
  }

  InserirUltrassonografia() {
    this.events.publish('ExameUltrassonografiaIncluir');
  }

  ngOnChanges(changes: SimpleChanges) {

    this.dataSource = this.ultrasom;

  }

}
