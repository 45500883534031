export function getParto(cod: string) {

    if (cod == 'PC') {
        return 'Parto Cesária';
    }
    if (cod == 'PN') {
        return 'Parto Normal';
    }

    return '- -'; // A = Aborto não escrever.

}

export function getCartaoStatus(ativado, encerrado?) {

    if (encerrado) {
        return 'Encerrado';
    }
    if (!ativado) {
        return 'Desativado';
    }

    return 'Ativo'; // A = Aborto não escrever.

}

