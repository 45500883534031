import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-item-consulta',
    templateUrl: './item-consulta.component.html',
    styleUrls: ['./item-consulta.component.scss']
})
export class ItemConsultaComponent implements OnInit {

    @Input() nome_medico = '';
    @Input() especialidade = '';
    @Input() private datetime: Date = null;

    @Output('clicked') evento_clicado = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    get dia() {
        const data = this.datetime.getDate();
        const mes = `${('0' + ((this.datetime.getMonth()) + 1)).slice(-2)}`;
        return `${data}/${mes}`;
    }

    get hora() {
        const horas = this.datetime.getHours();
        const minutos = this.datetime.getMinutes();
        return `${horas}h${minutos}`;
    }

}
