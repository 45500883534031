import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-vacinas',
    templateUrl: './vacinas.component.html',
    styleUrls: ['./../cartao-gestante.component.scss']
})
export class VacinasComponent implements OnInit {

    form: FormGroup = new FormGroup({
        tri_previa: new FormControl(),
        tri_dose1: new FormControl(),
        tri_dose2: new FormControl(),
        tri_dose3: new FormControl(),
        dt_previa: new FormControl(),
        dt_dose1: new FormControl(),
        dt_dose2: new FormControl(),
        dt_dose3: new FormControl(),
        hep_previa: new FormControl(),
        hep_dose1: new FormControl(),
        hep_dose2: new FormControl(),
        hep_dose3: new FormControl(),
        colpo_data: new FormControl(),
        colpo_resultado: new FormControl(),
        colpo_observacoes: new FormControl(),
    });

    vacinas_nomes: string[] = ['Triplice Bacteriana acelular do tipo adulto (diferia, tétano, e coqueluche) - dTpa ou dTpa-Vp', 'Dupla Adulto (difteria e tétano) - dT', 'Hepatite B'];
    constructor() { }

    ngOnInit() {
    }

}
