import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-modal-edicao-secretaria',
    templateUrl: './modal-edicao-secretaria.component.html',
    styleUrls: ['./modal-edicao-secretaria.component.scss'],
})
export class ModalEdicaoSecretariaComponent implements OnInit {

    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialogRef: MatDialogRef<ModalEdicaoSecretariaComponent>,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.form = this._formBuilder.group({
            nome: [''],
            ativa: [''],
            data_criacao: [''],
            hash: ['']
        });

        if (this.data) { this.form.patchValue(this.data); }
    }

    Salvar() {
        this.dialogRef.close(this.form.value);
    }
}
