import { CartaoContasService } from 'app/shared/components/cartao-gestante/cartao-contas.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { getParto } from 'app/shared/functions.shared';

@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['./resumo.component.scss']
})
export class ResumoComponent implements OnInit {

  @Input() paciente;

  @Input() dum: any;

  @Input() usg: Date;

  @Input() cartao_gestante_obj: CartaoGestante;

  @Input() semanas_us: string;
  @Input() dias_us: string;
  @Input() data_parto: Date | string;

  @Input() url_paciente = false;

  resumo;

  public getParto = getParto;

  constructor(
    private cartao: CartaoContasService
  ) { }

  ngOnInit() {

    this.drawResume();

  }

  drawResume() {
    this.resumo = this.cartao.ResumoCartao(this.dum, this.usg, parseInt(this.semanas_us), this.dias_us == '' ? 0 : parseInt(this.dias_us), this.cartao_gestante_obj.hasOwnProperty('fim_data') ? this.cartao_gestante_obj.fim_data : null);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.drawResume();
  }

}
