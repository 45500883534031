import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paciente, CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { CartaoContasService } from '../cartao-gestante/cartao-contas.service';
import { Medico } from 'app/core/interfaces/medico.interface';

@Component({
    selector: 'app-paciente-info',
    templateUrl: './paciente-info.component.html',
    styleUrls: ['./paciente-info.component.scss']
})
export class PacienteInfoComponent implements OnInit {

    @Input('paciente_info') info: Paciente = null;
    @Input('cartao') cartao_gestante: CartaoGestante = null;
    @Input('medico') medico_gestante: Medico = null;

    @Output('cartao_gestante') public botao = new EventEmitter();

    constructor(private contasCartaoService: CartaoContasService) { }

    ngOnInit() {
    }

    ConvertTimeStampToDate(timestamp: { seconds: number, nanoseconds: number }): string {
        const date = new Date(timestamp.seconds * 1000);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    SemanasGestante(concepcao: { seconds: number, nanoseconds: number }): any {
        const today = new Date();
        const concep = new Date(concepcao.seconds * 1000);
        let dias_de_gravidez: number = Math.abs(today.getTime() - concep.getTime());
        dias_de_gravidez /= (3600 * 24 * 1000);
        dias_de_gravidez = Math.ceil(dias_de_gravidez);
        const semanas = Math.floor(dias_de_gravidez / 7);
        return semanas;
    }

    get previsaoDum() {
        const resultado = this.contasCartaoService.PrevisaoDum(this.cartao_gestante.sobre_a_gestacao.dum);
        return resultado;
    }

    get previsaoUltrassom() {
        const aux = this.cartao_gestante.sobre_a_gestacao;
        const resultado = this.contasCartaoService.PrevisaoUltrassom(aux.us1, aux.us_semanas, aux.us_dias);
        return resultado;
    }

    get semanasGestacaoDum() {
        return this.contasCartaoService.SemanasGestacaoDum(this.cartao_gestante.sobre_a_gestacao.dum);
    }

    get semanasGestacaoUs() {
        return this.contasCartaoService.SemanasGestacaoUs(
            this.cartao_gestante.sobre_a_gestacao.us1,
            this.cartao_gestante.sobre_a_gestacao.us_semanas,
            this.cartao_gestante.sobre_a_gestacao.us_dias);
    }

}
