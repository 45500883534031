import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MedicoService } from '../../medico.service';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { OverlayService } from 'app/shared/overlay.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';

@Component({
  selector: 'app-medico-cadastrar-paciente',
  templateUrl: './medico-cadastrar-paciente.component.html',
  styleUrls: ['./medico-cadastrar-paciente.component.scss']
})
export class MedicoCadastrarPacienteComponent implements OnInit, AfterViewInit {

  form: FormGroup; 
  usuario_medico: any; 

  bloqueado_por_limite_de_cartoes = false;

  loading = false; 

  @ViewChild('cadastro_paciente') componente_cadastro_paciente; 
  @ViewChild('imageUploader') file_uploader_component;

  constructor(
    private medicoService: MedicoService, 
    private snackService: AlertsService, 
    private router: Router, 
    private overlayService: OverlayService,
    private _apigestacard: APIGestaCardService
    ) { }

  ngOnInit() {

  }

  BuscarPerfilMedico() {

    this.medicoService.GetUserData().subscribe((res) => {

      this.usuario_medico = res; 
      
      if(res) {
        this.form.patchValue({
          medico: `${res.primeiro_nome} ${res.sobrenome}`, 
          telefone_medico: res.telefone, 
          crm: res.crm,
        }); 
      }

      this.medicoService.TotalCartoesUsadosPeloMedico(res.id).then((result) => {
        
        if (result >= res.maximo_cartoes) {
          
          this.bloqueado_por_limite_de_cartoes = true;
        }
        
      }).catch((err) => {

        console.log("MedicoCadastrarPacienteComponent -> BuscarPerfilMedico -> err", err)
        
      });
      
    });
     
  }

  ngAfterViewInit() {

    this.form = this.componente_cadastro_paciente.form; 

    this.componente_cadastro_paciente.enabledSearchEmail = true; 

    this.BuscarPerfilMedico(); 

  }

  SalvarPaciente() {

    if (this.form.valid) {

      this.loading = true;
      this.overlayService.show(); 

      var aux = this.form.value; 
      aux.medico_app = this.usuario_medico.id; 
      const paciente_criado = Paciente.create(aux); 

      this.medicoService.CadastrarPaciente(paciente_criado).then(async (res) => {

        try {

          var imagem_aux = this.file_uploader_component.imagePath;

          // Salvando a imagem 
          if (imagem_aux) {
            var imagem = imagem_aux[0];
            var url_imagem = await this._apigestacard.UploadImage(imagem);

            await this._apigestacard.AtualizarUrlPerfil(res.id, url_imagem);
          }

          this.snackService.SnackMessage('Cadastro feito com sucesso!'); 
          this.router.navigate(['/medico']);
          
        } catch (error) {

          console.log("MedicoCadastrarPacienteComponent -> SalvarPaciente -> error", error);

        }

      }).catch((err) => {
        
        this.snackService.SnackMessage('Erro ao cadastrar paciente.'+(err.hasOwnProperty('message') ? ' '+err.message : '' )); 

      }).finally(() => {

        this.loading = false;
        this.overlayService.hide(); 

      })

    } else {

      this.snackService.SnackMessage('Formulário invalido'); 

    }

  }

}
