import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-item-ativacao-usuario',
    templateUrl: './item-ativacao-usuario.component.html',
    styleUrls: ['./item-ativacao-usuario.component.scss']
})
export class ItemAtivacaoUsuarioComponent implements OnInit {

    @Input('nome') nome_medico: string;
    @Input('ativado') medico_ativado = false;

    @Output() reenviar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

}
