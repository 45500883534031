import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MedicoService } from '../../medico.service';
import { AlertsService } from 'app/shared/alerts.service';
import { FormGroup } from '@angular/forms';
import { CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { ModalsService } from 'app/shared/modals.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { Secretaria } from 'app/core/interfaces/secretaria.interface';

@Component({
  selector: 'app-medico-alterar-secretaria',
  templateUrl: './medico-alterar-secretaria.component.html',
  styleUrls: ['./medico-alterar-secretaria.component.scss']
})
export class MedicoAlterarSecretariaComponent implements OnInit, AfterViewInit {

  loading: boolean = true; 
  email_secretaria: string; 
  secretaria_id: string; 
  secretaria: Secretaria; 

  emails_medicos: string[];
  secretariaLogada: boolean;

  form: FormGroup; 

  @ViewChild('imageUploader') image_uploader; 
  @ViewChild('cadastro_secretaria') componente_cadastro_secretaria; 
  
  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private medicoService: MedicoService, 
    private snackService: AlertsService, 
    private modalService: ModalsService, 
    private _apigestacard: APIGestaCardService, 
  ) {

    try {

      var navigation = this.router.getCurrentNavigation();

      if (!navigation) this.router.navigateByUrl('/medico');
      if (!navigation.extras || !navigation.extras.state) this.router.navigateByUrl('/medico');
      
      const state = navigation.extras.state;
      if (!state.secretaria) this.router.navigateByUrl('/medico');

      this.email_secretaria = state ? state.secretaria.email : null; 
      this.secretaria_id = state ? state.secretaria.id : null; 
      this.secretariaLogada = localStorage.getItem('secretariaHash') ? true : false;

    } catch(err) {

      console.log('MedicoAlterarCartaoGestanteComponent -> constructor -> err', err);
      this.router.navigateByUrl('/medico');

    }
     
  }

  ngOnInit() {
    this.AtualizarEmailMedicos(); 
  }

  ngAfterViewInit() {
    this.form = this.componente_cadastro_secretaria.form; 

    if(this.secretaria_id) {
      this.BuscarSecretaria(this.secretaria_id);
    }
  }

  /** Buscar dados no firebase */
  AtualizarEmailMedicos() {
    this.medicoService.GetListaEmailsMedicos().subscribe((res) => {
      this.emails_medicos = res; 
    });
  }

  BuscarSecretaria(id) {
     
    this.loading = true; 
    
    this.medicoService.GetSecretariaPorId(id).then((res) => {
      
      this.secretaria = res; 
      
      if(this.secretaria) this.form.patchValue(this.secretaria); 
      
      this.form.controls.email.disable(); 

      this.form.controls.medicos.disable(); 

    }).catch((err) => {
       
    }).finally(() => {

      this.loading = false; 

    })

  }

  async Alterar() {

    if (this.form.valid) {

      var novo_objeto_secretaria = { ...this.secretaria, ...this.form.getRawValue() };

      // Salvar o dado no db
      if (this.image_uploader.foto_mudou) {
        
        try {
      
          var imagem = this.image_uploader.imagePath;
          var image_to_upload = imagem[0];
          var url_imagem = await this._apigestacard.UploadImage(image_to_upload);

          novo_objeto_secretaria['url'] = url_imagem;
      
        } catch (err) {
      
          this.snackService.SnackMessage('Erro ao atualizar a imagem do secretaria');
          return;
      
        }
      
      }

      this.medicoService.AtualizarSecretaria(novo_objeto_secretaria).then((res) => {

        this.snackService.SnackMessage('Secretaria atualizado com sucesso!'); 

        this.router.navigateByUrl('/medico'); 

      }).catch((err) => {

        this.snackService.SnackMessage('Erro ao atualizar o secretaria'); 

      })

    }
  
  }

  DeletarSecretaria() {

    this.modalService.AbrirModalDeConfirmacao('Excluir Secretaria', 'Tem certeza que deseja excluir esse secretaria? Essa ação não poderá ser desfeita!, deseja continuar?').subscribe((res:boolean) => {
      if(res)
        this.medicoService.DeletarSecretaria(this.secretaria).then((res) => {
          this.snackService.SnackMessage('Usuario excluido com sucesso!'); 
          this.router.navigate(['/medico']); 
        })
    });

  }

  ResetPassword() {

    this.modalService.AbrirModalDeConfirmacao('Redefinir Senha', 'Confirma o envio do link de redefinição de senha para o usuário, no email  '+this.email_secretaria+'?').subscribe((res:boolean) => {
      if (res)
        this.medicoService.EmailResetSecretaria(this.email_secretaria).then((res) => {
          this.snackService.SnackMessage('Link de definição de senha enviado para o e-mail da secretaria: '+this.email_secretaria); 
          this.router.navigate(['/medico']); 
        });
    });

  }

}
