import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PacienteComponent } from './subpages/paciente/paciente.component';
import { CartaoInfoComponent } from './subpages/cartao-info/cartao-info.component';
import { ConsultasComponent } from './subpages/consultas/consultas.component';
import { CadernetaComponent } from './subpages/caderneta/caderneta.component';
import { CartaoAnterioresComponent } from './subpages/cartao-anteriores/cartao-anteriores.component';
import { EditarPerfilComponent } from '../paciente/subpages/editar-perfil/editar-perfil.component';
import { DadosConsultaComponent } from './subpages/dados-consulta/dados-consulta.component';
import { PacienteGuard } from 'app/core/guards/paciente.guard';

const routes: Routes = [
  {
    path: 'paciente',
    component: PacienteComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'paciente-informacao',
    component: CartaoInfoComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'paciente-consultas',
    component: ConsultasComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'caderneta-gestante',
    component: CadernetaComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'cartoes-anteriores',
    component: CartaoAnterioresComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'editar-perfil-paciente',
    component: EditarPerfilComponent,
    canActivate: [PacienteGuard]
  },
  {
    path: 'paciente-dados-consulta',
    component: DadosConsultaComponent,
    canActivate: [PacienteGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PacienteRoutingModule { }
