import { InserirOutroComponent } from './modals/inserir-outro/inserir-outro.component';
import { InserirUsgComponent } from './modals/inserir-usg/inserir-usg.component';
import { Observable } from 'rxjs';
import { InserirClinicoComponent } from './modals/inserir-clinico/inserir-clinico.component';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CustomizeService } from 'app/core/customize/customize.service';
import { MatDialog } from '@angular/material';
import { InserirLabComponent } from './modals/inserir-lab/inserir-lab.component';

@Injectable({
    providedIn: 'root'
})
export class CartaoContasService {

    public exames = ['ABO/Rh', 'Hepatite B', 'Hepatite C', 'Plaquetas', 'HT/HB', 'CMV IgG', 'CMV IgM', 'TSH', 'Glicose em Jejum', 'TTOG (GTT)', 'HIV', 'VDRL', 'HBsAG', 'COOMBs Ind', 'Toxo IgM', 'Toxo IgG', 'Rubéola IgM', 'Rubéola IgG', 'Urina I', 'Urocultura', 'Parasitológico', 'Protoparasitológico', 'Cultura Streptococcus', 'Outros'];

    total_semanas: number;

    constructor(
        private customVariables: CustomizeService,
        private dialog: MatDialog
    ) {
        this.total_semanas = this.customVariables.semanas_gestacionais;
    }

    PrevisaoDum(data: Date | string) {
        if (!data) {
            return null;
        }
        const mon = moment(data);

        const numero_semanas = this.total_semanas;
        mon.add(numero_semanas, 'week');

        return mon.toDate();

    }

    PrevisaoUltrassom(data_ultrasom: Date | string, semanas: string, dias: string): Date {
        if (semanas == '' || data_ultrasom == undefined || data_ultrasom == null) {
            return null;
        } else {
            const days = dias == '' ? '0' : dias;
            const total_dias = parseInt(semanas) * 7 + parseInt(days);

            const data_ultrassom_m = moment(data_ultrasom);


            const dias_restantes = this.total_semanas * 7 - total_dias;

            data_ultrassom_m.add(dias_restantes, 'days');


            const result = data_ultrassom_m.toDate();
            return result;
        }
    }

    SemanasGestacaoDum(data: Date | string, data_parto?: Date | string): number {
        if (data == null) {
            return 0;
        }
        const dum = moment(data);
        const diff = data_parto ? moment(data_parto).diff(dum, 'days') : moment().diff(dum, 'days');
        return diff;
    }

    SemanasGestacaoUs(data_ultrasom: Date | string, semanas: string, dias: string, data_parto?: Date | string): number {

        if (semanas == '' || data_ultrasom == undefined || data_ultrasom == null) {

            return 0;

        } else {

            const days = dias == '' ? '0' : dias;
            const total_dias = parseInt(semanas) * 7 + parseInt(days);

            const today = moment();
            const data_us = moment(data_ultrasom);
            let dias_de_gestacao;

            if (data_parto) {

                const diff = moment(data_parto).diff(data_us.subtract(total_dias, 'days'), 'days');

                dias_de_gestacao = diff;

            } else {

                const diff = today.diff(data_us, 'days');

                dias_de_gestacao = total_dias + diff;

            }


            // var total_semanas = Math.round(dias_de_gestacao/7);
            return dias_de_gestacao;

        }

    }

    Imc(altura_cm: number, peso: number): number {
        const altura_m = altura_cm / 100;
        return peso / (altura_m * altura_m);
    }

    AbrirModalClinicos(
        dum: any = null,
        usg: any = null,
        semanas: string = null,
        dias: string = null): Observable<any> {

        console.log('AbrirModalClinicos: ', dum, usg, semanas, dias);

        const dialogRef = this.dialog.open(InserirClinicoComponent, {
            width: `800px`,
            data: {
                igdum: this.SemanasGestacaoDum(dum),
                igusg: this.SemanasGestacaoUs(usg, semanas, dias)
            }
        });

        return dialogRef.afterClosed();
    }

    AbrirModalClinicosEditar(dataOld = null): Observable<any> {
        const dialogRef = this.dialog.open(InserirClinicoComponent, {
            width: `800px`,
            data: {
                apres: dataOld.apres ? dataOld.apres : '',
                auca: dataOld.auca ? dataOld.auca : '',
                bcf: dataOld.bcf ? dataOld.bcf : '',
                data: dataOld.data ? dataOld.data : '',
                diag: dataOld.diag ? dataOld.diag : '',
                edema: dataOld.edema ? dataOld.edema : '',
                hora: dataOld.hora ? dataOld.hora : '',
                igdum: dataOld.igdum ? dataOld.igdum : '',
                igusg: dataOld.igusg ? dataOld.igusg : '',
                mov_fetal: dataOld.mov_fetal ? dataOld.mov_fetal : '',
                pa: dataOld.pa ? dataOld.pa : '',
                peso: dataOld.peso ? dataOld.peso : '',
            }
        });

        return dialogRef.afterClosed();
    }

    AbrirModalLab(exames_anteriores: any): Observable<any> {
        const dialogRef = this.dialog.open(InserirLabComponent, {
            width: `800px`,
            data: {
                exames_anteriores
            }
        });

        return dialogRef.afterClosed();
    }

    AbrirModalUSG(): Observable<any> {
        const dialogRef = this.dialog.open(InserirUsgComponent, {
            width: `800px`
        });

        return dialogRef.afterClosed();
    }

    AbrirModalOutros(): Observable<any> {
        const dialogRef = this.dialog.open(InserirOutroComponent, {
            width: `800px`
        });

        return dialogRef.afterClosed();
    }

    ResumoCartao(dum: Date, us_date: Date, us_semanas: number, us_dias: number, data_parto?: Date | string): any {
        const dpp_dum = this.PrevisaoDum(dum);
        const dum_resumo = {
            dum: dum,
            data_concepcao: this.DataProvavelConcepcao(dum),
            fim_1tri: this.FimPrimeiroTrimestre(dpp_dum),
            fim_2tri: this.FimSegundoTrimestre(dpp_dum),
            dpp: dpp_dum,
            ig: this.SemanasGestacaoDum(dum, data_parto)
        };

        const dpp_ultrasom = this.PrevisaoUltrassom(us_date, `${us_semanas}`, `${us_dias}`);
        const dum_usg = this.CalcularDumPorUSG(dpp_ultrasom);

        console.log('DATA:'+us_date+". SEMANAS:"+us_semanas+". DIAS:"+us_dias)
        console.log(this.SemanasGestacaoUs(us_date, `${us_semanas}`, `${us_dias}`, (data_parto ? data_parto : null)))

        const usg_resumo = {
            us_date,
            us_semanas,
            us_dias,
            ig: this.SemanasGestacaoUs(us_date, `${us_semanas}`, `${us_dias}`, (data_parto ? data_parto : null)),
            data_concepcao: this.DataProvavelConcepcao(dum_usg),
            dum: dum_usg,
            dpp: dpp_ultrasom,
            fim_1tri: this.FimPrimeiroTrimestre(dpp_ultrasom),
            fim_2tri: this.FimSegundoTrimestre(dpp_ultrasom),
        };

        return {
            dum: dum_resumo,
            usg: usg_resumo
        };
    }

    CalcularDumPorUSG(dpp: Date): Date {
        const semanas = 40;
        const dpp_moment = moment(dpp);
        const dum = dpp_moment.subtract(semanas, 'weeks');
        return dum.toDate();
    }

    DataProvavelConcepcao(dum): Date {
        const media_dias = 14;
        const dum_moment = moment(dum);
        const nova_data = dum_moment.add(media_dias, 'days');
        return nova_data.toDate();
    }

    FimPrimeiroTrimestre(dpp: Date) {
        const dpp_moment = moment(dpp);
        const fim_1tri = dpp_moment.subtract(6, 'months');
        return fim_1tri.toDate();
    }

    FimSegundoTrimestre(dpp: Date) {
        const dpp_moment = moment(dpp);
        const fim_1tri = dpp_moment.subtract(3, 'months');
        return fim_1tri.toDate();
    }

    CalcularTrimestres(dpp: Date) {

        const dateBase = dpp ? moment(dpp) : moment();

        const tri3 = dateBase.subtract(3, 'months').toDate();
        const tri2 = dateBase.subtract(3, 'months').toDate();
        const tri1 = dateBase.subtract(3, 'months').toDate();

        return [tri1, tri2, tri3];

    }

}
