import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./../modals.global.scss']
})
export class ConfirmModalComponent implements OnInit {

    texto_titulo = 'titulo';
    texto_conteudo_modal = 'conteudo';
    texto_confirmacao = 'Continuar';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmModalComponent>
    ) { }

    ngOnInit() {
        this.texto_titulo = this.data.titulo;
        this.texto_conteudo_modal = this.data.conteudo;
        this.texto_confirmacao = this.data.confirmacao ? this.data.confirmacao : 'Continuar';
    }

}
