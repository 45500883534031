import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-cadastrar-secretaria',
  templateUrl: './cadastrar-secretaria.component.html',
  styleUrls: ['./cadastrar-secretaria.component.scss']
})
export class CadastrarSecretariaComponent implements OnInit {

  @Input() cadastro_admin: boolean = false; 
  @Input() lista_medicos: any[] = []; 

  @Input() edit_secretaria: boolean = false; 

  form: FormGroup = new FormGroup({
    primeiro_nome: new FormControl('', Validators.required), 
    sobrenome: new FormControl('', Validators.required), 
    idade: new FormControl('', Validators.required), 
    endereco: new FormControl('', Validators.required), 
    cidade: new FormControl('', Validators.required), 
    estado: new FormControl('', Validators.required), 
    telefone: new FormControl('', Validators.required), 
    email: new FormControl('', [Validators.required, Validators.email]),
    telefone_medico: new FormControl(''), 
    medicos: new FormControl(''),
  });
  
  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    if (this.cadastro_admin != false){

      const formControls = this.lista_medicos.reduce((acc, medico) => {
        acc[medico.id] = [false]; // Inicialmente nenhum checkbox está selecionado
        return acc;
      }, {});

      this.form = this.fb.group({
        medicos: this.fb.group(formControls)
      });
      
    }
  }

  // Função para obter os IDs dos médicos selecionados
  get selectedMedicos() {
    return Object.keys(this.form.get('medicos').value).filter(key => this.form.get('medicos').get(key).value);
  }
  get primeiro_nome() { return this.form.controls.primeiro_nome; };
  get sobrenome() { return this.form.controls.sobrenome; };
  get idade() { return this.form.controls.idade; };
  get endereco() { return this.form.controls.endereco; };
  get cidade() { return this.form.controls.cidade; };
  get estado() { return this.form.controls.estado; };
  get telefone() { return this.form.controls.telefone; };
  get email() { return this.form.controls.email; };
  get telefone_medico() { return this.form.controls.telefone_medico; };
  get medicos() { return this.form.controls.medico; };
}
