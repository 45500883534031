import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { p10, p25, p50, p90 } from './data-grafico';

@Component({
  selector: 'app-ganho-de-peso',
  templateUrl: './ganho-de-peso.component.html',
  styleUrls: ['./ganho-de-peso.component.scss']
})
export class GanhoDePesoComponent implements OnInit {

  @Input() dados: any = [];
  @Input() estatura: number;
  @Input() peso_inicial: number;

  public showGraph = false;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public initialData = { '12': {}, '13': {}, '14': {}, '15': {}, '16': {}, '17': {}, '18': {}, '19': {}, '20': {}, '21': {}, '22': {}, '23': {}, '24': {}, '25': {}, '26': {}, '27': {}, '28': {}, '29': {}, '30': {}, '31': {}, '32': {}, '33': {}, '34': {}, '35': {}, '36': {}, '37': {}, '38': {}, '39': {}, '40': {} };
  public barChartLabels = [];
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData;
  constructor() {
  }
  ngOnInit() {
    
    if (this.peso_inicial) { this.updateCharData(); }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (this.peso_inicial) { this.updateCharData(); }

  }

  updateCharData() {
    let dadosPrepared = {};

    this.barChartLabels = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40]; // X

    this.barChartData = [{ // Dados iniciais do paciente
      data: [],
      label: 'Gestante',
      fill: false
    }, {
      data: p10,
      label: 'P10'
    }, {
      data: p25,
      label: 'P25'
    }, {
      data: p50,
      label: 'P50'
    }, {
      data: p90,
      label: 'P90'
    }];

    this.dados = this.dados.filter(element => element.igdum !== null && element.peso !== null && element.igdum <= 287); // Filtra os dados para remover os nulos e os menores que 287 (41 semanas)

    this.dados.sort((f, s) => { // Deixa os dados na ordem certa
      if (f.igdum < s.igdum) { return -1; }
      else { return 1; }
    });

    this.dados.forEach(item => {// Deixa os dados no formato X Y R para o grafico
      dadosPrepared[`${Math.floor(item.igdum / 7)}`] = {
        x: Math.floor(item.igdum / 7),
        y: item.peso,
        r: 0
      };
    });

    const primeiroElemento = Math.floor(this.dados[0].igdum / 7);
    const ultimoElemento = Math.floor(this.dados[this.dados.length - 1].igdum / 7);

    this.barChartLabels = this.barChartLabels.filter(element => { // Removendo lados vazios no grafico
      return element >= primeiroElemento && element <= ultimoElemento;
    });

    this.barChartData = this.barChartData.map(element => {// Removendo lados vazios no grafico
      const data = element.data.filter(dataElement => {
        return dataElement.x >= primeiroElemento && dataElement.x <= ultimoElemento;
      });

      element.data = data;

      return element;
    });

    dadosPrepared = { ...this.initialData, ...dadosPrepared }; // Mergea os dados do paciente com os campos necessarios do grafico para evitar zeros no grafico em campos vazios

    Object.keys(dadosPrepared).forEach(element => {
      if (dadosPrepared[`${element}`].y && this.peso_inicial /*&& this.estatura*/) {
        if (dadosPrepared[`${element}`].x >= 12) {
          // --> IMC --> let imc = dadosPrepared[`${element}`].y / ((this.estatura / 100) * (this.estatura / 100))
          const ganhoPeso = dadosPrepared[`${element}`].y - this.peso_inicial;
          this.barChartData[0].data.push({ x: dadosPrepared[`${element}`].x, y: ganhoPeso.toFixed(2) }); // Migranto o grafico com os dados do paciente
        }
      }
    });
    this.showGraph = true;

  }

}
