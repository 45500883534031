import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MedicoService } from '../../medico.service';
import { Medico } from 'app/core/interfaces/medico.interface';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { ModalsService } from 'app/shared/modals.service';
import { AuthService } from 'app/core/auth/auth.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';

@Component({
  selector: 'app-medico-editar-perfil',
  templateUrl: './medico-editar-perfil.component.html',
  styleUrls: ['./medico-editar-perfil.component.scss']
})
export class MedicoEditarPerfilComponent implements OnInit, AfterViewInit {

  formulario: FormGroup;
  perfil: Medico;

  form: FormGroup = new FormGroup({
    primeiro_nome: new FormControl('', [Validators.required]),
    sobrenome: new FormControl('', [Validators.required]),
    idade: new FormControl('', [Validators.required]),
    endereco: new FormControl('', [Validators.required]),
    cidade: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    name_instagram: new FormControl(''),
    telefone: new FormControl('', [
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
      Validators.maxLength(11)
    ]),
    whatsapp: new FormControl('', [
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(11),
      Validators.maxLength(11)
    ]),
    // maximo_cartoes: new FormControl(5, Validators.required), 
    crm: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });


  maximo_cartoes = 0;
  cartoes_usados = 0;

  /** Formulário de atualizar senha */
  atualizarSenha: FormGroup = new FormGroup({
    senha_atual: new FormControl('', Validators.required),
    nova_senha: new FormControl('', Validators.required),
    confirmar_senha: new FormControl('', Validators.required),
  });

  senhas_nao_iguais = false;

  /** Formulário para atualizar o email */
  atualizarEmail: FormGroup = new FormGroup({
    novo_email: new FormControl('', Validators.required),
    confirmar_email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  email_nao_iguais = false;
  visiblePassword = false;

  loading = true;
  outras_opcoes = false;

  @ViewChild('imageUploader') image_uploader;

  constructor(
    private medicosService: MedicoService,
    private snackService: AlertsService,
    private router: Router,
    private modalService: ModalsService,
    private authService: AuthService,
    private _apigestacard: APIGestaCardService,
  ) { }

  /** Lyfe cicle hooks */

  ngOnInit() {
    this.BuscarPerfilMedico();
  }

  ngAfterViewInit() {
    
  }

  setVisiblePassword(visiblePassword: boolean) {
    this.visiblePassword = visiblePassword;
  }

  /** Funções do componente */
  BuscarPerfilMedico() {

    this.loading = true;
    this.medicosService.GetUserData().subscribe((res: Medico) => {

      this.perfil = res;

      if (res.maximo_cartoes) this.maximo_cartoes = res.maximo_cartoes;

      this.loading = false;

      this.medicosService.TotalCartoesUsadosPeloMedico(this.perfil.id).then((res) => {

        this.cartoes_usados = res;
        this.PreencherFormulario();

      });

    });

  }

  PreencherFormulario() {

    this.form.patchValue(this.perfil);
    this.form.controls.email.disable();

  }

  async SalvarAlteracoes() {

    if (this.form.valid) {

      const novo_obj_usuario = { ...this.perfil, ...this.form.getRawValue() };

      // Verificando se a imagem mudou 
      if (this.image_uploader.foto_mudou) {

        const imagem = this.image_uploader.imagePath;
        const image_to_upload = imagem[0];

        const url = await this._apigestacard.UploadImage(image_to_upload);

        novo_obj_usuario['url'] = url;

      }

      this.medicosService.AtualizarPerfilMedico(novo_obj_usuario).then((res) => {

        this.snackService.SnackMessage('Perfil Atualizado com sucesso!');
        this.authService.AtualizarDadosDoUsuario(this.perfil.email, 'medico');
        this.router.navigate(['/medico']);
        
      }).catch((err) => {

        this.snackService.SnackMessage(err);

      });

    }

  }

  Voltar() {
    this.router.navigateByUrl('/medico');
  }

  AlterarSenha() {

    if (this.atualizarSenha.valid) {

      const senha_atual = this.atualizarSenha.controls.senha_atual.value;
      const nova_senha = this.atualizarSenha.controls.nova_senha.value;
      const confirmar_senha = this.atualizarSenha.controls.confirmar_senha.value;

      // Verificando as senhas
      if (confirmar_senha != nova_senha) {

        this.senhas_nao_iguais = true;

      } else {

        this.senhas_nao_iguais = false;
        this.authService.UpdatePassword(this.perfil.email, senha_atual, nova_senha).then(() => {
            
          this.snackService.SnackMessage('Senha alterada com sucesso!');
          
        }).catch((err) => {

          this.snackService.SnackMessage(`Erro ao atualizar senha: ${JSON.stringify(err)}`);

        });

      }

    }

  }

  AlterarEmail() {

    if (this.atualizarEmail.valid) {

      const novo_email = this.atualizarEmail.controls.novo_email.value;
      const confirmar_email = this.atualizarEmail.controls.confirmar_email.value;
      const password = this.atualizarEmail.controls.password.value;

      if (novo_email != confirmar_email) {

        this.email_nao_iguais = true;

      } else {

        this.email_nao_iguais = false;
        this.authService.UpdateEmail(novo_email, password).then(() => {

          this.snackService.SnackMessage('E-mail atualizado com sucesso!, Faça o login novamente');

          setTimeout(() => this.authService.Logout(), 1000);

        }).catch((err) => {

          this.snackService.SnackMessage(err);

        });

      }

    }

  }

  DeletarPerfil() {
    this.modalService.AbrirModalDeConfirmacao('Deseja Excluir perfil?', 'Todos os seus dados serão apagados, e não será possível recuperá-los, deseja continuar?').subscribe((res: boolean) => {
      if (res) {
        
      }
    });
  }

  get primeiro_nome() { return this.form.get('primeiro_nome'); }
  get sobrenome() { return this.form.get('sobrenome'); }
  get idade() { return this.form.get('idade'); }
  get endereco() { return this.form.get('endereco'); }
  get cidade() { return this.form.get('cidade'); }
  get estado() { return this.form.get('estado'); }
  get telefone() { return this.form.get('telefone'); }
  get whatsapp() { return this.form.get('whatsapp'); }
  get name_instagram() { return this.form.get('name_instagram'); }
  get crm() { return this.form.get('crm'); }
  get email() { return this.form.get('email'); }
}
