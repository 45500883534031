import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule, MatIconModule, MatInputModule } from '@angular/material';

const routes = [
    {
        path: 'signup',
        component: SignupComponent
    }
];

@NgModule({
    declarations: [SignupComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FuseSharedModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
    ]
})
export class SignupModule { }
