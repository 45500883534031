import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-cadastra-medicos',
    templateUrl: './cadastra-medicos.component.html',
    styleUrls: ['./cadastra-medicos.component.scss']
})
export class CadastraMedicosComponent implements OnInit {

    @Input('editar') texto_botao = false;
    @Input('admin_acess') acesso_de_administrador = true;

    form: FormGroup = new FormGroup({
        primeiro_nome: new FormControl('', [Validators.required]),
        sobrenome: new FormControl('', [Validators.required]),
        idade: new FormControl('', [Validators.required]),
        endereco: new FormControl('', [Validators.required]),
        cidade: new FormControl('', [Validators.required]),
        estado: new FormControl('', [Validators.required]),
        name_instagram: new FormControl(''),
        telefone: new FormControl('', [
            Validators.pattern('^[0-9]*$'),
            Validators.minLength(10),
            Validators.maxLength(11)
        ]),
        whatsapp: new FormControl('', [
            Validators.pattern('^[0-9]*$'),
            Validators.minLength(11),
            Validators.maxLength(11)
        ]),
        maximo_cartoes: new FormControl(5, Validators.required),
        crm: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email])
    });

    // @Output() salvar = new EventEmitter(); 

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewChecked() {
    }

    get primeiro_nome() { return this.form.get('primeiro_nome'); }
    get sobrenome() { return this.form.get('sobrenome'); }
    get idade() { return this.form.get('idade'); }
    get endereco() { return this.form.get('endereco'); }
    get cidade() { return this.form.get('cidade'); }
    get estado() { return this.form.get('estado'); }
    get telefone() { return this.form.get('telefone'); }
    get whatsapp() { return this.form.get('whatsapp'); }
    get name_instagram() { return this.form.get('name_instagram'); }
    get maximo_cartoes() { return this.form.get('maximo_cartoes'); }
    get crm() { return this.form.get('crm'); }
    get email() { return this.form.get('email'); }








}
