import { Component, OnInit } from '@angular/core';
import { MedicoService } from '../../medico.service';
import { NavigationExtras, Router } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { CartaoContasService } from 'app/shared/components/cartao-gestante/cartao-contas.service';
import { ModalsService } from 'app/shared/modals.service';

@Component({
    selector: 'app-medico-secretarias',
    templateUrl: './medico-secretarias.component.html',
    styleUrls: ['./medico-secretarias.component.scss']
})
export class MedicoSecretariasComponent implements OnInit {

  loading: boolean = true; 
  secretarias: any = null;

  filtro_pesquisa: string = ''; 
  filtro_exibir: string = 'A'; 

  constructor(
    private medicoService: MedicoService, 
    private router: Router,
    private alerts: AlertsService,
    private modalService: ModalsService, 
    private contasService: CartaoContasService, 
    ) { }


  ngOnInit() {
    //this.overlayService.show(); 
    this.AtualizarInformacoes(); 
  }

  AtualizarInformacoes() {

    this.medicoService.GetListaSecretarias().subscribe((res) => {
      
      this.secretarias = res; 
      this.loading = false; 
      
    }, err => {

      this.loading = false; 
      
    });
    
  }

  BloquearSecretaria(item) {
      
    this.modalService.AbrirModalDeConfirmacao('Bloquear o usuário?', 'Essa ação impedirá o login do médico e de qualquer alteração em relação aos seus pacientes, deseja continuar?')
    .subscribe((res) => {
      if(res) {
        this.medicoService.BloquearSecretaria(item).then((res) => {
          this.AtualizarInformacoes(); 
          this.alerts.SnackMessage('Médico Bloqueado com sucesso!'); 
        })
      }
    })
  }

  DesbloquearSecretaria(item) {
    
    this.modalService.AbrirModalDeConfirmacao('Desbloquear o usuário?', 'Essa ação permitirá que o usuário acesse o sistema novamente, deseja continuar?')
    .subscribe((res) => {
      if(res) {
        this.medicoService.DesbloquearSecretaria(item).then((res) => {
          this.AtualizarInformacoes(); 
          this.alerts.SnackMessage('Médico Desbloqueado com sucesso!'); 
        })
      }
    })
  }

  EditarSecretaria(item) {
    
    var navigationExtras: NavigationExtras = {
      state: {
        secretaria: item
      }
    }; 

    this.router.navigate(['/medico-alterar-secretarias'], navigationExtras);
  }

  ExcluirSecretaria(item) {
    
    this.modalService.AbrirModalDeConfirmacao('Excluir o usuário?', 'Essa ação apagará o registro da secretária e o impedirá de realizar novos logins, os pacientes deles não serão apagados, deseja continuar? ')
    .subscribe((res) => {
      if(res) {
        this.medicoService.DeletarSecretaria(item).then((res) => {
          this.alerts.SnackMessage('Usuário excluído com sucesso!'); 
          this.AtualizarInformacoes(); 
        })
      } else {
        this.alerts.SnackMessage('Falha ao excluir o usuário'); 
      }
    })
  }
}