import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CartaoContasService } from '../cartao-contas.service';

@Component({
    selector: 'app-sobre-a-gestacao',
    templateUrl: './sobre-a-gestacao.component.html',
    styleUrls: ['./sobre-a-gestacao.component.scss']
})
export class SobreAGestacaoComponent implements OnInit {

    private _previsao_dum: Date;
    private _previsao_parto_ultrassom: Date;

    @Input('showOpen') showOpen;

    form: FormGroup = new FormGroup({
        dum: new FormControl(),
        us1: new FormControl(),
        us_semanas: new FormControl(),
        us_dias: new FormControl(),
        tsabo: new FormControl(),
        rh: new FormControl(),
        peso_inicial: new FormControl(),
        estatura: new FormControl(),
        /* exame_clinico: new FormControl(),
        exame_mamas: new FormControl(),
        exame_ginecologico: new FormControl(),
        alteracoes: new FormControl() */
    });

    constructor(private contasCartaoService: CartaoContasService) { }

    ngOnInit() {
        this.fControls.dum.valueChanges.subscribe((valor) => {
            this._previsao_dum = this.contasCartaoService.PrevisaoDum(valor);
        });
    }

    get fControls() { return this.form.controls; }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    set previsaoDum(value) { }
    get previsaoDum() {
        const aux: any = this._previsao_dum;
        if (this.isValidDate(aux)) {
            return this._previsao_dum ? `${this._previsao_dum.getDate()}/${this._previsao_dum.getMonth() + 1}/${this._previsao_dum.getFullYear()}` : '';
        }
        else { return ''; }
    }

    set previsaoUltrassom(value) { }
    get previsaoUltrassom() {
        const data_us = this.fControls.us1.value;
        const semanas = this.fControls.us_semanas.value;
        const dias = this.fControls.us_dias.value;
        const aux = this.contasCartaoService.PrevisaoUltrassom(data_us, semanas, dias);
        return aux ? `${aux.getDate()}/${aux.getMonth() + 1}/${aux.getFullYear()}` : '';
    }

    set imc(value) { }
    get imc() {
        const altura = this.form.controls.estatura.value;
        const peso = this.form.controls.peso_inicial.value;
        if (!altura || !peso) { return 0; }
        return (this.contasCartaoService.Imc(altura, peso)).toLocaleString('pt-BR', { maximumFractionDigits: 2 }, );
    }

    get IgDum() {
        if (this.form.controls.dum.value == '') {
            return '';
        }

        return this.contasCartaoService.SemanasGestacaoDum(this.form.controls.dum.value);
    }
}
