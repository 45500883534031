import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-page-container',
    templateUrl: './page-container.component.html',
    styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {

    @Input('titulo') titulo_pagina = 'titulo';

    @Input('tipo_usuario') usuario = 'admin';

    constructor() { }

    ngOnInit() {
    }

}
