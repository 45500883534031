import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-modal-adicionar-exame',
    templateUrl: './modal-adicionar-exame.component.html',
    styleUrls: ['./modal-adicionar-exame.component.scss']
})
export class ModalAdicionarExameComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalAdicionarExameComponent>,
    ) { }

    ngOnInit() {
    }

    AdicionarExame(exame: string) {
        this.dialogRef.close(exame);
    }

}
