import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-altura-uterina',
  templateUrl: './altura-uterina.component.html',
  styleUrls: ['./altura-uterina.component.scss']
})
export class AlturaUterinaComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('barChart') chart: BaseChartDirective;
  @Input('dados') dados; 

  ready = false; 

  public showGraph = false; 

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public initialData = { '13': {}, '14': {}, '15': {}, '16': {}, '17': {}, '18': {}, '19': {}, '20': {}, '21': {}, '22': {}, '23': {}, '24': {}, '25': {}, '26': {}, '27': {}, '28': {}, '29': {}, '30': {}, '31': {}, '32': {}, '33': {}, '34': {}, '35': {}, '36': {}, '37': {}, '38': {}, '39': {}};
  public barChartLabels: any;
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData;

  constructor() {
  }

  ngOnInit() {

    if (this.dados.length) this.updateCharData();
    // this.convertLista();

  }

  ngOnChanges(changes: SimpleChanges) {

    this.initChar();
    
    if (this.dados.length) this.updateCharData();

  }

  ngOnDestroy() {

    if (this.chart) this.chart.ngOnDestroy();

  }

  initChar() {

    if (this.chart) this.chart.ngOnDestroy();

    this.showGraph = false; 
    this.initialData = { '13': {}, '14': {}, '15': {}, '16': {}, '17': {}, '18': {}, '19': {}, '20': {}, '21': {}, '22': {}, '23': {}, '24': {}, '25': {}, '26': {}, '27': {}, '28': {}, '29': {}, '30': {}, '31': {}, '32': {}, '33': {}, '34': {}, '35': {}, '36': {}, '37': {}, '38': {}, '39': {}};
    this.barChartLabels = [];
    this.barChartType = 'line';
    this.barChartLegend = true;
    this.barChartData;

  }

  updateCharData() {

    this.barChartLabels = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];

    this.barChartData = [{ 
        data: [], 
        label: 'Altura uterina', 
        fill: false 
      }, {
        data: [{
          x: 13,
          y: 13
        }, { 
          x: 14,
          y: 14.47
        }, {
          x: 15,
          y: 15.53
        }, {
          x: 16,
          y: 16.73
        }, {
          x: 17,
          y: 17.75
        }, {
          x: 18,
          y: 18.73
        }, {
          x: 19,
          y: 19.67
        }, {
          x: 20,
          y: 20.73
        }, {
          x: 21,
          y: 21.59
        }, { 
          x: 22,
          y: 22.47
        }, { 
          x: 23,
          y: 23.40
        }, { 
          x: 24,
          y: 24.20
        }, { 
          x: 25,
          y: 25
        }, {
          x: 26,
          y: 25.85
        }, {
          x: 27,
          y: 26.60
        }, {
          x: 28,
          y: 27.40
        }, {
          x: 29,
          y: 28.20
        }, {
          x: 30,
          y: 28.87
        }, {
          x: 31,
          y: 29.53
        }, {
          x: 32,
          y: 30.33
        }, {
          x: 33,
          y: 30.89
        }, {
          x: 34,
          y: 31.53
        }, {
          x: 35,
          y: 32.28
        }, {
          x: 36,
          y: 32.84
        }, {
          x: 37,
          y: 33.48
        }, {
          x: 38,
          y: 34.07
        }, {
          x: 39,
          y: 34.60
        }, {
          x: 40,
          y: 35.19
        }], 
        label: 'P 90'
      }, {
        data: [{
          x: 13,
          y: 8.07
        }, {
          x: 14,
          y: 8.87 
        }, {
          x: 15,
          y: 10.07
        }, {
          x: 16,
          y: 11.13
        }, {
          x: 17,
          y: 12.15
        }, {
          x: 18,
          y: 13.24
        }, {
          x: 19,
          y: 14.20
        }, {
          x: 20,
          y: 15.13
        }, {
          x: 21,
          y: 15.96
        }, {
          x: 22,
          y: 17
        }, {
          x: 23,
          y: 17.64
        },  {
          x: 24,
          y: 18.60
        }, {
          x: 25,
          y: 19.27
        }, {
          x: 26,
          y: 19.93
        }, {
          x: 27,
          y: 20.73
        }, {
          x: 28,
          y: 21.53
        }, {
          x: 29,
          y: 22.28
        }, {
          x: 30,
          y: 22.87
        }, {
          x: 31,
          y: 23.67
        }, {
          x: 32,
          y: 24.47
        }, {
          x: 33,
          y: 25.27
        }, {
          x: 34,
          y: 26.07
        }, {
          x: 35,
          y: 26.87
        }, {
          x: 36,
          y: 27.67
        }, {
          x: 37,
          y: 28.47
        }, {
          x: 38,
          y: 29.27
        }, {
          x: 39,
          y: 30.07
        }, {
          x: 40,
          y: 31.00
        }],
        label: 'P 10'
    }];
    
    this.dados = this.dados.filter(element => element.auca !== null && element.igdum !== null && element.igdum <= 287); // Filtra os dados para remover os nulos

    if (!this.dados.length) return;

    this.dados.sort((f, s) => { // Deixa os dados na ordem certa
      if (f.igdum < s.igdum) { return -1; } 
      else { return 1; }
    });

    let dadosPrepared = {};

    const primeiroElemento = Math.floor(this.dados[0].igdum / 7);
    const ultimoElemento = Math.floor(this.dados[this.dados.length - 1].igdum / 7);

    this.dados.forEach(item => {// Deixa os dados no formato X Y R para o grafico
      if (item.igdum >= 91) {
        dadosPrepared[`${Math.floor(item.igdum / 7)}`] = { 
          x: Math.floor(item.igdum / 7), 
          y: item.auca, 
          r: 0 
        };
      }
    });

    this.barChartLabels = this.barChartLabels.filter(element => { // Removendo lados vazios no grafico
      return element >= primeiroElemento && element <= ultimoElemento;
    });

    this.barChartData = this.barChartData.map(element => {// Removendo lados vazios no grafico
      const data = element.data.filter(dataElement => {
        return dataElement.x >= primeiroElemento && dataElement.x <= ultimoElemento;
      });

      element.data = data;

      return element;
    });

    dadosPrepared = { ...this.initialData, ...dadosPrepared }; // Mergea os dados do paciente com os campos necessarios do grafico para evitar zeros no grafico em campos vazios

    Object.keys(dadosPrepared).forEach((element, index, array) => {
      if (dadosPrepared[`${element}`].y){
        this.barChartData[0].data.push(dadosPrepared[`${element}`]); // Migranto o grafico com os dados do paciente
      }
    });

    this.showGraph = true; 

  }


  // Função para converter mm em cm
  convertCmInMm(mm) {
    mm = mm + 18.75;

    let cm = mm/3.75;

    return cm.toFixed(2);
  }
  
  convertLista() {
    console.log('lista start');

    let dataP90MM = [{
      semana: 13,
      value: 30
    }, {
      semana: 14,
      value: 35.5
    }, {
      semana: 15,
      value: 39.5
    }, {
      semana: 16,
      value: 44
    }, {
      semana: 17,
      value: 47.8
    }, {
      semana: 18,
      value: 51.5
    }, {
      semana: 19,
      value: 55
    }, {
      semana: 20,
      value: 59
    }, {
      semana: 21,
      value: 62.2
    }, {
      semana: 22,
      value: 65.5
    }, {
      semana: 23,
      value: 69
    }, {
      semana: 24,
      value: 72
    }, {
      semana: 25,
      value: 75
    }, {
      semana: 26,
      value: 78.2
    }, {
      semana: 27,
      value: 81
    }, {
      semana: 28,
      value: 84
    }, {
      semana: 29,
      value: 87
    }, {
      semana: 30,
      value: 89.5
    }, {
      semana: 31,
      value: 92
    }, {
      semana: 32,
      value: 95
    }, {
      semana: 33,
      value: 97.1
    }, {
      semana: 34,
      value: 99.5
    }, {
      semana: 35,
      value: 102.3
    }, {
      semana: 36,
      value: 104.4
    }, {
      semana: 37,
      value: 106.8
    }, {
      semana: 38,
      value: 109
    }, {
      semana: 39,
      value: 111
    }, {
      semana: 40,
      value: 113.2
    }];

    let dataP90CM = dataP90MM.map(item => this.convertCmInMm(item.value));

    let dataP10MM = [{
      semana: 13,
      value: 11.5
    }, {
      semana: 14,
      value: 14.5
    }, {
      semana: 15,
      value: 19
    }, {
      semana: 16,
      value: 23
    }, {
      semana: 17,
      value: 26.8
    }, {
      semana: 18,
      value: 30.9
    }, {
      semana: 19,
      value: 34.5
    }, {
      semana: 20,
      value: 38
    }, {
      semana: 21,
      value: 41.1
    }, {
      semana: 22,
      value: 45
    }, {
      semana: 23,
      value: 47.4
    }, {
      semana: 24,
      value: 51
    }, {
      semana: 25,
      value: 53.5
    }, {
      semana: 26,
      value: 56
    }, {
      semana: 27,
      value: 59
    }, {
      semana: 28,
      value: 62
    }, {
      semana: 29,
      value: 64.8
    }, {
      semana: 30,
      value: 67
    }, {
      semana: 31,
      value: 70
    }, {
      semana: 32,
      value: 73
    }, {
      semana: 33,
      value: 76
    }, {
      semana: 34,
      value: 79
    }, {
      semana: 35,
      value: 82
    }, {
      semana: 36,
      value: 85
    }, {
      semana: 37,
      value: 88
    }, {
      semana: 38,
      value: 91
    }, {
      semana: 39,
      value: 94
    }, {
      semana: 40,
      value: 97.5
    }]

    let dataP10CM = dataP10MM.map(item => this.convertCmInMm(item.value));
    
    // console.log(dataP90CM)
    // console.log(dataP10CM)
  }
}
