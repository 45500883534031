import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { dataExames, Exame } from 'app/core/interfaces/pacient-info.interface';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'app-modal-edicao-exames',
    templateUrl: './modal-edicao-exames.component.html',
    styleUrls: ['./modal-edicao-exames.component.scss']
})

export class ModalEdicaoExamesComponent implements OnInit {

    form: FormGroup = new FormGroup({
        resultado: new FormControl('')
    });

    listaExame: Array<Exame> = dataExames;
    tipo_usuario: string;
    regrasIxibicao: Exame = {
        name: ' ',
        res: {
            positivo: true,
            negativo: true,
            livre: true
        }
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalEdicaoExamesComponent>,
        private _authService: AuthService
    ) {
    }

    ngOnInit() {

        this.tipo_usuario = this._authService.tipo_usuario;

        this.listaExame.forEach((el) => {
            el.name == this.data.nameExame ? this.regrasIxibicao = el : null;
        });

    }

    Salvar() {

        const resultado = this.form.value.resultado;

        if (resultado != '') {
            this.dialogRef.close(resultado);
        }

    }

    ResultadoRapido(resultado) {

        this.dialogRef.close(resultado);

    }

}
