import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickermaskComponent),
  multi: true
};

const noop = () => { };

@Component({
  selector: 'app-datepickermask',
  templateUrl: './datepickermask.component.html',
  styleUrls: ['./datepickermask.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  styles: [`
    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  `]
})

export class DatepickermaskComponent implements ControlValueAccessor {


  @Input() label: String;
  @Input() layout: String;
  isDisabled = false;

  public mask = {
    guide: true,
    showMask: true,
    // keepCharPositions : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  innerValue: Date = new Date();

  @ViewChild('inputDate') inputDate;
  @ViewChild('picker') picker;

  constructor(private renderer: Renderer2) { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): Date {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: Date) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  setDisabledState(disabledVal: boolean) {
    this.isDisabled = disabledVal;
    // this.renderer.setProperty( this.inputDate.nativeElement, 'readonly', disabledVal);
    if (disabledVal) {
      this.renderer.addClass(this.inputDate.nativeElement, 'disabled');
    } else {
      this.renderer.removeClass(this.inputDate.nativeElement, 'disabled');
    }
  }

  // Occured value changed from module
  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onDatePickerChange(event) {
    
    this.value = event.value;
    this.onBlur();
    
  }

  onChange(event) {
    
    this.value = event;
    this.onBlur();

  }

  todate(value) {

    if (this.isDisabled) { return; }

    const arr = value.split('/'); // Invertendo o formato para o padrão Americano, aceito pelo metodo: dd/MM/yyyy -> MM/dd/yyyy

    const testValue = String(value).replace(/\//gi, '').replace(/\_/gi, '').trim();

    if (testValue) {
      this.value = new Date(arr[1] + '/' + arr[0] + '/' + arr[2]);
    } else {
      this.value = null;
    }

  }

  onBlur() {
    this.onChangeCallback(this.innerValue);
  }
}
