import { Usuario } from "./usuario.interface";

export class Paciente implements Usuario {
  id?: string;
  primeiro_nome: string;
  sobrenome: string;
  idade: string | Date;
  endereco: string;
  cidade: string;
  estado: string;
  telefone: string;
  whatsapp?: string;
  name_instagram?: string;

  email: string;
  tipo_usuario: string;
  url?: string;
  url_paciente?: string;
  plano_de_saude: string;
  qual_plano: string;
  medico: string;
  telefone_medico: string;
  crm: string;
  medico_app: string;
  cartao_gestante?: CartaoGestante[];
  ultimo_cartao?: CartaoGestante;

  static create(paciente: {
    url: string,
    url_paciente?: string,
    primeiro_nome: string,
    sobrenome: string,
    email: string,
    idade: string,
    endereco: string,
    cidade: string,
    estado: string,
    telefone: string,
    whatsapp?: string;
    name_instagram?: string;
    plano_de_saude: string,
    qual_plano: string,
    medico: string,
    telefone_medico: string,
    crm: string,
    medico_app: string,
    tipo_usuario: string,
    cartao_gestante?: CartaoGestante[]
  }) {
    return {
      url: paciente.url,
      url_paciente: paciente.url_paciente,
      primeiro_nome: paciente.primeiro_nome,
      sobrenome: paciente.sobrenome,
      email: paciente.email,
      idade: paciente.idade,
      endereco: paciente.endereco,
      cidade: paciente.cidade,
      estado: paciente.estado,
      telefone: paciente.telefone,
      whatsapp: paciente.whatsapp,
      name_instagram: paciente.name_instagram,
      plano_de_saude: paciente.plano_de_saude,
      qual_plano: paciente.qual_plano,
      medico: paciente.medico,
      crm: paciente.crm,
      telefone_medico: paciente.telefone_medico,
      medico_app: paciente.medico_app,
      tipo_usuario: 'paciente',
      cartao_gestante: []
    };
  }

  static createEmpty() {
    return {
      url: '',
      primeiro_nome: '',
      sobrenome: '',
      email: '',
      idade: '',
      endereco: '',
      cidade: '',
      estado: '',
      telefone: '',
      plano_de_saude: '',
      qual_plano: '',
      medico: '',
      crm: '',
      telefone_medico: '',
      medico_app: '',
      tipo_usuario: 'paciente',
      cartao_gestante: []
    };
  }
}

export class CartaoGestante {
  id?: string;
  used?: boolean;
  paciente_id?: string;
  medico_id?: string;
  ativado: boolean;
  encerrado: boolean;
  criado_em: any;
  data_concepcao: Date | string;
  consultas: ConsultaPaciente[];
  historico_clinico: HistoricoClinico;
  historico_familiar: HistoricoFamiliar;
  sobre_a_gestacao: SobreAGestacao;
  vacinas: Vacinas;
  exames: Exames;
  antecedentes_obstetricos: AntecedentesObstetricos;
  previsao_parto_dum: string | Date;
  previsao_parto_ultrassom: string | Date;
  fim_data: string | Date;
  fim_hora: string;
  fim_parto: string;
  fim_info: string;

  static create() {
    return {
      ativado: false,
      criado_em: new Date(),
      data_concepcao: '',
      consultas: [],
      historico_clinico: HistoricoClinico.create(),
      historico_familiar: HistoricoFamiliar.create(),
      sobre_a_gestacao: SobreAGestacao.create(),
      vacinas: Vacinas.create(),
      exames: Exames.create(),
      antecedentes_obstetricos: AntecedentesObstetricos.create(),
      previsao_parto_dum: '',
      previsao_parto_ultrassom: '',
      encerrado: false,
      fim_data: '',
      fim_hora: '',
      fim_parto: '',
      fim_info: ''
    };
  }

}

export class ConsultaPaciente {
  id?: string;
  nome_paciente?: string;
  data: Date;
  hora?: string;
  medico_id: string;
  paciente_id: string;
  cartao_paciente_id: string;
  ig_dum: string;
  ig_usg: string;
  peso: string;
  pa: string;
  au: string;
  bcf: string;
  mf: string;
  edema: string;
  anotacoes_gerais: string;
  static create(consulta_form: {
    data: Date,
    hora: string,
    ig_dum: string,
    ig_usg: string,
    peso: string,
    pa: string,
    au: string,
    bcf: string,
    mf: string,
    edema: string,
    anotacoes_gerais: string,
  }, medico_email: string, paciente_email: string, cartao_id: string) {
    var nova_data: any = `${consulta_form.data.getFullYear()}-${consulta_form.data.getMonth() + 1}-${consulta_form.data.getDate()} `;
    if (consulta_form.hora && consulta_form.hora != null) {
      nova_data += consulta_form.hora;
      nova_data = new Date(nova_data);
    } else {
      nova_data = consulta_form.data;
    }
    return {
      data: nova_data,
      medico_id: medico_email,
      paciente_id: paciente_email,
      cartao_paciente_id: cartao_id,
      ig_dum: consulta_form.ig_dum,
      ig_usg: consulta_form.ig_usg,
      peso: consulta_form.peso,
      pa: consulta_form.pa,
      au: consulta_form.au,
      bcf: consulta_form.bcf,
      mf: consulta_form.mf,
      edema: consulta_form.edema,
      anotacoes_gerais: consulta_form.anotacoes_gerais
    };
  }

  static createEmpty() {
    return {
      data: new Date(),
      medico_id: '',
      paciente_id: '',
      cartao_paciente_id: '',
      ig_dum: '',
      ig_usg: '',
      peso: '',
      pa: '',
      au: '',
      bcf: '',
      mf: '',
      edema: '',
      anotacoes_gerais: ''
    };
  }
}

export class HistoricoClinico {
  alcoolismo: string;
  alergia: string;
  anemia: string;
  aque: string;
  cardiopatia: string;
  diabetes: string;
  hipertensao: string;
  outros: string;
  tabagismo: string;

  static create() {
    return {
      alcoolismo: '',
      alergia: '',
      anemia: '',
      aque: '',
      cardiopatia: '',
      diabetes: '',
      hipertensao: '',
      outros: '',
      tabagismo: ''
    };
  }
}

export class HistoricoFamiliar {
  diabetes: string;
  eplepsia: string;
  gemelaridade: string;
  hipertensao: string;
  malformacao: string;
  outros: string;

  static create() {
    return {
      diabetes: '',
      eplepsia: '',
      gemelaridade: '',
      hipertensao: '',
      malformacao: '',
      outros: '',
    };
  }
}

export class SobreAGestacao {
  alteracoes: string;
  dum: string | Date;
  us1: string | Date;
  us_semanas: string;
  us_dias: string;
  tsabo: string;
  rh: string;
  peso_inicial: string;
  estatura: string;
  exame_clinico: string;
  exame_mamas: string;
  exame_ginecologico: string;

  static create() {
    return {
      alteracoes: '',
      dum: '',
      us1: '',
      us_semanas: '',
      us_dias: '',
      tsabo: '',
      rh: '',
      peso_inicial: '',
      estatura: '',
      exame_clinico: '',
      exame_mamas: '',
      exame_ginecologico: '',
    };
  }
}

export class Vacinas {
  colpo_data: string | Date;
  colpo_observacoes: string;
  colpo_resultado: string;
  dt_dose1: string | Date;
  dt_dose2: string | Date;
  dt_dose3: string | Date;
  dt_previa: string;
  hep_dose1: string | Date;
  hep_dose2: string | Date;
  hep_dose3: string | Date;
  hep_previa: string;
  tri_dose1: string | Date;
  tri_dose2: string | Date;
  tri_dose3: string | Date;
  tri_previa: string;

  static create() {
    return {
      colpo_data: '',
      colpo_observacoes: '',
      colpo_resultado: '',
      dt_dose1: '',
      dt_dose2: '',
      dt_dose3: '',
      dt_previa: '',
      hep_dose1: '',
      hep_dose2: '',
      hep_dose3: '',
      hep_previa: '',
      tri_dose1: '',
      tri_dose2: '',
      tri_dose3: '',
      tri_previa: '',
    };
  }
}

export class AntecedentesObstetricos {
  complicacoes: string;
  g: number;
  p: number;
  a: number;

  static create() {
    return {
      complicacoes: '',
      g: null,
      p: null,
      a: null
    };
  }
}

export class Exames {
  clinicos: any[];
  laboratorio: Array<ExameLaboratorio>;
  ultrasom: any[];
  outros: any[];

  static create() {
    return {
      clinicos: [],
      laboratorio: [],
      ultrasom: [],
      outros: []
    }
  }
}

export class ExameLaboratorio {
  data_limite_tri1: string;
  data_limite_tri2: string;
  data_limite_tri3: string;
  datas: {
    data1: string | Date;
    data2: string | Date;
    data3: string | Date;
    res1: string;
    res2: string;
    res3: string;
  }
  name: string;
}

export class Exame {
  name: string;
  res: {
    negativo?: boolean;
    positivo?: boolean;
    livre?: boolean
  };
}

export const dataExames: Array<Exame> = [{
  name: 'Outros',
  res: { livre: true }
}, {
  name: 'Plaquetas',
  res: { livre: true }
}, {
  name: 'HT/HB',
  res: { livre: true }
}, {
  name: 'TSH',
  res: { livre: true }
}, {
  name: 'Glicose em Jejum',
  res: { livre: true }
}, {
  name: 'TTOG (GTT)',
  res: { livre: true }
}, {
  name: 'Urina I',
  res: { livre: true }
}, {
  name: 'CMV IgG',
  res: { negativo: true, positivo: true }
}, {
  name: 'CMV IgM',
  res: { negativo: true, positivo: true }
}, {
  name: 'HIV',
  res: { negativo: true, positivo: true }
}, {
  name: 'VDRL',
  res: { negativo: true, positivo: true }
}, {
  name: 'HBsAG',
  res: { negativo: true, positivo: true }
}, {
  name: 'COOMBs Ind',
  res: { negativo: true, positivo: true }
}, {
  name: 'Toxo IgM',
  res: { negativo: true, positivo: true }
}, {
  name: 'Toxo IgG',
  res: { negativo: true, positivo: true }
}, {
  name: 'Rubéola IgM',
  res: { negativo: true, positivo: true }
}, {
  name: 'Rubéola IgG',
  res: { negativo: true, positivo: true }
}, {
  name: 'Cultura Streptococcus',
  res: { negativo: true, positivo: true }
}, {
  name: 'Hepatite C',
  res: { negativo: true, positivo: true }
}, {
  name: 'Urocultura',
  res: { negativo: true, positivo: true, livre: true }
}, {
  name: 'Protoparasitológico',
  res: { negativo: true, positivo: true, livre: true }
}, {
  name: 'ABO/Rh',
  res: { negativo: true, positivo: true, livre: true }
}, {
  name: 'Hepatite B',
  res: { negativo: true, positivo: true, livre: true }
}]
