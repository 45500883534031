import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor(private snack: MatSnackBar) { }

    SnackMessage(mensagem: string, action?: string, xcss?: string) {
        const opts: MatSnackBarConfig = {
            duration: 4000
        };
        if (xcss) { opts.panelClass = xcss; }

        this.snack.open(mensagem, action, opts);
    }
}
