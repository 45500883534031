import { ArrayType } from '@angular/compiler';
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Events } from 'app/core/events';

@Component({
  selector: 'app-exames',
  templateUrl: './exames.component.html',
  styleUrls: ['./exames.component.scss', './../tabelas.scss']
})
export class ExamesComponent implements OnInit {

  displayedColumns: string[] = ['exame', 'res1', 'res2', 'res3'];
  dataSource = [];

  data1_tri: any = null;
  data2_tri: any = null;
  data3_tri: any = null;
  boolCarregarExames: boolean;

  @Input() exames_lab;
  @Input() examesLabArr;

  @Input() edit_mode = true;

  @Output() alterar_celula = new EventEmitter();

  public tabcount: number[];

  constructor(public events: Events) { }

  ngOnInit() {

    this.tabcount = [0, 0, 0];

    this.boolCarregarExames = false;

    if (this.edit_mode) {

      // Caso o cartao nao possua algum dos exames da array, habilitar o botao de carregar exames
      this.CheckExames();

    }

    // Incluir aqui exames que foram alterados e não podem mais serem exibidos
    // ** Tela de Paciente */
    const exameBlackList = ['Protoparasitologia'];
    this.exames_lab = this.exames_lab.filter((item) => {
      
      if (exameBlackList.indexOf(item.name) > -1) { return false; }
      return true;

    });

    this.dataSource = this.exames_lab;
    this.ArrumarDatas(this.exames_lab);

    this.events.unsubscribe('ChangeDataTrimestre'); // avoid loop
    this.events.subscribe('ChangeDataTrimestre', () => {
      
      this.data1_tri = null;
      this.data2_tri = null;
      this.data3_tri = null;
      this.ArrumarDatas(this.exames_lab);

    });

  }

  CheckExames() {

    if (!Array.isArray(this.examesLabArr) || !this.examesLabArr.length) { return; }

    this.examesLabArr.map(exame => {
      let achou = false;
      this.exames_lab.map(obj => {
        if (obj.name == exame) { achou = true; }
      });
      if (!achou) { this.boolCarregarExames = true; }
    });

  }

  ArrumarDatas(exames) {

    exames.forEach(element => {

      if (element.datas.data1 != '' && this.data1_tri == null) {
        this.data1_tri = element.datas.data1;
      }

      if (element.datas.data2 != '' && this.data2_tri == null) {
        this.data2_tri = element.datas.data2;
      }

      if (element.datas.data3 != '' && this.data3_tri == null) {
        this.data3_tri = element.datas.data3;
      }

    });

  }

  CarregarExames() {

    this.events.publish('CarregarExames');

  }

  TrocarData(trimestre) {

    this.alterar_celula.emit({ exame: null, trimestre: trimestre, anotherField: 'data' });

  }

  SalvarMudancas() {
    
  }

  AbrirModoEdicao(element, trimestre, anotherField?) {
    
    if (element.name == 'ABO/Rh') {

      this.alterar_celula.emit({ exame: element, trimestre: trimestre, allTrimestre: true, anotherField: anotherField });

    } else {

      this.alterar_celula.emit({ exame: element, trimestre: trimestre, anotherField: anotherField });
      
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.dataSource = this.exames_lab;

    this.CheckExames();

  }

}
