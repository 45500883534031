import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';

@Component({
    selector: 'app-dados-consulta',
    templateUrl: './dados-consulta.component.html',
    styleUrls: ['./dados-consulta.component.scss']
})
export class DadosConsultaComponent implements OnInit {

    paciente: any;

    hide_day_form = true;
    dataConsulta: FormGroup = new FormGroup({
        data: new FormControl(new Date()),
        hora: new FormControl('')
    });

    dadosConsulta: FormGroup = new FormGroup({
        ig_dum: new FormControl(''),
        ig_usg: new FormControl(''),
        peso: new FormControl(''),
        pa: new FormControl(''),
        au: new FormControl(''),
        bcf: new FormControl(''),
        mf: new FormControl(''),
        edema: new FormControl(''),
        anotacoes_gerais: new FormControl(''),
    });

    // Entrada e saida de variaveis e eventos

    @Input('nome') nome_paciente = '';
    @Input('acesso') tipo_de_acesso = 'admin';

    @Output() salvar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    get data_form() {
        return this.dataConsulta.controls.data.value;
    }

    Salvar() {
        this.salvar.emit({ ...this.dataConsulta.value, ...this.dadosConsulta.value });
    }
}
