import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PacienteRoutingModule } from './paciente-routing.module';
import { PacienteComponent } from './subpages/paciente/paciente.component';
import { SharedModule } from 'app/shared/shared.module';
import { CartaoInfoComponent } from './subpages/cartao-info/cartao-info.component';
import { ConsultasComponent } from './subpages/consultas/consultas.component';
import { CalendarModule } from 'app/shared/components/calendario/calendar.module';
import { MatProgressBarModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { CadernetaComponent } from './subpages/caderneta/caderneta.component';
import { CartaoAnterioresComponent } from './subpages/cartao-anteriores/cartao-anteriores.component';
import { EditarPerfilComponent } from './subpages/editar-perfil/editar-perfil.component';
import { DadosConsultaComponent } from './subpages/dados-consulta/dados-consulta.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'app/core/core.module';

@NgModule({
    declarations: [
        PacienteComponent,
        CartaoInfoComponent,
        ConsultasComponent,
        CadernetaComponent,
        CartaoAnterioresComponent,
        EditarPerfilComponent,
        DadosConsultaComponent
    ],
    imports: [
        CommonModule,
        PacienteRoutingModule,
        SharedModule,
        CoreModule,
        ReactiveFormsModule,

        /** Fuse modules */
        CalendarModule,

        /** Angular Material  */
        MatProgressBarModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule

    ]
})
export class PacienteModule { }
