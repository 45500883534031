import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-agendar-consulta',
  templateUrl: './agendar-consulta.component.html',
  styleUrls: ['./agendar-consulta.component.scss']
})
export class AgendarConsultaComponent implements OnInit {

  form: FormGroup = new FormGroup({
    data: new FormControl(new Date(), Validators.required),
    hora: new FormControl('', Validators.required)
  });

  meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AgendarConsultaComponent>
  ) { }

  ngOnInit() {

  }

  get dia() {
    const value: Date = new Date(this.form.controls.data.value);
    const dia = value.getDate();
    const month = value.getMonth();
    const year = value.getFullYear();
    return `${dia} de ${this.meses[month]} de ${year}`;
  }
  get hora() {
    const value = this.form.controls.hora.value;
    if (value == '') {
      return '----';
    } else { return value; }
  }

  Agendar() {
    if (this.form.valid) {
      this.dialogRef.close(this.DataEHora());
    }
  }

  Cancelar() {
    this.dialogRef.close(false);
  }

  DataEHora() {
    const data: Date = new Date(this.form.controls.data.value);
    const hora = this.form.controls.hora.value;

    const string = `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()} ${hora}`;
    const dataEhoraCerta = new Date(string);
    return dataEhoraCerta;
  }

}
