import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-inserir-usg',
    templateUrl: './inserir-usg.component.html',
    styleUrls: ['./../modals-inserir.scss']
})
export class InserirUsgComponent implements OnInit {

    hide_day_form = true;
    dataConsulta: FormGroup = new FormGroup({
        data: new FormControl(new Date()),
    });

    dadosUSG: FormGroup = new FormGroup({
        ig: new FormControl(``),
        peso_fetal: new FormControl(``),
        placenta: new FormControl(``),
        outros: new FormControl(``),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InserirUsgComponent>
    ) { }

    ngOnInit() {
    }

    get data_form() {
        return this.dataConsulta.controls.data.value;
    }

    Salvar() {
        this.dialogRef.close({ ...this.dataConsulta.value, ...this.dadosUSG.value });
    }

}
