import { Secretaria } from "./secretaria.interface";
import { Usuario } from "./usuario.interface";

export class Medico implements Usuario {
  id?: string;
  primeiro_nome: string;
  sobrenome: string;
  idade: string | Date;
  endereco: string;
  cidade: string;
  estado: string;
  telefone: string;
  whatsapp?: string;
  name_instagram?: string;

  email: string;
  tipo_usuario: string;
  url?: string;
  crm: string;
  ativado: boolean;
  bloqueado: boolean;
  n_pacientes?: number;
  proxima_consulta?: any;
  maximo_cartoes?: number;
  cartoes_usados?: number;
  secretarias?: Array<Secretaria>

  static create(medico: {
    primeiro_nome: string,
    sobrenome: string,
    idade: string,
    endereco: string,
    cidade: string,
    estado: string,
    telefone: string,
    whatsapp?: string;
    name_instagram?: string;
    crm: string,
    email: string,
    ativado: boolean,
    bloqueado: boolean,
    maximo_cartoes: number
  }) {
    return {
      primeiro_nome: medico.primeiro_nome,
      sobrenome: medico.sobrenome,
      idade: medico.idade,
      endereco: medico.endereco,
      cidade: medico.cidade,
      estado: medico.estado,
      telefone: medico.telefone,
      whatsapp: medico.whatsapp,
      name_instagram: medico.name_instagram,
      crm: medico.crm,
      email: medico.email,
      ativado: false,
      bloqueado: false,
      tipo_usuario: 'medico',
      n_pacientes: 0,
      maximo_cartoes: medico.maximo_cartoes
    };
  }
}
