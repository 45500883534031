import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'IdadeGestacional'
})
export class IgPipe implements PipeTransform {

    transform(value: number, args?: any): any {
        const semanas = Math.floor(value / 7);
        const dias = value % 7;
        return `${semanas}s ${dias}d`;
    }

}
