import { CartaoContasService } from 'app/shared/components/cartao-gestante/cartao-contas.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

export interface Task {
  name: string;
  completed: boolean;
  color: any;
  subtasks?: Task[];
}

@Component({
  selector: 'app-inserir-lab',
  templateUrl: './inserir-lab.component.html',
  styleUrls: ['./inserir-lab.component.scss']
})
export class InserirLabComponent implements OnInit {

  todos_exames = ['ABO/Rh', 'Hepatite B', 'Hepatite C', 'Plaquetas', 'HT/HB', 'CMV IgG', 'CMV IgM', 'TSH', 'Glicose em Jejum', 'TTOG (GTT)', 'HIV', 'VDRL', 'HBsAG', 'COOMBs Ind', 'Toxo IgM', 'Toxo IgG', 'Rubéola IgM', 'Rubéola IgG', 'Urina I', 'Urocultura', 'Parasitológico', 'Protoparasitológico', 'Cultura Streptococcus', 'Outros'];

  form: FormGroup = new FormGroup({
    trimestre: new FormControl(''),
    data: new FormControl(''),
    exames_selecionados: new FormControl()
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InserirLabComponent>
  ) { }

  ngOnInit() { }

  Salvar() {

    var today = moment();
    var data_limite = today.add(1, 'days');
    var aux = { ...this.form.value, data_limite_edicao: data_limite.toDate() };
    this.dialogRef.close(aux);

  }

}
