import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';

@Injectable()
export class CalendarService // implements Resolve<any>
{
    events: any = [];
    onEventsUpdated: Subject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.onEventsUpdated = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    // {
    //     return new Promise((resolve, reject) => {
    //         Promise.all([
    //             this.getEvents()
    //         ]).then(
    //             ([events]: [any]) => {
    //                 resolve();
    //             },
    //             reject
    //         );
    //     });
    // }

    /**
     * Get events
     *
     * @returns {Promise<any>}
     */
    // getEvents(): Promise<any>
    // {
    //     return new Promise((resolve, reject) => {

    //         this._httpClient.get('api/calendar/events')
    //             .subscribe((response: any) => {
    //                 this.events = response.data;
    //                 this.onEventsUpdated.next(this.events);
    //                 resolve(this.events);
    //             }, reject);
    //     });
    // }

    /**
     * Update events
     *
     * @param events
     * @returns {Promise<any>}
     */
    // updateEvents(events): Promise<any>
    // {
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post('api/calendar/events', {
    //             id  : 'events',
    //             data: [...events]
    //         })
    //             .subscribe((response: any) => {
    //                 this.getEvents();
    //             }, reject);
    //     });
    // }

    setEvents(consultas: ConsultaPaciente[]) {
        const newEvents = consultas.map((item) => {
            const inicio = item.data;
            const final = item.data;

            const fuseEvent = {
                start: inicio,
                end: final,
                title: 'Consulta',
                allDay: false,
                color: {
                    primary: '#F44336',
                    secondary: '#FFCDD2'
                },
                draggable: false,
                resizable: {
                    beforeStart: false,
                    afterEnd: false
                },
                meta: {
                    notes: item
                }
            };
            return fuseEvent;
        });
        
        this.events = newEvents;
        this.onEventsUpdated.next(newEvents);
        
    }
}
