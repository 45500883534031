import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-modal-edicao-trimestre',
    templateUrl: './modal-edicao-trimestre.component.html',
    styleUrls: ['./modal-edicao-trimestre.component.scss']
})
export class ModalEdicaoTrimestreComponent implements OnInit {

    form: FormGroup = new FormGroup({
        dataInput: new FormControl('')
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalEdicaoTrimestreComponent>
    ) { }

    ngOnInit() {
        if (this.data['dataInput']) { this.form.setValue({ dataInput: this.data['dataInput'] }); }
    }

    Salvar() {
        const dataInput = this.form.value.dataInput;
        if (dataInput != '') {
            this.dialogRef.close(dataInput);
        }
    }

    ResultadoRapido(dataInput) {
        this.dialogRef.close(dataInput);
    }

}
