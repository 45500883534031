import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap, concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APIGestaCardService } from '../api/gestacard.service';
import { AlertsService } from 'app/shared/alerts.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  newRequest: HttpRequest<any>;

  constructor(
    private _apigestacard: APIGestaCardService,
    private router: Router,
    private snackService : AlertsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        console.log(error)

        if (error.error && error.error.accessTokenExpired) {
          this._apigestacard.GetNewAccessToken().then((res) => {
            let routerAtual = this.router.url;

            this.snackService.SnackMessage('Token atualizado. Tente novamente.')

            if (routerAtual.includes('auth')) this.router.navigateByUrl('/')
          }).catch((err) => {
            this.Logout();
            return throwError(error.error.error || error.error || error.message);
          })
        } else {
          this.Logout();
          return throwError(error.error.error || error.error || error.message);
        }

      }

      return throwError(error.error.error || error.error || error.message);

    }));

  }

  Logout() {
    localStorage.removeItem('medicoLogado')
    localStorage.removeItem('secretariaHash')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    this.router.navigateByUrl('/auth/login')
  }
}
