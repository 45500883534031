import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { AlterPasswordComponent } from './alter-password.component';

const routes = [
  {
    path     : 'alter-password',
    component: AlterPasswordComponent
  }
];

@NgModule({
    declarations: [
      AlterPasswordComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule, 
        
        FuseSharedModule
    ]
})
export class AlterPasswordModule
{
}
