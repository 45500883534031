import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginModule } from './login/login.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { MaintenanceModule } from './blocked-session/maintenence.module';
import { SignupModule } from './signup/signup.module';
import { AlterPasswordModule } from './alter-password/alter-password.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthenticationRoutingModule, 
    AlterPasswordModule,
    LoginModule, 
    ForgotPasswordModule, 
    ResetPasswordModule, 
    MaintenanceModule,
    SignupModule,
    SignupModule
  ]
})
export class AuthenticationModule { }
