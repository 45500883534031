import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecretariaPacientesComponent } from './subpages/secretaria-pacientes/secretaria-pacientes.component';
import { SecretariaCadastrarPacienteComponent } from './subpages/secretaria-cadastrar-paciente/secretaria-cadastrar-paciente.component';
import { SecretariaEditarPerfilComponent } from './subpages/secretaria-editar-perfil/secretaria-editar-perfil.component';
import { SecretariaAlterarPacienteComponent } from './subpages/secretaria-alterar-paciente/secretaria-alterar-paciente.component';
import { SecretariaGuard } from 'app/core/guards/secretaria.guard';

const routes: Routes = [
  {
    path: 'secretaria', 
    component: SecretariaPacientesComponent, 
    canActivate: [SecretariaGuard]
  },
  {
    path: 'secretaria-cadastrar-pacientes', 
    component: SecretariaCadastrarPacienteComponent, 
    canActivate: [SecretariaGuard]
  },
  {
    path: 'secretaria-alterar-pacientes', 
    component: SecretariaAlterarPacienteComponent, 
    canActivate: [SecretariaGuard]
  },
  {
    path: 'secretaria-editar-perfil', 
    component: SecretariaEditarPerfilComponent,
    canActivate: [SecretariaGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecretariaRoutingModule { }
