import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../../paciente.service';
import { Router, NavigationExtras } from '@angular/router';
import { Paciente, CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { Medico } from 'app/core/interfaces/medico.interface';
import { AuthService } from 'app/core/auth/auth.service';
import { MedicoService } from 'app/pages/medico/medico.service';


@Component({
    selector: 'app-paciente',
    templateUrl: './paciente.component.html',
    styleUrls: ['./paciente.component.scss']
})
export class PacienteComponent implements OnInit {

  loading = true;
  show = false;
  paciente_info: Paciente = null;

  cartao_gestante_ativo: CartaoGestante = null;
  medico_da_gestante: Medico = null;

  constructor(
    private pacienteService: PacienteService,
    private authService: AuthService,
    private medicoService: MedicoService,
    private router: Router
  ) { }

  async ngOnInit() {
    
    try {
      this.paciente_info = await this.pacienteService.GetUserInfo();

      this.authService.LerComoObs().subscribe(async (usuario) => {

        if (usuario && usuario instanceof Paciente && usuario.medico_app) {

          await this.medicoService.GetInfoMedico(usuario.medico_app).then(medico => {

            if (medico) this.medico_da_gestante = medico;

          }).catch(err => {

            console.log('Não foi possivel pegar informações do medico');
        
          });

        }

      });

      this.cartao_gestante_ativo = await this.pacienteService.GetCartaoAtivado();
      this.show = true;
      this.loading = false;

    } catch (error) {

      console.log('PacienteComponent -> ngOnInit -> error', error);

    }

  }

  AbrirCartaoGestante() {

    this.router.navigate(['/paciente-informacao']);

  }

}
