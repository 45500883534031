import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../../paciente.service';
import { ConsultaPaciente, Paciente } from 'app/core/interfaces/pacient-info.interface';
import { Router, NavigationExtras } from '@angular/router';
import { CalendarService } from 'app/shared/components/calendario/calendar.service';

@Component({
    selector: 'app-consultas',
    templateUrl: './consultas.component.html',
    styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent implements OnInit {

    paciente: Paciente;

    proximas_consultas: ConsultaPaciente[] = [];
    consultas_anteriores: ConsultaPaciente[] = [];

    constructor(
        private pacienteService: PacienteService,
        private router: Router,
        private calendarService: CalendarService
    ) { }

    ngOnInit() {
        this.pacienteService.GetUserInfo().then((res) => {
            this.paciente = res;
        });

        this.pacienteService.GetConsultasPaciente().then((res: any) => {
            this.proximas_consultas = res.proximas;
            this.consultas_anteriores = res.anteriores;

            // Todas as consultas 
            const todas_as_consultas = this.proximas_consultas.concat(this.consultas_anteriores);
            this.calendarService.setEvents(todas_as_consultas);

        }).catch((err) => {

        });
    }

    async EventoClicado(evento?) {

        try {
            const dados_paciente = await this.pacienteService.PegarPacientePorId(evento.paciente_id);
            const extras: NavigationExtras = {
                state: {
                    paciente: dados_paciente,
                    consulta: evento
                }
            };
            this.router.navigate(['/paciente-dados-consulta'], extras);
        } catch (error) {

        }
        // this.router.navigate(['/paciente-dados-consulta']); 
    }
}
