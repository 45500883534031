import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { CartaoGestante, ConsultaPaciente, ExameLaboratorio, Exames, Paciente } from '../interfaces/pacient-info.interface'
import { APIInterface } from '../interfaces/api/api.interface'
import { Usuario } from '../interfaces/usuario.interface'
import { ApiService } from './api.service'
import { ListaConsulta } from '../interfaces/listaconsulta.interface'
import { Secretaria } from '../interfaces/secretaria.interface'
import { Medico } from '../interfaces/medico.interface'
import { Pagination } from '../interfaces/pagination.interface'

interface LoginType {
  accessToken: string,
  refreshToken: string,
  token: string,
  expiresIn: number,
  data: {
    _id: string, 
    name: string, 
    email: string, 
    permissao: string
  }
}
interface codeType {
  success: boolean,
  message: string,
  needCode: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class APIGestaCardService implements APIInterface {

  constructor(
    private _api: ApiService
  ) { }

  GetMe(): Promise<Usuario> {

    return new Promise((resolve, reject) => {
      this._api.get('/api/auth/me').subscribe(result => {

        let usuario: Usuario = result.data
        
        if (!usuario) reject('Nenhum usuario encontrado')

        if (usuario.tipo_usuario === 'super') reject('O seu tipo de usuario não pode acessar esse aplicativo')
        
        resolve(usuario)

      }, err => reject(err))

    })

  }

  GetMeObs(): Observable<Usuario> {

    return Observable.create((observer) => {
      
      this._api.get('/api/auth/me').subscribe(result => {

        let usuario: Usuario = result.data
        
        if (!usuario) observer.error('Nenhum usuario encontrado')
        
        observer.next(usuario)

      }, err => observer.error(err))
  
    })
  }

  EmailExists(email: string): Promise<Boolean> {
    
    return new Promise((resolve, reject) => {

      this._api.get('/api/auth/checkEmail', { email }).subscribe(result => {

        let exists: Boolean = result.success

        resolve(exists)

      }, err => reject(err))

    })
  }

  GetNewAccessToken(): Promise<Boolean> {
      
    return new Promise((resolve, reject) => {

      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken) {
        this._api.get('/api/auth/token', { refreshToken: refreshToken }).subscribe(result => {
  
          if (!result.accessToken || !result.refreshToken) reject('Nenhum token encontrado')
  
          localStorage.setItem('accessToken', result.accessToken);
          localStorage.setItem('refreshToken', result.refreshToken);
          localStorage.setItem('expiresIn', (new Date().getTime() + result.expiresIn).toString());
  
          resolve(true)
  
        }, err => reject(err))
      }

    })

  }

  GetNewAccessTokenObs(): Observable<Boolean> {

    return Observable.create((observer) => {

      const refreshToken = localStorage.getItem('refreshToken')

      if (refreshToken) {

        this._api.get('/api/auth/token', { refreshToken: refreshToken }).subscribe(result => {
  
          let data = result.data
  
          if (!data) observer.error('Nenhum token encontrado')
  
          localStorage.setItem('accessToken', data.accessToken)
          localStorage.setItem('refreshToken', data.refreshToken);
          localStorage.setItem('expiresIn', (new Date().getTime() + result.expiresIn).toString());
  
          observer.next(true)
  
        }, err => observer.error(err))
        
      }
    })

  }


  ChecarUsuario(email: string): Promise<Boolean> {

    return new Promise((resolve, reject) => {
        
      this._api.post('/api/auth/checkUser', { email, device: 'app' }).subscribe(result => {

        let exists: Boolean = result.needCode

        resolve(exists)

      }, err => reject({ message: err }))
    })

  }

  EnviarCodigo(email: string): Promise<Boolean> {
      
    return new Promise((resolve, reject) => {

      this._api.post('/api/auth/sendCode', { email }).subscribe(result => {

        let sent: Boolean = result.success

        resolve(sent)

      }, err => reject(err))

    })

  }

  AlterarSenha(senhaAtual: string, senhaNova: string, confirmaSenhaNova: string): Promise<Usuario> {
      
    return new Promise((resolve, reject) => {

      this._api.post('/api/auth/alterPassword', { password: senhaAtual, newPassword: senhaNova, confirmNewPassword: confirmaSenhaNova }).subscribe((result) => {
        let usuario: Usuario = result.data

        if (!usuario) reject('Não foi possivel pegar o usuario')

        resolve(usuario)

      }, err => {
        reject(err.error)
      })

    })
  
  }

  AlterarEmailDeUsuario(email_novo: string, password: string): Promise<Usuario> {

    return new Promise((resolve, reject) => {

      this._api.post('/api/auth/alterEmail', { newEmail: email_novo, password }).subscribe((result) => {

        var usuario: Usuario = result.data

        if (!usuario) resolve(null)

        resolve(usuario)

      }, err => reject(err))

    })

  }

  VerificarCodigo(email: string, code: string): Promise<Boolean> {
    
    return new Promise((resolve, reject) => {

      this._api.post('/api/auth/verifyCode', { email, code }).subscribe(result => {

        let verified: Boolean = result.verified

        resolve(verified)

      }, err => reject(err))

    })

  }

  VerificarCodigoEAlterarSenha(email: string, verificationCode: string, newPassword: string): Promise<Boolean> {

    return new Promise ((resolve, reject) => {

      this._api.post('/api/auth/verifyCodeAndSetPassword ', { email, verificationCode, newPassword }).subscribe(result => {

        let verified: Boolean = result.success

        resolve(verified)

      }, err => reject(err))

    })

  }

  AtualizaSenha(email: string, verificationCode: string, newPassword: string): Promise<Boolean> {

    return new Promise((resolve, reject) => {

      this._api.post('/api/auth/verifyCodeAndSetPassword', { email, verificationCode, newPassword }).subscribe(result => {
        let status = result.success

        resolve(status)

      }, err => reject({ message: err }))

    })

  }

  UploadImage(file: File): Promise<string> {

    return new Promise((resolve, reject) => {

      const formData: FormData = new FormData();
      formData.append('url', file, file.name);

      this._api.post('/api/upload', formData).subscribe(result => {

        if (!result.data) reject('Nenhuma imagem encontrada')

        resolve(result.data.path)

      }, (err) => reject(err))

    })
  }

  signInWithEmailAndPassword(email: string, senha: string): Promise<LoginType|codeType> {

    return new Promise((resolve, reject) => {
      
      this._api.post('/api/auth/login', {email, password: senha}).subscribe(async (result: LoginType|codeType) => {

        if (this.isCodeType(result)) {
          let code: codeType = result

          resolve(code)
        } else {
          let login: LoginType = result

          let dadosUsuario = login.data

          if (!['super', 'admin'].includes(dadosUsuario.permissao)) {
            if (window.innerWidth < 768) {
              localStorage.setItem('accessToken', result.token);
            } else {
              localStorage.setItem('accessToken', result.accessToken);
              localStorage.setItem('refreshToken', result.refreshToken);
              localStorage.setItem('expiresIn', (new Date().getTime() + result.expiresIn).toString());
            }

            resolve(result)
          }
        }

        reject('O seu tipo de usuario não pode acessar esse aplicativo')

      }, err => reject(err))

    })

  }

  signInWithToken(token: string): Promise<Usuario> {
      
      return new Promise((resolve, reject) => {
        console.log(token)
  
        this._api.setAuthorization(token)
  
        this._api.get('/api/auth/me').subscribe(result => {
          
          let usuario: Usuario = result.data
          if (!usuario) reject('Nenhum usuario encontrado')
          if (usuario.tipo_usuario === 'super') reject('O seu tipo de usuario não pode acessar esse aplicativo')

          if (!['super', 'admin'].includes(usuario.tipo_usuario)) {
            resolve(usuario)
          } else {
            this._api.setAuthorization('')
            localStorage.removeItem('accessToken')
            reject('O seu tipo de usuario não pode acessar esse aplicativo')
          }
  
        }, err => reject(err))
  
      })
  }

  sendPasswordResetEmail(email): Promise<Boolean> {
    return new Promise((resolve, reject) => {  
      this._api.post('/api/auth/forgotPassword', { email }).subscribe(() => {
        resolve(true)
      }, err => reject(err))
    })
  }

  isCodeType(result: any): result is codeType {
    return (
      result &&
      typeof result.success !== 'undefined' &&
      typeof result.message === 'string' &&
      typeof result.needCode === 'boolean'
    );
  }

  ConsultarPermissao(): Promise<string> {

    return new Promise((resolve, reject) => {

      this._api.get('/api/auth/permissao').subscribe(result => {

        if (result.data.length) {

          var usuario: Usuario = result.data[0]

          if (usuario.tipo_usuario) resolve(usuario.tipo_usuario)

        } else reject()

      }, (err) => reject(err))

    })

  }

  ConsultarPermissoAsObservable() : Observable<string> {

    return Observable.create(async (observer) => {  

      await this.ConsultarPermissao()
        .then((result) => observer.next(result))
        .catch((err) => observer.error(err))

    })

  }
  
  OrderByDate(consultas: ConsultaPaciente[]) : ConsultaPaciente[] {

    var ordered = consultas.sort((a,b) => a.data.getTime() - b.data.getTime()) 

    return ordered

  }

  IsCardActive(cartao_id: string) : Promise<boolean> {

    return new Promise((resolve) => {

      this._api.get(`/api/cartoes/${cartao_id}`).subscribe((result) => {

        if (!result.data) resolve(null)

        var cartao: CartaoGestante = result.data 
        
        resolve(cartao.ativado) 

      })

    })

  }

  FilterByActiveCard(consultas: ConsultaPaciente[]) : Promise<any> {

    return new Promise((resolve) => {

      var aux = consultas.map((item) => { 
        return this.IsCardActive(item.cartao_paciente_id)
      }) 

      Promise.all(aux).then((res) => {
        var filtered = []

        consultas.forEach((item, index) => {

          if (res[index]) filtered.push(item)

        })

        resolve(filtered) 
      })

    })

  }

  IsPreviousToday(date: Date) : boolean {

    var today = new Date() 

    return date.getTime() < today.getTime() 

  }

  DivideNextAndPrevious(consultas: ConsultaPaciente[]): ListaConsulta {
    
    var obj: ListaConsulta = {
      proximas: [], 
      anteriores: []
    }

    consultas.forEach((item) => {
      if (this.IsPreviousToday(item.data)) obj.anteriores.push(item) 
      else obj.proximas.push(item) 
    })

    return obj 

  }

  ConvertTimeStampToDate(ts: any) : Date | string {
    if (ts == '' || !ts) return ''

    var date = null

    if (typeof ts === 'string') {
      date = new Date(ts)
    } else {
      if (ts.seconds) date = new Date(ts.seconds * 1000)
      if (ts._seconds) date = new Date(ts._seconds * 1000)
    }

    if (!date) return ''

    return date 
  }

  FormatCartaoGestanteToDate(cartao: CartaoGestante) : CartaoGestante {
    var aux = cartao 
    aux.criado_em = aux.criado_em ? this.ConvertTimeStampToDate(aux.criado_em) : '' 

    // Atualizando sobre a gestação
    aux.sobre_a_gestacao.dum = aux.sobre_a_gestacao.dum ? this.ConvertTimeStampToDate(aux.sobre_a_gestacao.dum) : '' 
    aux.sobre_a_gestacao.us1 = aux.sobre_a_gestacao.us1 ? this.ConvertTimeStampToDate(aux.sobre_a_gestacao.us1) : '' 

    // Atualizando as vacins 
    aux.vacinas.tri_dose1 = aux.vacinas.tri_dose1 ? this.ConvertTimeStampToDate(aux.vacinas.tri_dose1) : '' 
    aux.vacinas.tri_dose2 = aux.vacinas.tri_dose2 ? this.ConvertTimeStampToDate(aux.vacinas.tri_dose2) : '' 
    aux.vacinas.tri_dose3 = aux.vacinas.tri_dose3 ? this.ConvertTimeStampToDate(aux.vacinas.tri_dose3) : ''
    
    aux.vacinas.hep_dose1 = aux.vacinas.hep_dose1 ? this.ConvertTimeStampToDate(aux.vacinas.hep_dose1) : '' 
    aux.vacinas.hep_dose2 = aux.vacinas.hep_dose2 ? this.ConvertTimeStampToDate(aux.vacinas.hep_dose2) : '' 
    aux.vacinas.hep_dose3 = aux.vacinas.hep_dose3 ? this.ConvertTimeStampToDate(aux.vacinas.hep_dose3) : '' 

    aux.vacinas.dt_dose1 = aux.vacinas.dt_dose1 ? this.ConvertTimeStampToDate(aux.vacinas.dt_dose1) : '' 
    aux.vacinas.dt_dose2 = aux.vacinas.dt_dose2 ? this.ConvertTimeStampToDate(aux.vacinas.dt_dose2) : '' 
    aux.vacinas.dt_dose3 = aux.vacinas.dt_dose3 ? this.ConvertTimeStampToDate(aux.vacinas.dt_dose3) : '' 

    aux.vacinas.colpo_data = aux.vacinas.colpo_data ? this.ConvertTimeStampToDate(aux.vacinas.colpo_data) : '' 

    aux.fim_data = aux.fim_data ? this.ConvertTimeStampToDate(aux.fim_data) : '' 

    aux.exames.clinicos = aux.exames.clinicos.map((item) => {
      var aux2 = item 
      aux2.data = aux2.data ? this.ConvertTimeStampToDate(aux2.data) : ''
      return aux2
    })

    aux.exames.laboratorio = aux.exames.laboratorio.map((item) => this.FormatExameLaboratorioToDate(item))

    aux.exames.ultrasom = aux.exames.ultrasom.map((item) => {
      var aux2 = item

      aux2.data = this.ConvertTimeStampToDate(aux2.data)

      return aux2
    })
    
    return aux 
  }

  FormatExameLaboratorioToDate(laboratorial: ExameLaboratorio) : ExameLaboratorio {

    var aux2 = laboratorial

    if (!aux2) return aux2

    aux2.datas.data1 = this.ConvertTimeStampToDate(aux2.datas.data1)
    aux2.datas.data2 = this.ConvertTimeStampToDate(aux2.datas.data2)
    aux2.datas.data3 = this.ConvertTimeStampToDate(aux2.datas.data3)

    return aux2

  }

  FormatarConsulta(consulta: ConsultaPaciente) : ConsultaPaciente {
    var aux: any = consulta 

    aux.data = this.ConvertTimeStampToDate(aux.data)
    
    return aux 
  }
  

  /*****************************************************************************************************************************************
   * 
   *                                                                 MÉDICOS
   * 
   *****************************************************************************************************************************************/
  CadastrarMedico(medico: Medico): Promise<any> {

    return new Promise(async (resolve, reject) => {

      this._api.post('/api/usuarios', medico).subscribe(result => {

        resolve(result.data) 

      }, err => reject(err))

    })

  }

  TotalCartoesUsadosPorMedico(medico_id: string): Promise<number> {

    return new Promise((resolve, reject) => {

      this._api.get('/api/cartoes', { medico: medico_id }).subscribe(result => {  
        
        let cartoesGestantes: Array<CartaoGestante> = result.data
        
        if (!cartoesGestantes.length) resolve(null) 

        var filtrado = cartoesGestantes.filter(cartao => cartao.ativado) 

        resolve(filtrado.length) 

      }, err => reject(err))

    })

  }

  PegarTotalMedicos(): Promise<number> {
      
    return new Promise((resolve, reject) => {

      this._api.get('/api/usuarios', { tipoUsuario: 'medico', count: true }).subscribe(result => {

        let medicosCount: number = result.data

        resolve(medicosCount)

      }, err => reject(err))

    })

  }

  PegarListaMedicos(): Observable<Medico[]> {

    return Observable.create((observer) => {

      this._api.get('/api/usuarios', { tipoUsuario: 'medico' }).subscribe(result => {

        let medicos: Array<Medico> = result.data

        if (medicos.length) {

          var medicosEditados = medicos.map((item) => {
            if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)

            return item
          }) 

          medicosEditados.sort((a,b) => a.primeiro_nome > b.primeiro_nome ? 1 : -1) 

          observer.next(medicosEditados ? medicosEditados : []) 
          observer.complete() 

        }

      }, err => observer.error(err))

    })

  }

  PegarUmMedicoPorEmail(email_medico: string, token?: string): Promise<Medico> {

    return new Promise((resolve, reject) => {
      if (token) this._api.setAuthorization(token);

      if (!email_medico) {
        resolve(null);
        return
      }

      this._api.get('/api/usuarios', { tipoUsuario: 'medico', email: email_medico}).subscribe((result) => {
        if (!result.data) resolve(null)

        let medicos: Array<Medico> = result.data

        if (medicos.length != 0) {
          let medico = medicos[0] 

          if (medico.idade) medico.idade = this.ConvertTimeStampToDate(medico.idade) 

          resolve(medico) 

        } else resolve(null)

      }, err => reject(err))

    })

  }

  PegarUmMedicoPorId(id_medico: string): Promise<Medico> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/usuarios/${id_medico}`).subscribe(result => {

        let medico: Medico = result.data

        if (medico.idade) medico.idade = this.ConvertTimeStampToDate(medico.idade) 

        resolve(medico)

      }, err => reject(err))

    })

  }

  PegarListaPacientesDeUmMedico(medicoId: string): Observable<Paciente[]> {
    
    return Observable.create(async (observer) => {

      try {

        this._api.get('/api/usuarios', { tipoUsuario: 'paciente', medico: medicoId, detailCartao: true }).subscribe(result => {
          
          var pacientes: Array<Paciente> = result.data

          var pacientesEditado = pacientes.map((item) => {

            item.cartao_gestante = item.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

            const cartoesOrder = item.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())

            item.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null

            if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)
            
            return item

          })

          pacientesEditado.sort((a,b) =>  a.primeiro_nome > b.primeiro_nome ? 1 : -1)

          observer.next(pacientesEditado) 

        })

      } catch (error) {
        observer.error(error) 
      }
      
    })

  }

  PegarListaPacientesDeUmMedicoPage(medicoId: string, page: number = 1, limit: number = 12,  likeName: String = '', ativado: boolean = null, encerrado: boolean = null): Observable<Pagination<Paciente>> {
  
    return Observable.create(async (observer) => {
      try {
        this._api.get('/api/usuarios/page', { name: likeName, tipoUsuario: 'paciente', medico: medicoId, detailCartao: true, page, limit, cartaoAtivado: ativado, cartaoEncerrado: encerrado }).subscribe(result => {
          const { page, data, total, totalInPage, totalPages } = result
            
          var pacientes: Array<Paciente> = data

          var pacientesEditado = pacientes.map((item) => {

            item.cartao_gestante = item.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

            let cartoesOrder = item.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())

            item.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null

            if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)
            
            return item

          })

          pacientesEditado.sort((a,b) =>  a.primeiro_nome > b.primeiro_nome ? 1 : -1)

          const pagination: Pagination<Paciente> = {
            data: pacientesEditado,
            totalInPage,
            totalPages,
            total,
            page
          }

          observer.next(pagination) 

        })

      } catch (error) {
        observer.error(error) 
      }
    })
  }

  PegarCartoesGestanteDoMedico(email_medico: string ): Promise<any> {
    return new Promise((resolve, reject) => {
        // this.firestore.collection('cartao_gestante', ref => ref.where())
    })
  }

  ProximaConsultaMedico(email_medico: string): Promise<ConsultaPaciente> {

    return new Promise(async (resolve, reject) => {

      try {
          var consultas = await this.ListaConsultasMedico(email_medico)

          var proximas_consultas = consultas.proximas 
          
          if (proximas_consultas.length > 0) resolve(proximas_consultas[0]) 
          else resolve(null) 

      } catch (error) {

        reject(error)

      }

    })

  }

  AtualizarMedico(medico: Medico): Promise<Medico> {

    return new Promise((resolve, reject) => {

      this._api.put(`/api/usuarios/${medico.id}`, medico).subscribe(result => {

        let medico: Medico = result.data

        resolve(medico) 
        
      }, err => reject(err))

    })

  }

  DeletarMedico(medico: Medico): Promise<Boolean> {
    return new Promise((resolve, reject) => {

      this._api.delete('/api/usuarios', medico.id).subscribe(() => {

        resolve(true) 

      }, err => reject(err)) 

    })
  }  

  /*****************************************************************************************************************************************
   * 
   *                                                             Secretarias
   * 
   *****************************************************************************************************************************************/
  CadastrarSecretaria(secretaria: Secretaria): Promise<any> {

    return new Promise(async (resolve, reject) => {

      this._api.post('/api/usuarios', secretaria).subscribe(result => {

        let secretaria: Secretaria = result.data

        if (!secretaria) reject("Nenhuma secretaria encontrada")

        resolve(secretaria) 

      }, err => reject(err))

    })

  }
  
  PegarListaSecretarias(): Observable<Secretaria[]> {

    return Observable.create((observer) => {

      this._api.get('/api/usuarios', { tipoUsuario: 'secretaria' }).subscribe(result => {

        let secretarias: Array<Secretaria> = result.data

        if (!secretarias.length) observer.error('Nenhuma secretaria encontrada')

        observer.next(secretarias)

        observer.complete()

      }, err => observer.error(err))

    })

  }

  AtualizarSecretaria(secretaria: Secretaria): Promise<Secretaria> {

    return new Promise((resolve, reject) => {

      this._api.put(`/api/usuarios/${secretaria.id}`, secretaria).subscribe(result => {

        let secretaria: Secretaria = result.data

        resolve(secretaria) 
        
      }, err => reject(err))

    })

  }

  DeletarSecretaria(secretaria: Secretaria): Promise<Boolean> {
    return new Promise((resolve, reject) => {

      this._api.delete('/api/usuarios', secretaria.id).subscribe(() => {

        resolve(true) 

      }, err => reject(err)) 

    })
  }

  PegarTotalSecretarias(): Promise<number> {
        
    return new Promise((resolve, reject) => {

      this._api.get('/api/usuarios', { tipoUsuario: 'secretaria', count: true }).subscribe(result => {
          
        let secretariasCount: number = result.data

        resolve(secretariasCount)

      }, err => reject(err))

    })

  }

  PegarSecretariaPorId(id: string): Promise<Secretaria> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/usuarios/${id}`).subscribe(result => {

        const secretaria: Secretaria = result.data

        if (secretaria.idade) secretaria.idade = this.ConvertTimeStampToDate(secretaria.idade)

        resolve(secretaria)

      }, err => reject(err))

    })
  }

  PegarUmaSecretariaPorEmail(email_secretaria: string, token?: string): Promise<Secretaria> {
    return new Promise((resolve, reject) => {
      if (token) this._api.setAuthorization(token);

      if (!email_secretaria) {
        resolve(null);
        return
      }

      this._api.get('/api/usuarios', { tipoUsuario: 'secretaria', email: email_secretaria}).subscribe((result) => {
        if (!result.data) resolve(null)

        let secretarias: Array<Secretaria> = result.data

        if (secretarias.length != 0) {
          let secretaria = secretarias[0] 

          if (secretaria.idade) secretaria.idade = this.ConvertTimeStampToDate(secretaria.idade) 

          resolve(secretaria) 

        } else resolve(null)

      }, err => reject(err))

    })
  }

  PegarListaPacientesPorSecretaria(secretariaId: string): Observable<Paciente[]> {
    
    return Observable.create(async (observer) => {

      try {

        this._api.get('/api/usuarios', { tipoUsuario: 'paciente', secretaria: secretariaId, detailCartao: true }).subscribe(result => {
          
          var pacientes: Array<Paciente> = result.data

          var pacientesEditado = pacientes.map((item) => {

            item.cartao_gestante = item.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

            const cartoesOrder = item.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())

            item.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null

            if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)
            
            return item

          })

          pacientesEditado.sort((a,b) =>  a.primeiro_nome > b.primeiro_nome ? 1 : -1)

          observer.next(pacientesEditado) 

        })

      } catch (error) {
        observer.error(error) 
      }
      
    })

  }

  PegarListaPacientesPorSecretariaPage(secretariaId: string, page: number = 1, limit: number = 12, likeName: String = '', ativado: boolean = null, encerrado: boolean = null): Observable<Pagination<Paciente>> {
    
      return Observable.create(async (observer) => {
        try {
          this._api.get('/api/usuarios/page', {  name: likeName, tipoUsuario: 'paciente', secretaria: secretariaId, detailCartao: true, page, limit, cartaoAtivado: ativado, cartaoEncerrado: encerrado }).subscribe(result => {
            const { page, data, total, totalInPage, totalPages } = result
              
            var pacientes: Array<Paciente> = data
  
            var pacientesEditado = pacientes.map((item) => {
  
              item.cartao_gestante = item.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))
  
              let cartoesOrder = item.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())
  
              item.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null
  
              if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)
              
              return item
  
            })
  
            pacientesEditado.sort((a,b) =>  a.primeiro_nome > b.primeiro_nome ? 1 : -1)
  
            const pagination: Pagination<Paciente> = {
              data: pacientesEditado,
              totalInPage,
              totalPages,
              total,
              page
            }
  
            observer.next(pagination) 
  
          })
  
        } catch (error) {
          observer.error(error) 
        }
      })
    }

  /*****************************************************************************************************************************************
   * 
   *                                                             PACIENTES
   * 
   *****************************************************************************************************************************************/
  AtualizarQuantPacienteDoMedico(id_medico: string, value: number): Promise<Medico> {

    return new Promise(async (resolve, reject) => {

      try {

        let medico = await this.PegarUmMedicoPorId(id_medico)

        medico.n_pacientes = medico.n_pacientes + value

        let medicoAtualizado = await this.AtualizarMedico(medico)

        resolve(medicoAtualizado)

      } catch (error) {

        reject(error)

      }

    })

  }

  CadastrarPaciente(paciente: Paciente): Promise<Paciente> {

    return new Promise(async (resolve, reject) => {

      this._api.post('/api/usuarios?full=true', paciente).subscribe(async result => {

        let paciente: Paciente = result.data

        resolve(paciente)

      }, err => reject(err))

    })

  }

  PegarTotalPacientes(): Promise<number> {
        
    return new Promise((resolve, reject) => {

      this._api.get('/api/usuarios', { tipoUsuario: 'paciente', count: true }).subscribe(result => {
          
        let secretariasCount: number = result.data

        resolve(secretariasCount)

      }, err => reject(err))
      
    })

  }

  PegarDadosExtraPaciente(id_paciente: string): Promise<CartaoGestante[]> {

    return new Promise(async (resolve, reject) => {

      try {
        
        const paciente: Paciente = await this.PegarPacientePorId(id_paciente)

        const cartao_gestante = paciente.cartao_gestante.filter(item => { return (!item.hasOwnProperty('encerrado') || item.encerrado !== true) && item.hasOwnProperty('ativado') && item.ativado === true })

        resolve(cartao_gestante)

      } catch (error) {

        reject(error)

      }

    })
  }

  PegarListaPacientes(infoCompleta: boolean = false): Observable<Paciente[]> {

    return Observable.create((observer) => {

      let query = { tipoUsuario: 'paciente', detailCartao: false }

      if (infoCompleta) query.detailCartao = true

      this._api.get('/api/usuarios', query).subscribe(result => {

        var pacientes: Array<Paciente> = result.data

        if (!pacientes.length) observer.next([])

        var pacientesEditado = pacientes.map((item) => {

          item.cartao_gestante = item.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

          const cartoesOrder = item.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())

          item.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null

          if (item.idade) item.idade = this.ConvertTimeStampToDate(item.idade)
          
          return item

        })

        pacientesEditado.sort((a, b) => a.primeiro_nome > b.primeiro_nome ? 1 : -1)

        observer.next(pacientesEditado ? pacientesEditado : [])

        observer.complete()

      })

    })

  }

  PegarPacientePorId(id: string): Promise<Paciente> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/usuarios/${id}`).subscribe((result) => {

        let paciente: Paciente = result.data

        if (paciente.idade) paciente.idade = this.ConvertTimeStampToDate(paciente.idade)

        paciente.cartao_gestante = paciente.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

        resolve(paciente)

      }, err => reject(err))

    })
  }

  PegarPacientePorIdCompletasso(id: string): Promise<Paciente> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/usuarios/${id}`).subscribe(result => {

        const paciente: Paciente = result.data

        if (paciente.idade) paciente.idade = this.ConvertTimeStampToDate(paciente.idade)

        paciente.cartao_gestante = paciente.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

        resolve(paciente)

      }, err => reject(err))

    })

  }

  PegarPacientePorEmail(paciente_email: string,token?: string): Promise<Paciente> {

    return new Promise((resolve, reject) => {
      if (token) this._api.setAuthorization(token);

      this._api.get('/api/usuarios', { tipoUsuario: 'paciente', email: paciente_email, detailCartao: true }).subscribe(result => {

        if (!result.data.length) {
          reject('Nenhum paciente encontrado')
          return
        }
          
        const pacientes: Array<Paciente> = result.data

        const paciente: Paciente = pacientes[0]

        if (paciente.idade) paciente.idade = this.ConvertTimeStampToDate(paciente.idade)

        paciente.cartao_gestante = paciente.cartao_gestante.map(cartao => this.FormatCartaoGestanteToDate(cartao))

        const cartoesOrder = paciente.cartao_gestante.sort((a, b) => a.criado_em.getTime() - b.criado_em.getTime())

        paciente.ultimo_cartao = cartoesOrder.length > 0 ? cartoesOrder[0] : null

        resolve(paciente)

      }, err => reject(err))

    })

  }

  PegarIdDoPacientePorEmail(paciente_email: string): Promise<string> {

    return new Promise((resolve, reject) => {

      this.PegarPacientePorEmail(paciente_email)
        .then(paciente => resolve(paciente.id))
        .catch(err => reject(err))

    })

  }

  ProximaConsultaPaciente(id_paciente: string): Promise<ConsultaPaciente> {

    return new Promise(async (resolve, reject) => {

      await this.ListaConsultasPaciente(id_paciente).then(consultas => {

        var proxima_consulta = consultas.proximas.length > 0 ? consultas.proximas[0] : null

        resolve(proxima_consulta)

      }).catch(err => reject(err))

    })

  }

  AtualizarPaciente(paciente: Paciente): Promise<Paciente> {

    return new Promise((resolve, reject) => {

      this._api.put(`/api/usuarios/${paciente.id}`, paciente).subscribe(result => {

        const paciente: Paciente = result.data

        resolve(paciente)

      }, err => reject(err))
    })
  }

  DeletarPaciente(paciente_email: string, id?: string): Promise<Boolean> {

    return new Promise(async (resolve, reject) => {

      try {
        // Listar todos os cartões do paciente 
        var paciente = await this.PegarPacientePorEmail(paciente_email)

        if (paciente.id !== id) var paciente = await this.PegarPacientePorId(id)

        this._api.delete('/api/usuarios', paciente.id).subscribe(async () => {

          resolve(true)

        }, err => reject(err))

      } catch (error) {
        reject(error)
      }
    })

  }

  AtualizarUrlPerfil(id_usuario: string, url: string): Promise<Usuario> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/usuarios/${id_usuario}`).subscribe(result => {

        const perfil: Usuario = result.data

        perfil.url = url

        this._api.put(`/api/usuarios/${id_usuario}`, perfil).subscribe(result => {

          const perfilAtualizado: Usuario = result.data

          resolve(perfilAtualizado)

        }, err => reject(err))

      }, err => reject(err))

    })

  }
  

  /*****************************************************************************************************************************************
   * 
   *                                                             EXAMES
   * 
   *****************************************************************************************************************************************/
  InserirExameClinico(cartao_id: string, exame: any, todos_exames: any): Promise<Exames> {

    return new Promise((resolve, reject) => {

      var novos_exames = todos_exames
      novos_exames.clinicos.push(exame)

      this._api.post(`/api/cartoes/${cartao_id}/exames`, novos_exames).subscribe(result => {

        const exames: Exames = result.data

        resolve(exames)

      }, err => reject(err))

    })

  }

  EditarExameClinico(cartao_id: string, exameOld: any, exameNew: any, todos_exames: Exames): Promise<any> {

    let index = todos_exames.clinicos.indexOf(exameOld)
    todos_exames.clinicos[index] = exameNew

    return new Promise((resolve, reject) => {

      this._api.post(`/api/cartoes/${cartao_id}/exames`, todos_exames).subscribe(result => {

        const exames: Exames = result.data

        resolve(exames)

      }, err => reject(err))

    })

  }

  InserirLab(cartao_id: string, exame: any, todos_exames: any): Promise<Exames> {

    return new Promise((resolve, reject) => {

      var novos_exames = exame
      var exames_anteriores = todos_exames.laboratorio
      var lab_atualizado = []

      var pos = 0
      novos_exames.forEach(element => {

        var item = exames_anteriores.find(item => item.name == element.name)
        var index = exames_anteriores.indexOf(item)

        if (index == -1) {
          exames_anteriores.splice(pos, 0, element)
        } else {
          var aux = {
            name: element.name,
            data_limite_tri1: exames_anteriores[index].data_limite_tri1 == '' ? element.data_limite_tri1 : exames_anteriores[index].data_limite_tri1,
            data_limite_tri2: exames_anteriores[index].data_limite_tri2 == '' ? element.data_limite_tri2 : exames_anteriores[index].data_limite_tri2,
            data_limite_tri3: exames_anteriores[index].data_limite_tri3 == '' ? element.data_limite_tri3 : exames_anteriores[index].data_limite_tri3,
            datas: {
              data1: exames_anteriores[index].datas.data1 == '' ? element.datas.data1 : exames_anteriores[index].datas.data1,
              data2: exames_anteriores[index].datas.data2 == '' ? element.datas.data2 : exames_anteriores[index].datas.data2,
              data3: exames_anteriores[index].datas.data3 == '' ? element.datas.data3 : exames_anteriores[index].datas.data3,
              res1: exames_anteriores[index].datas.res1 == '' ? element.datas.res1 : exames_anteriores[index].datas.res1,
              res2: exames_anteriores[index].datas.res2 == '' ? element.datas.res2 : exames_anteriores[index].datas.res2,
              res3: exames_anteriores[index].datas.res3 == '' ? element.datas.res3 : exames_anteriores[index].datas.res3,
            }
          }

          exames_anteriores[index] = aux
        }

        pos++

      })

      let exameBlackList = ['Protoparasitologia', 'Parasitológico']
      exames_anteriores = exames_anteriores.filter(item => {

        if (exameBlackList.indexOf(item.name) > -1) return false

        return true

      })

      var exames_atualizados = todos_exames
      exames_atualizados.laboratorio = exames_anteriores

      this._api.post(`/api/cartoes/${cartao_id}/exames`, exames_atualizados).subscribe(result => {
        
        const exames: Exames = result.data

        resolve(exames)
        
      }, err => reject(err))

    })

  }

  AlterarValorExameLab(cartao_id: string, trimestre: number, allTrimestre: boolean = false, valor: string, exame: any, todos_os_exames: any): Promise<Exames> {
    
    return new Promise((resolve, reject) => {

      var todos_exames = todos_os_exames
      var laboratoriais = todos_exames.laboratorio

      var aux = laboratoriais.filter(item => item.name == exame.name)
      var exame_a_editar = aux[0]

      var index = laboratoriais.indexOf(exame_a_editar)

      if (allTrimestre) {
        exame_a_editar.datas[`res1`] = valor
        exame_a_editar.datas[`res2`] = valor
        exame_a_editar.datas[`res3`] = valor
      } else {
        exame_a_editar.datas[`res${trimestre}`] = valor
      }

      laboratoriais[index] = exame_a_editar
      todos_exames.laboratorio = laboratoriais

      this._api.post(`/api/cartoes/${cartao_id}/exames`, todos_exames).subscribe(result => {

        const exames: Exames = result.data

        resolve(exames)

      }, err => reject(err))

    })

  }

  AlterarTrimestreExameLab(cartao_id: string, trimestre: number, valor: string, todos_os_exames: any): Promise<ExameLaboratorio[]> {

    return new Promise((resolve, reject) => {
      console.log(trimestre)

      var todos_exames = todos_os_exames
      var novadata = valor

      var todos_exames_laboratorio = todos_exames.laboratorio.map((item) => {

        item.datas['data'+trimestre] = novadata

        return item

      })

      const cartaoUpd = { ...todos_exames, laboratorio: todos_exames_laboratorio }

      this._api.post(`/api/cartoes/${cartao_id}/exames`, cartaoUpd).subscribe(result => {

        const exames: Exames = result.data

        resolve(exames.laboratorio)

      }, err => reject(err))

    })

  }

  InserirUSG(cartao_id: string, exame: any, todos_exames: any): Promise<Exames> {

    return new Promise((resolve, reject) => {

      var novos_exames = todos_exames; 
      novos_exames.ultrasom.push(exame); 

      this._api.post(`/api/cartoes/${cartao_id}/exames`, novos_exames).subscribe(result => {
        
        const exames: Exames = result.data

        if (!exames) reject('Erro ao pegar exames')
        
        resolve(exames)

      }, err => reject(err))
          
    });

  }

  InserirOutros(cartao_id: string, exame: any, todos_exames: any): Promise<Exames> {
    
    return new Promise((resolve, reject) => {
      
      var novos_exames = todos_exames;
      novos_exames.outros.push(exame);

      this._api.post(`/api/cartoes/${cartao_id}/exames`, novos_exames).subscribe(result => {

        const exames: Exames = result.data

        if (!exames) reject('Erro ao pegar exames')

        resolve(exames);

      }, err => reject(err))
    
    });
  
  }

  
  /*****************************************************************************************************************************************
   * 
   *                                                             CONSULTAS
   * 
   *****************************************************************************************************************************************/
  CriarConsulta(email_paciente: string, email_medico: string, id_cartao_gestante: string, dados_consulta: any): Promise<ConsultaPaciente[]> {

    return new Promise((resolve, reject) => {

      var aux: ConsultaPaciente = ConsultaPaciente.create(dados_consulta, email_medico, email_paciente, id_cartao_gestante)

      this._api.post(`/api/cartoes/${id_cartao_gestante}/consultas`, aux).subscribe(result => {
        const consultas: Array<ConsultaPaciente> = result.data

        resolve(consultas)
      }, err => reject(err))

    })

  }

  ListaConsultasMedico(email_medico: string): Promise<ListaConsulta> {

    return new Promise(async (resolve, reject) => {

      try {

        var medico = await this.PegarUmMedicoPorEmail(email_medico)

        this._api.get('/api/cartoes/consultas', { medico: medico.id }).subscribe(async result => {

          const cartoes: Array<CartaoGestante> = result.data

          if (!cartoes.length) resolve(null)

          const consultas: Array<ConsultaPaciente> = []

          cartoes.forEach(cartao => {
            cartao.consultas.forEach(item => {
              consultas.push(item)
            })
          })

          var consultas_filtradas = await this.FilterByActiveCard(consultas)

          var ordenado = this.OrderByDate(consultas_filtradas)

          var nomesPacientes: Promise<Paciente>[] = ordenado.map(item => this.PegarPacientePorId(item.paciente_id))

          var allPromises = await Promise.all(nomesPacientes)

          var consulta_editada: Array<ConsultaPaciente> = allPromises.map((item, index) => {
            return { ...ordenado[index], nome_paciente: `${item.primeiro_nome} ${item.sobrenome}` }
          })

          var consultas_divididas = this.DivideNextAndPrevious(consulta_editada)

          resolve(consultas_divididas)

        }, err => reject(err))

      } catch (error) {

        reject(error)

      }

    })

  }

  ListaConsultasPaciente(id_paciente: string): Promise<ListaConsulta> {

    return new Promise((resolve, reject) => {

      this._api.get('/api/cartoes/consultas', { paciente: id_paciente }).subscribe(async result => {

        const cartoes: Array<CartaoGestante> = result.data

        if (!cartoes.length) resolve(null)

        const consultas: Array<ConsultaPaciente> = []

        cartoes.forEach(cartao => {
          cartao.consultas.forEach(item => {
            consultas.push(item)
          })
        })

        var consultas_filtradas = await this.FilterByActiveCard(consultas)

        var ordenada = this.OrderByDate(consultas_filtradas)

        var consultas_divididas = this.DivideNextAndPrevious(ordenada)

        resolve(consultas_divididas)

      }, err => reject(err))

    })

  }

  ListaConsultasPorCartao(cartao_id: string): Promise<ConsultaPaciente[]> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/cartoes/${cartao_id}/consultas`).subscribe(async result => {

        const consultas: Array<ConsultaPaciente> = result.data

        var ordenado = this.OrderByDate(consultas)

        var nomesPacientes: Promise<Paciente>[] = ordenado.map(item => {
          return this.PegarPacientePorId(item.paciente_id)
        })

        var allPromises = await Promise.all(nomesPacientes)

        var consulta_editada: Array<ConsultaPaciente> = allPromises.map((item, index) => {
          return { ...ordenado[index], nome_paciente: `${item.primeiro_nome} ${item.sobrenome}` }
        })

        resolve(consulta_editada)

      }, err => reject(err))

    })

  }

  AlterarConsulta(id_consulta: string, consulta: ConsultaPaciente): Promise<ConsultaPaciente[]> {

    return new Promise((resolve, reject) => {

      this._api.put(`/api/cartoes/${consulta.cartao_paciente_id}/consultas/${id_consulta}`, consulta).subscribe(result => {

        const consultas: Array<ConsultaPaciente> = result.data 

        resolve(consultas)

      }, err => reject(err))
    
    });

  }

  DeletarConsulta(id_consulta: string): Promise<void> {

    return new Promise((resolve, reject) => {

      this._api.delete(`/api/cartoes/consultas`, id_consulta).subscribe(() => {
        resolve()
      }, err => reject(err))

    });

  }


  /*****************************************************************************************************************************************
   * 
   *                                                             CARTÃO GESTANTE
   * 
   *****************************************************************************************************************************************/
  AdicionarCartaoAPaciente(id_paciente: string, email_medico: string): Promise<CartaoGestante> {

    return new Promise(async (resolve, reject) => {

      var cartao = CartaoGestante.create()
      var aux = { ...cartao, paciente: id_paciente, medico: email_medico, used: false }

      this._api.post('/api/cartoes', aux).subscribe(result => {

        let cartao: CartaoGestante = result.data

        resolve(cartao)

      }, err => reject(err))

    })

  }

  LerUmCartaoGestante(id: string): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/cartoes/${id}`).subscribe(result => {

        if (!result.data) resolve(null)

        let cartao: CartaoGestante = result.data

        var cartaoConverted = this.FormatCartaoGestanteToDate(cartao)

        resolve(cartaoConverted)

      }, err => reject(err))

    })

  }

  LerOCartaoAtivo(paciente_id: string): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      this._api.get('/api/cartoes', { paciente: paciente_id, ativado: true, limit: 10 }).subscribe(result => {

        const cartoes: Array<CartaoGestante> = result.data

        if (!cartoes.length) resolve(null)

        const cartao = cartoes[0]

        const cartaoConverted = this.FormatCartaoGestanteToDate(cartao)

        resolve(cartaoConverted)

      }, err => reject(err))

    })

  }

  LerOCartaoEncerrado(paciente_id: string): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      this._api.get('/api/cartoes', { paciente: paciente_id, noDefault: true, limit: 10 }).subscribe(result => {

        const cartoes: Array<CartaoGestante> = result.data

        if (!cartoes.length) resolve(null)

        const cartao = cartoes[0]

        const cartaoConverted = this.FormatCartaoGestanteToDate(cartao)

        resolve(cartaoConverted)

      }, err => reject(err))

    })

  }

  LerUltimoCartao(paciente_id: string): Promise<CartaoGestante> {

    return new Promise(async (resolve, reject) => {

      const cartaoAtivo = await this.LerOCartaoAtivo(paciente_id)

      if (cartaoAtivo) resolve(cartaoAtivo)

      const cartaoEncerrado = await this.LerOCartaoEncerrado(paciente_id)

      resolve(cartaoEncerrado)

    })

  }

  AtivarUmCartao(paciente_email: string, cartao_id: string, paciente_id: string = null): Promise<CartaoGestante> {

    return new Promise(async (resolve, reject) => {

      try {

        var id_do_paciente = paciente_id
        if (id_do_paciente == null) id_do_paciente = await this.PegarIdDoPacientePorEmail(paciente_email)

        var cartao_ativo = await this.LerOCartaoAtivo(id_do_paciente)

        if (cartao_ativo == null) {

          if (!(await this.WasCardUsed(cartao_id))) {

            const cartao: CartaoGestante = await this.LerUmCartaoGestante(cartao_id)

            cartao.ativado = true

            this._api.put(`/api/cartoes/${cartao.id}`, cartao).subscribe(result => {

              const cartao: CartaoGestante = result.data

              resolve(cartao)

            }, err => reject(err))

          } else reject('Esse cartão já foi usado uma vez!')

        } else reject('já existe um cartão ativo pra esse paciente')

      } catch (error) {

        reject(error)

      }

    })

  }

  AtivarUmCartaoPeloMedico(medico_id: string, paciente_id: string, cartao_id: string): Promise<CartaoGestante> {

    return new Promise(async (resolve, reject) => {

      try {

        var medico = await this.PegarUmMedicoPorId(medico_id)

        var cartoes_usados = await this.TotalCartoesUsadosPorMedico(medico_id)

        if (medico.maximo_cartoes > cartoes_usados)
          this.AtivarUmCartao('', cartao_id, paciente_id)
            .then(res => resolve(res))
            .catch(err => reject(err))
            
        else reject('Usuário atingiu o limite de cartões!')

      } catch (error) {

        reject(error)

      }

    })

  }

  DesativarUmCartao(cartao_id: string): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      this.LerUmCartaoGestante(cartao_id)
        .then(cartao => {

          cartao.ativado = false

          this._api.put(`/api/cartoes/${cartao_id}`, cartao).subscribe(result => {

            const cartao: CartaoGestante = result.data

            resolve(cartao)

          }, err => reject(err))

        })
        .catch(err => reject(err))

    })

  }

  RegistrarEncerramento(cartao_id: string, encerramentoObj: object): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      const cartaoEncerrado = { ativado: false, encerrado: true, ...encerramentoObj }

      this.AtualizarCartao(cartao_id, cartaoEncerrado)
        .then(cartao => resolve(cartao))
        .catch(err => reject(err))

    })

  }

  AtualizarCartao(cartao_id: string, cartao_atualizado: any): Promise<CartaoGestante> {

    return new Promise((resolve, reject) => {

      this.LerUmCartaoGestante(cartao_id)
        .then(cartao => {

          cartao = { ...cartao, ...cartao_atualizado }

          this._api.put(`/api/cartoes/${cartao_id}`, cartao).subscribe(result => {

            const cartao: CartaoGestante = result.data

            resolve(cartao)

          }, err => reject(err))

        })
        .catch(err => reject(err))

    })

  }

  DeletarCartao(cartao_id: string): Promise<void> {
    
    return new Promise((resolve, reject) => {

      this._api.delete('/api/cartoes', cartao_id).subscribe(() => {
        resolve()
      }, err => reject(err))

    })

  }

  WasCardUsed(cartao_id: string): Promise<boolean> {

    return new Promise((resolve, reject) => {

      this._api.get(`/api/cartoes/${cartao_id}`).subscribe(result => {

        const cartao: CartaoGestante = result.data

        resolve(cartao ? cartao.used : true)

      })

    })

  }
}