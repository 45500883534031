import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MaintenanceComponent } from './blocked-session/maintenance.component';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AlterPasswordComponent } from './alter-password/alter-password.component';

const routes: Routes = [
  {
      path: `auth`, 
      children: [
        {
            path: ``, 
            redirectTo: `login`, 
            pathMatch: `full`
        },
        {
            path: `login`,
            component: LoginComponent,
        },
        {
            path: `esqueceu-senha`, 
            component: ForgotPasswordComponent
        },
        {
            path: `resetar-senha`, 
            component: ResetPasswordComponent
        },
        {
            path: `alter-password`, 
            component: AlterPasswordComponent
        },
        {
            path: 'blocked', 
            component: MaintenanceComponent
        },
        {
          path: 'signup', 
          component: SignupComponent
      }
      ]
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
