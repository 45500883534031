import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { SecretariaService } from '../../secretaria.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-modal-consultas-diaria',
  templateUrl: './modal-consultas-diaria.component.html',
  styleUrls: ['./modal-consultas-diaria.component.scss']
})
export class ModalConsultasDiariaComponent implements OnInit {

  eventos: ConsultaPaciente[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<ModalConsultasDiariaComponent>, 
    private secretariaService: SecretariaService, 
    private router: Router
  ) {
    this.eventos = this.data.events.map((item) => { 
      return item.meta.notes; 
    }); 
  }


  ngOnInit() {
  }

  async EventoClicado(item) {
    try {
      var dados_paciente = await this.secretariaService.GetPacientePorId(item.paciente_id); 
      var extras: NavigationExtras = {
        state: {
          paciente: dados_paciente, 
          consulta: item
        }
      };
      this.dialogRef.close(); 
      this.router.navigate(['/secretaria-nova-consulta'], extras);
    } catch (error) {
      
    }
  }

}
