import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bt-warning',
    templateUrl: './bt-warning.component.html',
    styleUrls: ['./bt-warning.component.scss']
})
export class BtWarningComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
