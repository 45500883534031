import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { MedicoService } from '../../medico.service';
import { Paciente, CartaoGestante, ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { FormGroup } from '@angular/forms';
import { ModalsService } from 'app/shared/modals.service';

@Component({
  selector: 'app-medico-nova-consulta',
  templateUrl: './medico-nova-consulta.component.html',
  styleUrls: ['./medico-nova-consulta.component.scss']
})
export class MedicoNovaConsultaComponent implements OnInit, AfterViewInit {

  paciente: Paciente;
  cartao: CartaoGestante;

  consulta: ConsultaPaciente;

  @ViewChild('dados_consulta') componente_dados_consulta: any;

  form_data: FormGroup;
  form_dados_consulta: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackService: AlertsService,
    private medicoService: MedicoService,
    private modalServices: ModalsService,
    private alerts: AlertsService,
  ) {

    const aux = this.router.getCurrentNavigation().extras.state;
    this.paciente = aux.paciente ? aux.paciente : null;
    this.cartao = aux.cartao ? aux.cartao : null;

    // Dados anteriores 
    this.consulta = aux.consulta ? aux.consulta : null;

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.consulta != null) {
      this.form_data = this.componente_dados_consulta.dataConsulta;
      this.form_dados_consulta = this.componente_dados_consulta.dadosConsulta;

      this.form_data.setValue({
        data: this.consulta.data,
        hora: this.consulta.data ? `${this.consulta.data.getHours()}:${this.consulta.data.getMinutes()}` : ''
      });

      this.form_dados_consulta.setValue({
        ig_dum: this.consulta.ig_dum,
        ig_usg: this.consulta.ig_usg,
        peso: this.consulta.peso,
        pa: this.consulta.pa,
        au: this.consulta.au,
        bcf: this.consulta.bcf,
        mf: this.consulta.mf,
        edema: this.consulta.edema,
        anotacoes_gerais: this.consulta.anotacoes_gerais,
      });
    }
  }

  SalvarConsulta(dados) {

    if (this.consulta == null) {

      this.medicoService.CriarConsulta(dados, this.paciente.medico_app, this.paciente.id, this.cartao.id).then(() => {

        this.snackService.SnackMessage('Consulta salva com sucesso!');
        this.router.navigate(['/medico']);

      }).catch(() => {

        this.snackService.SnackMessage('Erro ao salvar consulta');
      });
    } else {
      this.medicoService.AtualizarConsulta(dados, this.consulta.id).then(() => {

        this.snackService.SnackMessage('Consulta atualizada com sucesso!');
        this.router.navigate(['/medico']);

      }).catch(() => {

        this.snackService.SnackMessage('Erro ao atualizar consulta');

      });
    }

  }

  ExcluirConsulta() {

    this.modalServices.AbrirModalDeConfirmacao('Excluir consulta', 'Tem certeza que deseja excluir essa consulta? Essa ação não poderá ser desfeita, deseja continuar?')
      .subscribe((res) => {

        if (res) {

          this.medicoService.ExcluirConsulta(this.consulta.id).then(() => {

            this.snackService.SnackMessage('Consulta excluida com sucesso');
            this.router.navigate(['/medico']);

          }).catch(() => {

            this.snackService.SnackMessage('Erro ao excluir consulta');

          });

        }

      });

  }

  async VerCartao() {
    
    const navigationExtras: NavigationExtras = {
      state: {
        cartao_id: this.consulta.cartao_paciente_id,
        email_paciente: this.paciente.email,
        paciente_id: this.paciente.id
      }
    };

    this.router.navigate(['/medico-alterar-cartao-gestante'], navigationExtras);

  }


  Voltar() {

    if (this.medicoService.GetPreviousUrl() == '/medico-alterar-cartao-gestante') {

      this.VerCartao();
      
    } else {
      
      this.router.navigateByUrl('/medico-consultas');

    }

  }

}
