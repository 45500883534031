import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { Observable } from 'rxjs';
import { Secretaria } from 'app/core/interfaces/secretaria.interface';
import { PreviousRouteService } from 'app/shared/previous-route.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { Usuario } from 'app/core/interfaces/usuario.interface';
import { Medico } from 'app/core/interfaces/medico.interface';


@Injectable({
  providedIn: 'root'
})
export class SecretariaService {

  usuario: any; 
  navigation: any; 
  constructor(
    private authService: AuthService, 
    private _apigestacard: APIGestaCardService,
    private previousRouteService: PreviousRouteService
  ) { }

  GetPreviousUrl() {
    return this.previousRouteService.getPreviousUrl(); 
  }

  SetAppToDoctor() {
    // this.authService.MudarUsuario('secretaria'); 

    // this.navigation = secretaria; 
    // this._fuseNavigationService.register('secretaria', this.navigation); 
    // this._fuseNavigationService.setCurrentNavigation('secretaria'); 
  }

  GetUserData() : Observable<Secretaria> {
    return Observable.create((observer) => {
      this.authService.LerComoObs().subscribe((res) => {
        this.usuario = res; 
        observer.next(res); 
      })
    })
  }

  GetConsultas() {
    return this._apigestacard.ListaConsultasMedico(this.usuario.email); 
  }

  GetConsultaPorCartao(id_cartao: string) {
    return this._apigestacard.ListaConsultasPorCartao(id_cartao);
  }

  GetListaSecretarias() {
    return this._apigestacard.PegarListaSecretarias();
  }

  GetListaMedicos() {
    return this._apigestacard.PegarListaMedicos();
  }

  AtualizarPerfilSecretaria(secretaria: Secretaria) : Promise<any> {
    return this._apigestacard.AtualizarSecretaria(secretaria);
  }

  AtualizarSenha(senha_atual: string, nova_senha: string, confirma_senha: string) : Promise<any> {
    return this.authService.UpdatePassword(senha_atual, nova_senha, confirma_senha);
  }

  AlterarEmailPaciente(pacienteId, email) {
    return this
  }

  GetListaEmailsSecretarias() : Observable<any> {
    return Observable.create((observer) => {
      this.GetListaSecretarias().subscribe((secretarias) => {
        var secretariasResumidos = secretarias.map((item:any) => {
          var obj = {
            email: item.email, 
            nome: `${item.primeiro_nome} ${item.sobrenome}`
          }; 
          return obj; 
        }); 
        observer.next(secretariasResumidos); 
        observer.complete(); 
      });
    });    
  }

  TotalCartoesUsadosPeloMedico(id_medico: string ) : Promise<any> {
    return this._apigestacard.TotalCartoesUsadosPorMedico(id_medico);
  }

  /** CRUD PACIENTES */

  CadastrarPaciente(paciente: Paciente) : Promise<any> {
    return this.authService.CreatePacient(paciente); 
  }

  CadastrarSecretaria(secretaria: Secretaria) : Promise<any> {
    return this.authService.CreateSecretary(secretaria); 
  }

  EmailResetPaciente(email_paciente) {
    return this.authService.EnviarEmailRecuperacaoDeSenha(email_paciente);
  }

  EmailResetSecretaria(email_secretaria) {
    return this.authService.EnviarEmailRecuperacaoDeSenha(email_secretaria);
  }

  AtualizarPaciente(paciente: Paciente) : Promise<any> {
    return this._apigestacard.AtualizarPaciente(paciente); 
  }

  DeletarPaciente(email_paciente: string, id?: string) : Promise<any> {
    return this._apigestacard.DeletarPaciente(email_paciente, id); 
  }

  GetListaPaciente(secretariaId: string) {
    return this._apigestacard.PegarListaPacientesPorSecretaria(secretariaId); 
  }

  GetListaPacientePage(secretariaId: string, page: number = 1, limit: number = 10, likeName: String = '', ativado: boolean = true, encerrado: boolean = null) {
    return this._apigestacard.PegarListaPacientesPorSecretariaPage(secretariaId, page, limit, likeName, ativado, encerrado); 
  }

  GetPacientePorEmail(email_paciente: string, id?: string) : Promise<Paciente> {
    return this._apigestacard.PegarPacientePorEmail(email_paciente); 
  }

  GetPacientePorId(id: string) : Promise<Paciente> {
    return this._apigestacard.PegarPacientePorIdCompletasso(id); 
  }

  GetMedicoPorId(id: string) : Promise<Medico> {
    return this._apigestacard.PegarUmMedicoPorId(id); 
  }

  GetSecretariaPorId(id: string) : Promise<Secretaria> {
    return this._apigestacard.PegarSecretariaPorId(id); 
  }

  AdicionarCartaoAoPaciente(email_paciente: string) {
    return this._apigestacard.AdicionarCartaoAPaciente(email_paciente, this.usuario.id); 
  }

  LerCartaoAtivoPaciente(email_paciente: string) : Promise<any> {
    return this._apigestacard.LerOCartaoAtivo(email_paciente);
  }

  LerUltimoCartao(email_paciente: string) : Promise<any> {
    return this._apigestacard.LerUltimoCartao(email_paciente);
  }

  LerUmCartao(cartao_id: string) {
    return this._apigestacard.LerUmCartaoGestante(cartao_id); 
  }

  AtivarUmCartao(medico_id: string, paciente_id: string, id_cartao: string) {
    return this._apigestacard.AtivarUmCartaoPeloMedico(medico_id, paciente_id, id_cartao); 
  }

  RegistrarEncerramento(id_cartao: string, encerramentoObj:object) {
    return this._apigestacard.RegistrarEncerramento(id_cartao, encerramentoObj); 
  }

  DesativarUmCartao(id_cartao: string) {
    return this._apigestacard.DesativarUmCartao(id_cartao); 
  }

  AtualizarCartao(cartao_id: string, cartao_atualizado: any) {
    return this._apigestacard.AtualizarCartao(cartao_id, cartao_atualizado); 
  }

  DeletarCartaoGestante(cartao_id: string) {
    return this._apigestacard.DeletarCartao(cartao_id); 
  }

  GetSomenteUmMedico(email: string) : Promise<any> {
    return this._apigestacard.PegarUmMedicoPorEmail(email); 
  }

  CriarConsulta(dados_consulta: any, email_medico: string, email_paciente: string, id_cartao: string) : Promise<any> {
    return this._apigestacard.CriarConsulta(email_paciente, email_medico, id_cartao, dados_consulta); 
  }

  AtualizarConsulta(dados_consulta: any, consulta_id: string) : Promise<any> {
    return this._apigestacard.AlterarConsulta(consulta_id, dados_consulta); 
  }

  ExcluirConsulta(consulta_id: string) : Promise<any> {
    return this._apigestacard.DeletarConsulta(consulta_id); 
  }

  ListarConsultasMedico(email_medico: string) : Promise<any> {
    return this._apigestacard.ListaConsultasMedico(email_medico); 
  }

  AtualizarSecretaria(secretaria: Secretaria) {
    return this._apigestacard.AtualizarSecretaria(secretaria); 
  }

  DeletarSecretaria(secretaria: Secretaria): Promise<Boolean> {
    return this._apigestacard.DeletarSecretaria(secretaria); 
  }

  BloquearSecretaria(secretaria: Secretaria) {

    secretaria.bloqueado = true;

    return this.AtualizarSecretaria(secretaria); 

  }

  DesbloquearSecretaria(secretaria: Secretaria) {

    secretaria.bloqueado = false;
    
    return this.AtualizarSecretaria(secretaria); 

  }
}
