import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomizeService {

    private _semanas_gestacionais = 40;
    /** Variaveis que o admin pode configurar */
    constructor() {
        // Faz a leitura delas no firebase
    }

    get semanas_gestacionais() { return this._semanas_gestacionais; }






}
