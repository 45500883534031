import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SecretariaService } from '../../secretaria.service';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { OverlayService } from 'app/shared/overlay.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { Medico } from 'app/core/interfaces/medico.interface';

@Component({
  selector: 'app-secretaria-cadastrar-paciente',
  templateUrl: './secretaria-cadastrar-paciente.component.html',
  styleUrls: ['./secretaria-cadastrar-paciente.component.scss']
})
export class SecretariaCadastrarPacienteComponent implements OnInit, AfterViewInit {

  form: FormGroup; 
  emails_medicos: Medico[] = [];

  bloqueado_por_limite_de_cartoes: boolean = false;

  loading: boolean = false; 

  @ViewChild('cadastro_paciente') componente_cadastro_paciente; 
  @ViewChild('imageUploader') file_uploader_component;

  constructor(
    private secretariaService: SecretariaService, 
    private snackService: AlertsService, 
    private router: Router, 
    private overlayService: OverlayService,
    private _apigestacard: APIGestaCardService
    ) { }

  ngOnInit() {
    this.secretariaService.GetListaMedicos().subscribe((res) => {
      this.emails_medicos = res; 
    });
  }

  ngOnChanges() {
    let form = this.componente_cadastro_paciente.form

    if (form.medico_app) {
      form.medico_app.valueChanges.subscribe((res) => {
        this.BuscarPerfilMedico(res);
      })
    }
  }

  ngAfterViewInit() {

    this.form = this.componente_cadastro_paciente.form; 

    this.componente_cadastro_paciente.enabledSearchEmail = true
    
    this.componente_cadastro_paciente.urlChangePaciente = '/secretaria-alterar-pacientes'

  }

  BuscarPerfilMedico(id: string) {

    if (!id) return;

    this.secretariaService.GetMedicoPorId(id).then((res) => {

      this.secretariaService.TotalCartoesUsadosPeloMedico(id).then((result) => {
        
        if (result >= res.maximo_cartoes) {
          
          this.bloqueado_por_limite_de_cartoes = true;

        }
        
      }).catch((err) => {

        console.log("SecretariaCadastrarPacienteComponent -> BuscarPerfilSecretaria -> err", err)
        
      });

    }).catch(err => {

      console.log('Error', err);

    })

  }

  SalvarPaciente() {

    if (this.form.valid) {

      this.loading = true;
      this.overlayService.show(); 

      var aux = this.form.value; 
      var paciente_criado = Paciente.create(aux); 

      this.secretariaService.CadastrarPaciente(paciente_criado).then(async (res) => {
        try {
          var imagem_aux = this.file_uploader_component.imagePath;

          if (imagem_aux) {
            var imagem = imagem_aux[0];
            var url_imagem = await this._apigestacard.UploadImage(imagem);

            var atualizando_perfil = await this._apigestacard.AtualizarUrlPerfil(res.id, url_imagem);
          }

          this.snackService.SnackMessage('Cadastro feito com sucesso!'); 

          this.router.navigate(['/secretaria']);
          
        } catch (error) {
          this.snackService.SnackMessage('Erro ao cadastrar paciente.'+(error.hasOwnProperty('message') ? ' '+error.message : '' ));
        }
      }).catch((err) => {
        
        this.snackService.SnackMessage('Erro ao cadastrar paciente.'+(err.hasOwnProperty('message') ? ' '+err.message : '' ));

      }).finally(() => {

        this.loading = false;

        this.overlayService.hide(); 

      })

    } else {

      this.snackService.SnackMessage('Formulário invalido'); 

    }
  }

}
