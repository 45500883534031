import { InserirUsgComponent } from './components/cartao-gestante/modals/inserir-usg/inserir-usg.component';
import { InserirLabComponent } from './components/cartao-gestante/modals/inserir-lab/inserir-lab.component';
import { InserirClinicoComponent } from './components/cartao-gestante/modals/inserir-clinico/inserir-clinico.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule, MatDialogModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatExpansionModule, MatRadioModule, MatDatepickerModule, MatCardModule, MatListModule, MatDividerModule, MatIconModule, MatProgressBarModule, MatProgressSpinnerModule, MatNativeDateModule, MatTableModule, MatCheckboxModule, MatGridListModule, MatSelectModule } from '@angular/material';
import { CartaoGestanteComponent } from './components/cartao-gestante/cartao-gestante.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { HistoricoClinicoComponent } from './components/cartao-gestante/historico-clinico/historico-clinico.component';
import { HistoricoFamiliarComponent } from './components/cartao-gestante/historico-familiar/historico-familiar.component';
import { AntecedentesObstetricosComponent } from './components/cartao-gestante/antecedentes-obstetricos/antecedentes-obstetricos.component';
import { SobreAGestacaoComponent } from './components/cartao-gestante/sobre-a-gestacao/sobre-a-gestacao.component';
import { VacinasComponent } from './components/cartao-gestante/vacinas/vacinas.component';
import { GanhoDePesoComponent } from './components/cartao-gestante/ganho-de-peso/ganho-de-peso.component';
import { AlturaUterinaComponent } from './components/cartao-gestante/altura-uterina/altura-uterina.component';
import { ExamesComponent } from './components/cartao-gestante/exames/exames.component';
import { ChartsModule } from 'ng2-charts';
import { PacienteInfoComponent } from './components/paciente-info/paciente-info.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { ListInfoComponent } from './components/paciente-info/list-info/list-info.component';
import { ItemConsultaComponent } from './components/calendario/components/item-consulta/item-consulta.component';
import { CalendarModule } from './components/calendario/calendar.module';
import { CadastrarPacienteComponent } from './components/cadastrar-paciente/cadastrar-paciente.component';
import { ItemUsuarioComponent } from './components/item-usuario/item-usuario.component';
import { ItemAtivacaoUsuarioComponent } from './components/item-ativacao-usuario/item-ativacao-usuario.component';
import { BoxesDashboardComponent } from './components/boxes-dashboard/boxes-dashboard.component';
import { CadastraMedicosComponent } from './components/cadastra-medicos/cadastra-medicos.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AgendarConsultaComponent } from './modals/agendar-consulta/agendar-consulta.component';
import { BotaoCartaoGestanteComponent } from './components/botao-cartao-gestante/botao-cartao-gestante.component';
import { EditarConsultaComponent } from './modals/editar-consulta/editar-consulta.component';
import { DadosConsultaComponent } from './components/dados-consulta/dados-consulta.component';
import { CadastraAdminComponent } from './components/cadastra-admin/cadastra-admin.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { SkeletonItemUsuarioComponent } from './components/skeleton-item-usuario/skeleton-item-usuario.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';

const maskConfig: Partial<IConfig> = {
    validation: false,
};

import { NgxSkltnModule, SkltnConfig } from 'ngx-skltn';
import { ExamesClinicosComponent } from './components/cartao-gestante/exames-clinicos/exames-clinicos.component';
import { UltrasonografiasComponent } from './components/cartao-gestante/ultrasonografias/ultrasonografias.component';
import { PipeModule } from 'app/core/pipes/pipes.module';
import { ResumoComponent } from './components/cartao-gestante/resumo/resumo.component';
import { InserirOutroComponent } from './components/cartao-gestante/modals/inserir-outro/inserir-outro.component';
import { OutrosExamesComponent } from './components/cartao-gestante/outros-exames/outros-exames.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ModalEdicaoExamesComponent } from './modals/modal-edicao-exames/modal-edicao-exames.component';
import { ModalEdicaoTrimestreComponent } from './modals/modal-edicao-trimestre/modal-edicao-trimestre.component';
import { BtWarningComponent } from './components/bt-warning/bt-warning.component';
import { DatepickermaskComponent } from './components/datepickermask/datepickermask.component';
import { CadastrarSecretariaComponent } from './components/cadastrar-secretaria/cadastrar-secretaria.component';
import { AlterarEmailComponent } from './modals/alterar-email/alterar-email.component';
import { ModalEdicaoSecretariaComponent } from './modals/modal-edicao-secretaria/modal-edicao-secretaria.component';
import { RouterModule } from '@angular/router';

const skltnConfig: SkltnConfig = {
    rectRadius: 10,
    flareWidth: '150px',
    bgFill: '#d8d5d1',
    flareFill: 'rgba(255,255,255, 0.5)',
};

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    HistoricoClinicoComponent,
    HistoricoFamiliarComponent,
    AntecedentesObstetricosComponent,
    SobreAGestacaoComponent,
    VacinasComponent,
    GanhoDePesoComponent,
    AlturaUterinaComponent,
    ExamesComponent,
    CartaoGestanteComponent,
    PacienteInfoComponent,
    PageContainerComponent, 
    ListInfoComponent, 
    ItemConsultaComponent, 
    CadastrarPacienteComponent, 
    CadastrarSecretariaComponent, 
    ItemUsuarioComponent, 
    ItemAtivacaoUsuarioComponent, 
    BoxesDashboardComponent, 
    CadastraMedicosComponent, 
    ConfirmModalComponent, 
    SpinnerComponent, 
    AgendarConsultaComponent, 
    BotaoCartaoGestanteComponent, 
    EditarConsultaComponent,
    DadosConsultaComponent, 
    CadastraAdminComponent, 
    SpinnerOverlayComponent,
    SkeletonItemUsuarioComponent,
    ExamesClinicosComponent,
    UltrasonografiasComponent, 
    InserirClinicoComponent, 
    InserirLabComponent, 
    InserirUsgComponent, 
    ResumoComponent, 
    InserirOutroComponent, 
    OutrosExamesComponent, 
    ImageUploaderComponent, 
    ModalEdicaoExamesComponent, 
    ModalEdicaoTrimestreComponent, 
    BtWarningComponent, 
    DatepickermaskComponent, 
    AlterarEmailComponent, 
    ModalEdicaoSecretariaComponent,
  ],

  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    NgxSkltnModule.forRoot(skltnConfig),
    NgxMaskModule.forRoot(maskConfig),
    PipeModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    ChartsModule,
    TextMaskModule,
    RouterModule
  ], 
  
  exports: [
    CartaoGestanteComponent, 
    ExpansionPanelComponent, 
    PacienteInfoComponent, 
    PageContainerComponent, 
    ListInfoComponent, 
    ItemConsultaComponent,
    CadastrarPacienteComponent,
    CadastrarSecretariaComponent, 
    ItemUsuarioComponent, 
    ItemAtivacaoUsuarioComponent,
    BoxesDashboardComponent, 
    CadastraMedicosComponent,
    ConfirmModalComponent,
    SpinnerComponent,
    BotaoCartaoGestanteComponent, 
    DadosConsultaComponent, 
    CadastraAdminComponent, 
    SkeletonItemUsuarioComponent, 
    ImageUploaderComponent, 
    BtWarningComponent, 
    DatepickermaskComponent, 
  ],

  entryComponents: [
    ConfirmModalComponent,
    AgendarConsultaComponent,
    EditarConsultaComponent,
    SpinnerOverlayComponent,
    InserirClinicoComponent,
    InserirLabComponent,
    InserirUsgComponent,
    InserirOutroComponent,
    ModalEdicaoExamesComponent,
    ModalEdicaoTrimestreComponent,
    AlterarEmailComponent,
    ModalEdicaoSecretariaComponent
  ]
})
export class SharedModule { }
