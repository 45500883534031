import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ConsultaPaciente } from 'app/core/interfaces/pacient-info.interface';
import { ChartDataSets, ChartOptions, Label } from 'chart.js';
import { au_default } from './padroes-au';

@Component({
  selector: 'app-altura-uterina',
  templateUrl: './altura-uterina.component.html',
  styleUrls: ['./altura-uterina.component.scss']
})
export class AlturaUterinaComponent implements OnInit {

  ready = false; 

  public showGraph = false; 

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public initialData = { '13': {}, '14': {}, '15': {}, '16': {}, '17': {}, '18': {}, '19': {}, '20': {}, '21': {}, '22': {}, '23': {}, '24': {}, '25': {}, '26': {}, '27': {}, '28': {}, '29': {}, '30': {}, '31': {}, '32': {}, '33': {}, '34': {}, '35': {}, '36': {}, '37': {}, '38': {}, '39': {}};
  public barChartLabels: any;
  public barChartType = 'line';
  public barChartLegend = true;
  public barChartData;
  // public barChartOptions: ChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true, 
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         min: 0, 
  //         max: 45, 
  //         stepSize: 5
  //       }
  //     }]
  //   }
  // };
  // public barChartLabels = [];
  // public barChartType = 'line';
  // public barChartLegend = true;
  // public barChartData: ChartDataSets = [];

  @Input('dados') dados; 

  constructor() {
  }

  ngOnInit() {

    this.updateCharData();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.updateCharData();

  }

  updateCharData() {

    this.barChartLabels = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];

    this.barChartData = [{ 
        data: [], 
        label: 'Altura uterina', 
        fill: false 
      }, {
        data: [13, 14.2, 15.3, 16.5, 17.6, 18.6, 19.6, 20.5, 21.4, 22.3, 23.2, 24.1, 25, 25.8, 26.5, 27.2, 28, 28.8, 29.4, 30.1, 30.8, 31.4, 32, 32.6, 33.2, 33.9, 34.5, 35], 
        label: 'P 90'
      }, {
        data: [7, 8.1, 9.3, 10.5, 11.6, 12.8, 13.9, 15, 16, 17, 17.9, 18.9, 19.8, 20.7, 21.6, 22.5, 23.3, 24.2, 25, 25.8, 26.5, 27.3, 28, 28.7, 29.3, 30, 30.5, 31], 
        label: 'P 10'
    }];
    
    this.dados = this.dados.filter(element => element.auca !== null && element.igdum !== null && element.igdum <= 287); // Filtra os dados para remover os nulos

    this.dados.sort((f, s) => { // Deixa os dados na ordem certa
      if (f.igdum < s.igdum) { return -1; } 
      else { return 1; }
    });

    let dadosPrepared = {};

    const primeiroElemento = Math.floor(this.dados[0].igdum / 7);
    const ultimoElemento = Math.floor(this.dados[this.dados.length - 1].igdum / 7);

    this.dados.forEach(item => {// Deixa os dados no formato X Y R para o grafico
      if (item.igdum >= 91) {
        dadosPrepared[`${Math.floor(item.igdum / 7)}`] = { x: Math.floor(item.igdum / 7), y: item.auca, r: 0 };
      }
    });

    this.barChartLabels = this.barChartLabels.filter(element => { // Removendo lados vazios no grafico
      return element >= primeiroElemento && element <= ultimoElemento;
    });

    this.barChartData = this.barChartData.map(element => {// Removendo lados vazios no grafico
      element.data.filter(element => {
        return element >= primeiroElemento &&  element <= ultimoElemento;
      });

      return element;
    });

    dadosPrepared = { ...this.initialData, ...dadosPrepared }; // Mergea os dados do paciente com os campos necessarios do grafico para evitar zeros no grafico em campos vazios

    Object.keys(dadosPrepared).forEach((element, index, array) => {
      if (dadosPrepared[`${element}`].y){
        this.barChartData[0].data.push(dadosPrepared[`${element}`]); // Migranto o grafico com os dados do paciente
      }
    });

    this.showGraph = true; 

  }
  
}
