import { Component, OnInit, Input, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from 'app/core/interfaces/usuario.interface';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-cadastrar-paciente',
    templateUrl: './cadastrar-paciente.component.html',
    styleUrls: ['./cadastrar-paciente.component.scss']
})
export class CadastrarPacienteComponent implements OnInit, DoCheck {

  @Input() cadastro_admin: boolean = false; 
  @Input() lista_medicos: Usuario[] = []; 

  @Input() edit_paciente = false;

  enabledSearchEmail = false;
  urlChangePaciente = '/medico-alterar-pacientes';

  form: FormGroup;
  emailOld: string = '';

  timeoutCheckEmail: any;

  userEmail: Usuario = null;
  
  constructor(
    private apigestacard: APIGestaCardService,
    private router: Router
  ) { 
    this.form = new FormGroup({
      primeiro_nome: new FormControl('', Validators.required), 
      sobrenome: new FormControl('', Validators.required), 
      idade: new FormControl('', Validators.required), 
      endereco: new FormControl('', Validators.required), 
      cidade: new FormControl('', Validators.required), 
      estado: new FormControl('', Validators.required),
      name_instagram: new FormControl(''),
      telefone: new FormControl('', Validators.required), 
      email: new FormControl('', [Validators.required, Validators.email]),
      whatsapp: new FormControl('', [
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(11),
          Validators.maxLength(11)
      ]),
      plano_de_saude: new FormControl(''), 
      qual_plano: new FormControl(''), 
      medico: new FormControl(''), 
      telefone_medico: new FormControl(''), 
      medico_app: new FormControl('')
    });

  }

  ngOnInit() {
    
    if (this.cadastro_admin == false) {

      this.form.get('medico_app').clearValidators();

    }
    
  }

  ngDoCheck(): void {

    if (!this.form.controls.email.invalid && this.enabledSearchEmail) {

      if (this.emailOld !== this.form.controls.email.value) {

        clearTimeout(this.timeoutCheckEmail);

        this.timeoutCheckEmail = setTimeout(() => {

          this.emailOld = this.form.controls.email.value;

          this.apigestacard.EmailExists(this.form.controls.email.value).then((res) => {

            this.form.controls.email.setErrors({ 'emailExists': true });

            this.apigestacard.PegarPacientePorEmail(this.form.controls.email.value).then((res) => {
              this.userEmail = res;
            }).catch((err) => {
              this.userEmail = null;
            }) 

          }).catch((err) => {

          }).finally(() => {

          });

        }, 1000);

      }

    }

  }

  LoadPaciente(): void {

    const navigationExtras: NavigationExtras = {
      state: {
        paciente: this.userEmail.email,
        id: this.userEmail.id
      }
    };

    this.router.navigate([this.urlChangePaciente], navigationExtras);

  }

  get primeiro_nome() { return this.form.controls.primeiro_nome; };
  get sobrenome() { return this.form.controls.sobrenome; };
  get idade() { return this.form.controls.idade; };
  get endereco() { return this.form.controls.endereco; };
  get cidade() { return this.form.controls.cidade; };
  get estado() { return this.form.controls.estado; };
  get telefone() { return this.form.controls.telefone; };
  get whatsapp() { return this.form.get('whatsapp'); }
  get name_instagram() { return this.form.get('name_instagram'); }
  get email() { return this.form.controls.email; };
  get plano_de_saude() { return this.form.controls.plano_de_saude; };
  get qual_plano() { return this.form.controls.qual_plano; };
  get medico() { return this.form.controls.medico; };
  get telefone_medico() { return this.form.controls.telefone_medico; };
  get medico_app() { return this.form.controls.medico_app; };

}
