import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/core/auth/auth.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { OverlayService } from 'app/shared/overlay.service';
import { error } from 'console';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    emailCheckForm: FormGroup;
    sendCodeForm: FormGroup;

    isLoading = false;
    visiblePassword = false;
    emailCheck: Boolean = false;
    initProcess = false;

    rememberMe = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private auth: AuthService,
        private router: Router,
        private sharedAlerts: AlertsService,
        private overlayService: OverlayService,
        private route: ActivatedRoute,
        private snackService: AlertsService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        var email = ``; 
        var senha = ``;

        this.router.url.split('?').forEach(async (item) => {
            const params = new URLSearchParams(item);

            if (params.has('key')) {
              const referer = params.has('referer') ? params.get('referer') : null;

              await this.auth.LoginByKey(params.get('key'), referer)
            } 
        })
          
       
        if (localStorage.getItem(`extranetLogin`)) {
            const recordedLogin = JSON.parse(localStorage.getItem(`extranetLogin`));

            email = recordedLogin.email; 
            senha = recordedLogin.senha;
        }

        this.loginForm = this._formBuilder.group({
            email: [email, [Validators.required, Validators.email]],
            password: [senha, Validators.required]
        });

        this.emailCheckForm = this._formBuilder.group({
            email: [email, [Validators.required, Validators.email]],
        })

        this.sendCodeForm = this._formBuilder.group({
            email: [email, [Validators.required, Validators.email]],
        });
    }

    ChecarEmail() {

        this.emailCheck = false;

        if (this.emailCheckForm.controls.email.valid) {

            this.overlayService.show();

            this.auth.ChecarUsuario(this.emailCheckForm.controls.email.value).then((needCode) => {

                if (needCode) {
                    this.auth.EnviarCodigo(this.emailCheckForm.controls.email.value).then(() => {

                        var navigationExtras: NavigationExtras = {
                            state: {
                                email: this.emailCheckForm.controls.email.value
                            }
                        }; 

                        this.sharedAlerts.SnackMessage(`Crie um novo login para acessar o aplicativo.`);
                        this.router.navigateByUrl(`/auth/alter-password`, navigationExtras);

                    }).catch(() => {

                        this.emailCheck = needCode;
                        this.initProcess = true;   

                    }).finally(() => {

                        this.overlayService.hide();

                    })
                } else {
                    this.sharedAlerts.SnackMessage(`Faça seu login.`);
                    
                    this.loginForm.controls.email.setValue(this.emailCheckForm.controls.email.value);

                    this.emailCheck = needCode;
                    this.initProcess = true;   
                
                    this.overlayService.hide();
                }

            }).catch((error) => {

                this.sharedAlerts.SnackMessage(error.message);
                this.emailCheck = false;
                this.initProcess = false;
                
                this.overlayService.hide();

            })

        }

    }

    EnviarCodigo() {
        this.overlayService.show();

        this.auth.EnviarCodigo(this.sendCodeForm.controls.email.value).then(() => {
            var navigationExtras: NavigationExtras = {
                state: {
                  email: this.sendCodeForm.controls.email.value
                }
            }; 

            this.sharedAlerts.SnackMessage(`Código enviado com sucesso!`);
            this.router.navigateByUrl(`/auth/alter-password`, navigationExtras);
        }).catch(() => {
            this.sharedAlerts.SnackMessage(`Erro ao enviar código!`);
        }).finally(() => {
            this.overlayService.hide();
        })
    }

    Login() {
        if (this.loginForm.valid || true) {
            this.overlayService.show();

            const obj = {
                email: this.loginForm.controls.email.value,
                senha: this.loginForm.controls.password.value
            };

            this.auth.Login(obj.email, obj.senha).then(() => {

                this.sharedAlerts.SnackMessage(`Login feito com sucesso!`);

                if(this.rememberMe) {
                    localStorage.setItem(`extranetLogin`, JSON.stringify(obj));
                } else {
                    localStorage.removeItem(`extranetLogin`);
                }

            }).catch((error) => {
    
                this.sharedAlerts.SnackMessage(error.message);

            }).finally(() => {

                this.overlayService.hide(); 

            })
        }
    }

    EsqueceuASenha() {
        const email = this.loginForm.controls.email.value;
        if (email && email != '') {
            const extras: NavigationExtras = {
                state: {
                    email
                }
            };
            this.router.navigate(['/auth/esqueceu-senha'], extras);

        } else {
            this.router.navigateByUrl('/auth/esqueceu-senha');
        }
    }

    async register() {
        this.auth.Login('', '').finally(() => {
            this.router.navigate(['/auth/signup']);
        })

        // window.open('https://gestacard.app/cadastre-se', "_blank", "closebuttoncaption=Cancelar,hidenavigationbuttons=yes,footer=yes,footercolor=#47b377,closebuttoncolor=#FFFFFF,location=no");

    }

    setVisiblePassword(visiblePassword: boolean) {
        this.visiblePassword = visiblePassword;
    }

}
