import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-cadastra-admin',
    templateUrl: './cadastra-admin.component.html',
    styleUrls: ['./cadastra-admin.component.scss']
})
export class CadastraAdminComponent implements OnInit {

    form: FormGroup = new FormGroup({
        primeiro_nome: new FormControl('', [Validators.required]),
        sobrenome: new FormControl('', [Validators.required]),
        idade: new FormControl('', [Validators.required]),
        endereco: new FormControl('', [Validators.required]),
        cidade: new FormControl('', [Validators.required]),
        estado: new FormControl('', [Validators.required]),
        telefone: new FormControl('', ),
        email: new FormControl('', [Validators.required, Validators.email])
    });

    // @Output() salvar = new EventEmitter(); 

    constructor() { }

    ngOnInit() {
    }

    get primeiro_nome() { return this.form.get('primeiro_nome'); }
    get sobrenome() { return this.form.get('sobrenome'); }
    get idade() { return this.form.get('idade'); }
    get endereco() { return this.form.get('endereco'); }
    get cidade() { return this.form.get('cidade'); }
    get estado() { return this.form.get('estado'); }
    get telefone() { return this.form.get('telefone'); }
    get email() { return this.form.get('email'); }

}
