import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseURL = environment.urlApi;

  constructor(private http: HttpClient) {}

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('accessToken');
    
    return new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    });
  }

  setAuthorization(token: string): void {
    localStorage.setItem('accessToken', token);
  }

  get(endpoint: string, params?: any): Observable<any> {
    let queryParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        queryParams = queryParams.set(key, params[key]);
      });
    }
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeaders(),
      params: queryParams
    });
  }

  post(endpoint: string, data: any): Observable<any> {
    return this.http.post(this.baseURL + endpoint, data, {
      headers: this.getHeaders()
    });
  }

  put(endpoint: string, data: any): Observable<any> {
    return this.http.put(this.baseURL + endpoint, data, {
      headers: this.getHeaders()
    });
  }

  delete(endpoint: string, id: string): Observable<any> {
    return this.http.delete(`${this.baseURL}${endpoint}/${id}`, {
      headers: this.getHeaders()
    });
  }
}
