import { Injectable, NgZone } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import { Router } from '@angular/router';
import { Paciente } from '../interfaces/pacient-info.interface';
import { Medico } from '../interfaces/medico.interface';
import { RolesManagerService } from '../guards/roles-manager.service';
import { BehaviorSubject } from 'rxjs';
import { Secretaria } from '../interfaces/secretaria.interface';
import { AlertsService } from 'app/shared/alerts.service';
import { APIGestaCardService } from '../api/gestacard.service';
import { Usuario } from '../interfaces/usuario.interface';
import { OverlayService } from 'app/shared/overlay.service';

interface User {
    uid: string;
    email: string;
    displayName?: string;
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
  /** Senha padrão  */
  default_password = "usuarioGesta123"; // 1#asDsaSD13%8&(.lwDSa
  admin_password = "admin1234";
  email: string;

  user: Observable<Usuario>;
  tipo_usuario: string;
  usuario: Usuario; 
  usuario_as_obs: BehaviorSubject<Usuario | Paciente>; 
  updateObservable: Function; 

  constructor(
    private auth: AngularFireAuth, 
    private router: Router, 
    private _apigestacard: APIGestaCardService, 
    private rolesManagerService: RolesManagerService, 
    private ngZone: NgZone,
    private snackService : AlertsService,
    private overlayService: OverlayService
  ) { 
    this.usuario_as_obs = new BehaviorSubject<Usuario>(Paciente.createEmpty()); 
    
    this.user = this._apigestacard.GetMeObs()

    this.LoginGetMe()

    window.my = window.my || {};
    window.my.namespace = window.my.namespace || {};
    window.my.namespace.PublicLogout = this.PublicLogout.bind(this);
  }

  LoginGetMe() {
    this._apigestacard.GetMeObs().subscribe(async usuario => {
      var permissao = usuario.tipo_usuario;

      if (permissao !== 'super') {

        this.usuario = usuario; 

        this.usuario_as_obs.next(usuario); 

        this.rolesManagerService.SetarAmbiente(permissao); 

      }
    }, () => {
      this.Logout()
    })
  }

  Login(email: string, senha: string): Promise<Usuario> {
    return new Promise(async (resolve, reject) => {
      try{
        localStorage.setItem(`medicoLogado`, 'sim')
        
        const dadosLogin = await this._apigestacard.signInWithEmailAndPassword(email, senha)

        if (this._apigestacard.isCodeType(dadosLogin)) {
          this.email = email

          this.snackService.SnackMessage('Digite o código de verificação')

          if (dadosLogin.needCode) this.router.navigateByUrl(`/auth/alter-password`)

          this.overlayService.hide(); 

        } else {
        
          var permissao = dadosLogin.data.permissao; 
          
          var dados_usuario = await this.BuscarDadosDoUsuario(email, permissao, dadosLogin.accessToken); 
          
          this.usuario = dados_usuario; 
          this.usuario_as_obs.next(dados_usuario); 
          
          var setando_ambiente = this.rolesManagerService.SetarAmbiente(permissao);

          if(setando_ambiente == 'sucesso')
            resolve(dados_usuario);
          else resolve(null); 
        } 

      } catch(err) {

        var aux = { message: err };
        
        reject(aux);

      }
    });
  }

  LoginByKey(accessToken: string, referer = null): Promise<void> {
    return new Promise(async () => {
      try {
        var usuario = await this._apigestacard.signInWithToken(accessToken);

        var permissao = usuario.tipo_usuario; 
        var dados_usuario = await this.BuscarDadosDoUsuario(usuario.email, permissao, accessToken); 

        this.usuario = dados_usuario; 
        this.usuario_as_obs.next(dados_usuario); 
        this.rolesManagerService.SetarAmbiente(permissao); 

        if (!referer) {
          referer = '/paciente'
          if (permissao == 'medico') referer = '/medico'
          if (permissao == 'secretaria') referer = '/secretaria'
        }

        this.router.navigateByUrl(referer);

      } catch (error) {
        this.snackService.SnackMessage('Erro ao logar com token')
        this.Logout();
      }
    });
  }

  Logout(redirect = true) {
    this.usuario = null; 
    this.usuario_as_obs.next(null);
    localStorage.removeItem('medicoLogado')
    localStorage.removeItem('secretariaHash')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    if (redirect) this.router.navigateByUrl('/auth/login')
  }

  VerificarCodigo(codigo: string): Promise<Boolean> {

    return this._apigestacard.VerificarCodigo(this.email, codigo);
  
  }

  ChecarUsuario(email: string): Promise<Boolean> {
    return this._apigestacard.ChecarUsuario(email);
  }

  EnviarCodigo(email: string): Promise<Boolean> {
    return this._apigestacard.EnviarCodigo(email);
  }

  ChangePassword(email: string, codigo: string, senha: string): Promise<Boolean> {

    return this._apigestacard.AtualizaSenha(email, codigo, senha);

  }

  PublicLogout() {
    this.ngZone.run(() => this.Logout()); 
  }

  Register() {
    this.auth.user.subscribe((res) => {
       
    })
  }

  EnviarEmailRecuperacaoDeSenha(email: string) : Promise<any> {
    return this._apigestacard.sendPasswordResetEmail(email);
  }

  CreateDoctor(usuario: Medico) {

    return new Promise(async (resolve, reject) => {

      try {

        var medico = await this._apigestacard.CadastrarMedico(usuario); 

        resolve(medico); 

      } catch (error) {

        reject(error); 

      }

    });

  }

  CreatePacient(usuario: Paciente) {
    return new Promise(async (resolve, reject) => {

      try {

        var paciente = await this._apigestacard.CadastrarPaciente(usuario); 

        resolve(paciente);

      } catch(err) {

        reject(err); 

      }

    });
  }

  CreateSecretary(usuario: Secretaria) {

    return new Promise(async (resolve, reject) => {

      try {

        const secretaria = await this._apigestacard.CadastrarSecretaria(usuario)

        resolve(secretaria)

      } catch (error) {

        reject(error); 

      }

    });

  }

  AuthState() : Observable<any> {
    return this.auth.authState;
  }

  GetUserInfo() {
    return this.usuario;
  }

  // Essa é a função usada pelo app 
  LerComoObs() : BehaviorSubject<Usuario | Paciente> {
    return this.usuario_as_obs; 
  }

  hasValue(value: any) {
    return true;
  }

  GetValueOfUser() {
    
    return this.usuario_as_obs.pipe().toPromise(); 
  }

  BuscarDadosDoUsuario(email: string, tipo_usuario: string, token?: string) : Promise<Usuario> {
    switch(tipo_usuario) {
      case 'medico':
        return this._apigestacard.PegarUmMedicoPorEmail(email, token); 
      case 'secretaria':
        return this._apigestacard.PegarUmaSecretariaPorEmail(email, token); 
      case 'paciente': 
        return this._apigestacard.PegarPacientePorEmail(email, token); 
    }
  }

  async AtualizarDadosDoUsuario(email, permissao) {
      const dados_usuario = await this.BuscarDadosDoUsuario(email, permissao);
      this.usuario = dados_usuario;
      this.usuario_as_obs.next(dados_usuario);
      return dados_usuario;
  }


  DeletarMedico(medico: Medico) : Promise<Boolean> {
    return this._apigestacard.DeletarMedico(medico); 
  }

  DeletarPaciente(paciente: Paciente): Promise<Boolean> {
    return this._apigestacard.DeletarPaciente(paciente.email, paciente.id); 
  }

  UpdatePassword(senhaAtual: string, novaSenha: string, novaSenhaConfirmacao:string) : Promise<any> {

    return new Promise(async (resolve, reject) => {

      try {

        let usuario = await this._apigestacard.AlterarSenha(senhaAtual, novaSenha, novaSenhaConfirmacao);
        
        setTimeout(() => {
          this.Login(usuario.email, novaSenha); 

          resolve('atualizado com sucesso'); 
        }, 1000);

      } catch (err) {
        reject(err.error); 
      }

    });

  }

  UpdateEmail(newEmail: string, password: string) {
    return new Promise(async (resolve, reject) => {
      try {
        var usuario = await this._apigestacard.AlterarEmailDeUsuario(newEmail, password); 
        
        resolve('deu certo');  
      } catch (error) {
        reject(error);
      }
    });
  }

  ConsultarPermissao() : Observable<string> {
    return this._apigestacard.ConsultarPermissoAsObservable(); 
  }
}
