import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-antecedentes-obstetricos',
    templateUrl: './antecedentes-obstetricos.component.html',
    styleUrls: ['./../cartao-gestante.component.scss']
})
export class AntecedentesObstetricosComponent implements OnInit {

    form: FormGroup = new FormGroup({
        g: new FormControl(''),
        p: new FormControl(''),
        pc: new FormControl(''),
        pn: new FormControl(''),
        a: new FormControl(''),
        complicacoes: new FormControl('')
    });

    partosTotal: number;

    constructor() { }

    ngOnInit() {

        this.calcPartos();

    }

    calcPartos() {

        this.partosTotal = this.form.controls.pn.value + this.form.controls.pc.value;

    }

}
