// Deploy Gestacard

/**
 * Uso da api local
 */
// export const environment = {
//     production: false,
//     hmr       : false,
//     firebaseConfig:  {
//         apiKey: "AIzaSyAKn9sUtbyPo9sq0PlHLuhm6FZQJdPZMVk",
//         authDomain: "app-gestante.firebaseapp.com",
//         databaseURL: "https://app-gestante.firebaseio.com",
//         projectId: "app-gestante",
//         storageBucket: "app-gestante.appspot.com",
//         messagingSenderId: "242632450982",
//         appId: "1:242632450982:web:e7618979124aefb0de2567"
//     },

//     email: 'contato@2rs.com.br',
//     senha: 'RS2021ti@',
//     urlAtual: 'http://localhost:4200',
//     urlApi: 'http://localhost:3000'
// };

/**
 * Uso da api de homologação na vercel
 */
export const environment = {
  production: false,
  hmr       : false,
  firebaseConfig:  {
    apiKey: "AIzaSyAKn9sUtbyPo9sq0PlHLuhm6FZQJdPZMVk",
    authDomain: "app-gestante.firebaseapp.com",
    databaseURL: "https://app-gestante.firebaseio.com",
    projectId: "app-gestante",
    storageBucket: "app-gestante.appspot.com",
    messagingSenderId: "242632450982",
    appId: "1:242632450982:web:e7618979124aefb0de2567"
  },

  email: 'contato@2rs.com.br',
  senha: 'RS2021ti@',
  urlAtual: 'https://dev.gestacard.app/',
  urlApi: 'https://gestacard-dev.vercel.app'
};
