import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { Observable } from 'rxjs';
import { Medico } from 'app/core/interfaces/medico.interface';
import { PreviousRouteService } from 'app/shared/previous-route.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { Usuario } from 'app/core/interfaces/usuario.interface';
import { Secretaria } from 'app/core/interfaces/secretaria.interface';
import { Pagination } from 'app/core/interfaces/pagination.interface';


@Injectable({
  providedIn: 'root'
})
export class MedicoService {

  usuario: any;
  navigation: any;
  constructor(
    private authService: AuthService,
    private _apigestacard: APIGestaCardService,
    private previousRouteService: PreviousRouteService

  ) { }

  GetPreviousUrl() {
    return this.previousRouteService.getPreviousUrl();
  }

  SetAppToDoctor() {
    // this.authService.MudarUsuario('medico'); 

    // this.navigation = medico; 
    // this._fuseNavigationService.register('medico', this.navigation); 
    // this._fuseNavigationService.setCurrentNavigation('medico'); 
  }

  GetUserData(): Observable<any> {
    return Observable.create((observer) => {
      this.authService.LerComoObs().subscribe((res) => {
        this.usuario = res;
        observer.next(res);
      });
    });
  }

  GetInfoMedico(id: string) {
    return this._apigestacard.PegarUmMedicoPorId(id);
  }

  GetConsultas() {
    return this._apigestacard.ListaConsultasMedico(this.usuario.email);
  }

  GetConsultaPorCartao(id_cartao: string) {
    return this._apigestacard.ListaConsultasPorCartao(id_cartao);
  }

  GetListaMedicos() {
    return this._apigestacard.PegarListaMedicos();
  }

  GetListaSecretarias() {
    return this._apigestacard.PegarListaSecretarias();
  }

  AtualizarPerfilMedico(medico: Medico): Promise<any> {
    return this._apigestacard.AtualizarMedico(medico);
  }

  AtualizarSenha(senha_atual: string, nova_senha: string): Promise<any> {
    return this.authService.UpdatePassword(this.usuario.email, senha_atual, nova_senha);
  }

  AlterarEmailPaciente(pacienteId, email) {
    return this;
  }

  GetListaEmailsMedicos(): Observable<any> {
    return Observable.create((observer) => {
      this.GetListaMedicos().subscribe((medicos) => {
        const medicosResumidos = medicos.map((item: any) => {
          const obj = {
            email: item.email,
            nome: `${item.primeiro_nome} ${item.sobrenome}`
          };
          return obj;
        });
        observer.next(medicosResumidos);
        observer.complete();
      });
    });
  }

  TotalCartoesUsadosPeloMedico(id_medico: string): Promise<any> {
    return this._apigestacard.TotalCartoesUsadosPorMedico(id_medico);
  }

  /** CRUD SECRETARIAS */
  CadastrarSecretaria(secretaria: Secretaria): Promise<any> {
    return this.authService.CreateSecretary(secretaria);
  }

  EmailResetSecretaria(email_secretaria) {
    return this.authService.EnviarEmailRecuperacaoDeSenha(email_secretaria);
  }

  DeletarSecretaria(secretaria: Secretaria): Promise<any> {
    return this._apigestacard.DeletarSecretaria(secretaria);
  }

  AtualizarSecretaria(secretaria: Secretaria): Promise<any> {
    return this._apigestacard.AtualizarSecretaria(secretaria);
  }

  GetSecretariaPorId(id: string): Promise<Secretaria> {
    return this._apigestacard.PegarSecretariaPorId(id);
  }

  BloquearSecretaria(secretaria: Secretaria): Promise<Secretaria> {
    secretaria.bloqueado = true;

    return this._apigestacard.AtualizarSecretaria(secretaria);
  }

  DesbloquearSecretaria(secretaria: Secretaria): Promise<Secretaria> {
    secretaria.bloqueado = false;

    return this._apigestacard.AtualizarSecretaria(secretaria);
  }

  /** CRUD PACIENTES */
  CadastrarPaciente(paciente): Promise<any> {
    return this.authService.CreatePacient(paciente);
  }

  EmailResetPaciente(email_paciente) {
    return this.authService.EnviarEmailRecuperacaoDeSenha(email_paciente);
  }

  AtualizarPaciente(paciente: Paciente): Promise<any> {
    return this._apigestacard.AtualizarPaciente(paciente);
  }

  DeletarPaciente(email_paciente: string, id?: string): Promise<any> {
    return this._apigestacard.DeletarPaciente(email_paciente, id);
  }

  GetListaPaciente(medicoId: string) {
    return this._apigestacard.PegarListaPacientesDeUmMedico(medicoId);
  }

  GetListaPacientePage(medicoId: string, page: number = 1, limit: number = 12, likeName: String = '', ativado: boolean = true, encerrado: boolean = null): Observable<Pagination<Paciente>> {
    return this._apigestacard.PegarListaPacientesDeUmMedicoPage(medicoId, page, limit, likeName, ativado, encerrado);
  }

  GetPacientePorEmail(email_paciente: string, id?: string): Promise<Paciente> {
    return this._apigestacard.PegarPacientePorEmail(email_paciente, id);
  }

  GetPacientePorId(id: string): Promise<Paciente> {
    return this._apigestacard.PegarPacientePorIdCompletasso(id);
  }

  AdicionarCartaoAoPaciente(email_paciente: string) {
    return this._apigestacard.AdicionarCartaoAPaciente(email_paciente, this.usuario.id);
  }

  LerCartaoAtivoPaciente(email_paciente: string): Promise<any> {
    return this._apigestacard.LerOCartaoAtivo(email_paciente);
  }

  LerUltimoCartao(email_paciente: string): Promise<any> {
    return this._apigestacard.LerUltimoCartao(email_paciente);
  }

  LerUmCartao(cartao_id: string) {
    return this._apigestacard.LerUmCartaoGestante(cartao_id);
  }

  AtivarUmCartao(medico_id: string, paciente_id: string, id_cartao: string) {
    return this._apigestacard.AtivarUmCartaoPeloMedico(medico_id, paciente_id, id_cartao);
  }

  RegistrarEncerramento(id_cartao: string, encerramentoObj: object) {
    return this._apigestacard.RegistrarEncerramento(id_cartao, encerramentoObj);
  }

  DesativarUmCartao(id_cartao: string) {
    return this._apigestacard.DesativarUmCartao(id_cartao);
  }

  AtualizarCartao(cartao_id: string, cartao_atualizado: any) {
    return this._apigestacard.AtualizarCartao(cartao_id, cartao_atualizado);
  }

  DeletarCartaoGestante(cartao_id: string) {
    return this._apigestacard.DeletarCartao(cartao_id);
  }

  GetSomenteUmMedico(email: string): Promise<any> {
    return this._apigestacard.PegarUmMedicoPorEmail(email);
  }

  CriarConsulta(dados_consulta: any, email_medico: string, email_paciente: string, id_cartao: string): Promise<any> {
    return this._apigestacard.CriarConsulta(email_paciente, email_medico, id_cartao, dados_consulta);
    // var consulta: ConsultaPaciente = {
    //   date: data, 
    //   id_medico: email_medico, 
    //   id_paciente: email_paciente
    // };
    // return this._apigestacard.CriarNovaConsulta(consulta, consulta.id_medico, consulta.id_paciente); 
  }

  AtualizarConsulta(dados_consulta: any, consulta_id: string): Promise<any> {
    return this._apigestacard.AlterarConsulta(consulta_id, dados_consulta);
  }

  ExcluirConsulta(consulta_id: string): Promise<any> {
    return this._apigestacard.DeletarConsulta(consulta_id);
  }

  ListarConsultasMedico(email_medico: string): Promise<any> {
    return this._apigestacard.ListaConsultasMedico(email_medico);
  }
}
