import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { AgendarConsultaComponent } from './modals/agendar-consulta/agendar-consulta.component';
import { EditarConsultaComponent } from './modals/editar-consulta/editar-consulta.component';
import { ModalEdicaoExamesComponent } from './modals/modal-edicao-exames/modal-edicao-exames.component';
import { ModalEdicaoTrimestreComponent } from './modals/modal-edicao-trimestre/modal-edicao-trimestre.component';
import { AlterarEmailComponent } from './modals/alterar-email/alterar-email.component';

@Injectable({
    providedIn: 'root'
})
export class ModalsService {

    constructor(private matDialog: MatDialog) { }

    AbrirModalDeConfirmacao(titulo: string, conteudo: string, width: string = '400px'): Observable<boolean> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(ConfirmModalComponent, {
                width: width,
                data: {
                    titulo,
                    conteudo
                }
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res ? true : false);
                observer.complete();
            });
        });
    }

    AbrirModalAlterarEmail(email?: string): Observable<string> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(AlterarEmailComponent, {
                width: '400px',
                data: {
                    email: email
                }
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    AgendarConsultaModal(data: any, width: string = '800px'): Observable<any> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(AgendarConsultaComponent, {
                width: '800px',
                data
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    AbrirPainelConsulta(data): Observable<any> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(EditarConsultaComponent, {
                width: '800px',
                data
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    ConfirmarDesativarCartao(): Observable<boolean> {
        return this.AbrirModalDeConfirmacao('Deseja desativar o cartão?', 'Uma vez desativado esse cartão não poderá ser ativado novamente! Deseja continuar mesmo assim?');
    }

    ConfirmarSalvarCartaoAlteracoes(): Observable<boolean> {
        return this.AbrirModalDeConfirmacao('Antes de inserir o exame deseja salvar as alterações do cartão?', 'Se incluir um exame sem salvar as alterações, ela será perdida.');
    }

    AbrirModalResultadoExame(exameName): Observable<boolean> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(ModalEdicaoExamesComponent, {
                width: '500px',
                data: { nameExame: exameName }
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    AbrirModalDataTrimestre(dataTrimestre?): Observable<boolean> {
        return Observable.create((observer) => {
            const dialogRef = this.matDialog.open(ModalEdicaoTrimestreComponent, {
                width: '500px',
                data: {
                    dataInput: dataTrimestre
                }
            });

            dialogRef.afterClosed().subscribe((res) => {
                observer.next(res);
                observer.complete();
            });
        });
    }
}
