import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

    @Input('titulo') titulo_do_card: string = null;
    @Input('descricao') descricao_do_card: string = null;
    @Input('icone') icone_do_card: string = null;

    @Input() expanded = true;

    @Output() opened: EventEmitter<any> = new EventEmitter();
    @Output() closed: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {

    }

    fireOpen() {
        this.opened.emit(null);
    }

    fireClose() {
        this.closed.emit(null);
    }

}
