import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';
import { AuthService } from 'app/core/auth/auth.service';
import { AlertsService } from 'app/shared/alerts.service';
import { Router } from '@angular/router';
import { PacienteService } from '../../paciente.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';

@Component({
    selector: 'app-editar-perfil',
    templateUrl: './editar-perfil.component.html',
    styleUrls: ['./editar-perfil.component.scss']
})
export class EditarPerfilComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  perfil: Paciente;
  loading = false;
  outras_opcoes = false;

  /** Formulário de atualizar senha */
  atualizarSenha: FormGroup = new FormGroup({
    senha_atual: new FormControl('', Validators.required),
    nova_senha: new FormControl('', Validators.required),
    confirmar_senha: new FormControl('', Validators.required),
  });

  @ViewChild('imageUploader') image_uploader;

  senhas_nao_iguais = false;
  visiblePassword = false;

  /** Formulário para atualizar o email */
  atualizarEmail: FormGroup = new FormGroup({
    novo_email: new FormControl('', Validators.required),
    confirmar_email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  email_nao_iguais = false;

  @ViewChild('form_paciente') form_paciente;


  constructor(
      private authService: AuthService,
      private snackService: AlertsService,
      private router: Router,
      private pacienteService: PacienteService,
      private _apigestacard: APIGestaCardService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

    this.form = this.form_paciente.form;

    this.BuscarDadosPaciente();

  }

  BuscarDadosPaciente() {

    this.pacienteService.GetUserInfo().then((res) => {

      this.perfil = res;

      this.form.patchValue(res);
      this.form.controls.email.disable();

    });

  }

  setVisiblePassword(visiblePassword: boolean) {
    this.visiblePassword = visiblePassword;
  }

  async Alterar() {
    
    this.loading = true;

    if (this.form.valid) {

      const paciente = { ...this.perfil, ...this.form.getRawValue() };

      if (this.image_uploader.foto_mudou) {

        const imagem = this.image_uploader.imagePath;
        const image_to_upload = imagem[0];

        const url = await this._apigestacard.UploadImage(image_to_upload);

        paciente['url_paciente'] = url;

      }

      this.pacienteService.UpdateProfile(paciente).then(() => {

        this.snackService.SnackMessage('Perfil atualizado com sucesso!');
        this.router.navigate(['/paciente']);

      }).catch((err) => {
        
        this.snackService.SnackMessage('Erro ao salvar mudancas');

      });

    }

  }

  Cancelar() {
    this.router.navigate(['/paciente']);
  }

  AlterarSenha() {

    if (this.atualizarSenha.valid) {

      const senha_atual = this.atualizarSenha.controls.senha_atual.value;
      const nova_senha = this.atualizarSenha.controls.nova_senha.value;
      const confirmar_senha = this.atualizarSenha.controls.confirmar_senha.value;

      // Verificando as senhas
      if (confirmar_senha != nova_senha) {
        
        this.senhas_nao_iguais = true;

      } else {
        
        this.senhas_nao_iguais = false;
        this.authService.UpdatePassword(this.perfil.email, senha_atual, nova_senha).then(() => {
          
          this.snackService.SnackMessage('Senha alterada com sucesso!');

        }).catch((err) => {

          this.snackService.SnackMessage(`Erro ao atualizar senha: ${JSON.stringify(err)}`);

        });

      }

    }

  }

  AlterarEmail() {

    if (this.atualizarEmail.valid) {

      const novo_email = this.atualizarEmail.controls.novo_email.value;
      const confirmar_email = this.atualizarEmail.controls.confirmar_email.value;
      const password = this.atualizarEmail.controls.password.value; 

      if (novo_email != confirmar_email) {

        this.email_nao_iguais = true;

      } else {

        this.email_nao_iguais = false;

        this.authService.UpdateEmail(novo_email, password).then((result) => {
          
          this.snackService.SnackMessage('E-mail atualizado com sucesso!, Faça o login novamente');

          setTimeout(() => this.authService.Logout(), 1000);

        }).catch((err) => {

          this.snackService.SnackMessage(err);

        });

      }

    }

  }

}
