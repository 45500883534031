import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-inserir-clinico',
  templateUrl: './inserir-clinico.component.html',
  styleUrls: ['./../modals-inserir.scss']
})
export class InserirClinicoComponent implements OnInit {

  hide_day_form = true;
  dataConsulta: FormGroup = new FormGroup({
    data: new FormControl(new Date()),
    hora: new FormControl('')
  });

  dadosConsulta: FormGroup = new FormGroup({
    igdum_semanas: new FormControl(''),
    igdum_dias: new FormControl(''),
    igusg_semanas: new FormControl(''),
    igusg_dias: new FormControl(''),
    peso: new FormControl(0),
    pa: new FormControl(''),
    auca: new FormControl(0),
    apres: new FormControl(''),
    bcf: new FormControl(''),
    mov_fetal: new FormControl('presente'),
    edema: new FormControl(''),
    diag: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InserirClinicoComponent>
  ) { }

  ngOnInit() {

    this.dadosConsulta.patchValue({
      igdum_semanas: Math.floor(this.data.igdum / 7),
      igdum_dias: this.data.igdum % 7,
      igusg_semanas: Math.floor(this.data.igusg / 7),
      igusg_dias: this.data.igusg % 7,
      peso: this.data.peso ? this.data.peso : '',
      pa: this.data.pa ? this.data.pa : '',
      auca: this.data.auca ? this.data.auca : '',
      apres: this.data.apres ? this.data.apres : '',
      bcf: this.data.bcf ? this.data.bcf : '',
      mov_fetal: this.data.mov_fetal ? this.data.mov_fetal : '',
      edema: this.data.edema ? this.data.edema : '',
      diag: this.data.diag ? this.data.diag : '',
    })

  }

  get data_form() {
    return this.dataConsulta.controls.data.value;
  }

  Salvar() {

    var dados_consulta = {
      igdum: (this.dadosConsulta.controls.igdum_semanas.value * 7 + this.dadosConsulta.controls.igdum_dias.value),
      igusg: (this.dadosConsulta.controls.igusg_semanas.value * 7 + this.dadosConsulta.controls.igusg_dias.value),
      peso: this.dadosConsulta.controls.peso.value,
      pa: this.dadosConsulta.controls.pa.value,
      auca: this.dadosConsulta.controls.auca.value,
      apres: this.dadosConsulta.controls.apres.value,
      bcf: this.dadosConsulta.controls.bcf.value,
      mov_fetal: this.dadosConsulta.controls.mov_fetal.value,
      edema: this.dadosConsulta.controls.edema.value,
      diag: this.dadosConsulta.controls.diag.value,
    }

    this.dialogRef.close({ ...this.dataConsulta.value, ...dados_consulta });

  }

}
