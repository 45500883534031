import { Component, ViewEncapsulation, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { PacienteService } from '../../paciente.service';
import { Router } from '@angular/router';
import { Paciente, CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { FormGroup } from '@angular/forms';
import { Events } from 'app/core/events';

@Component({
  selector: 'app-cartao-info',
  templateUrl: './cartao-info.component.html',
  styleUrls: ['./cartao-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartaoInfoComponent implements OnInit, AfterViewInit {

  usuario: any;

  pacient_info: Paciente;
  cartao_gestante: CartaoGestante;

  loading = true;

  state: any;

  form: FormGroup;
  @ViewChild('cartao_form') formulario_cartao_gestante: any;

  dados_graficos_prontos = false;
  dados_dos_graficos: any = [];

  constructor(
    private router: Router,
    private pacienteService: PacienteService,
    private events: Events
  ) {

    this.state = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state : null;

  }

  Voltar() {
    this.router.navigateByUrl('/paciente');
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    if (this.state && this.state.paciente) {
      this.pacient_info = this.state.paciente;
    }

    if (this.state && this.state.cartao) {
      this.cartao_gestante = this.state.cartao;
    }

    // Buscando os dados do paciente e o cartao ativado 
    if (this.pacient_info == null) {

      this.pacienteService.GetUserInfo().then((paciente_info: Paciente) => {

        this.pacient_info = paciente_info;

        if (this.cartao_gestante == null) {

          this.pacienteService.GetCartaoAtivado().then((cartao_ativado: CartaoGestante) => {

            this.cartao_gestante = cartao_ativado;
            
            this.loading = false;
            this.AtualizarFormulario();

          });

        } else {

          this.AtualizarFormulario();
          this.loading = false;

        }

      });

    } else {

      this.AtualizarFormulario();
      this.loading = false;

    }
  }

  AtualizarFormulario() {

    this.form = this.formulario_cartao_gestante.cartao_gestante;

    this.setFormValue(this.form.controls.historico_clinico, this.cartao_gestante ? this.cartao_gestante.historico_clinico : null);
    this.setFormValue(this.form.controls.historico_familiar, this.cartao_gestante ? this.cartao_gestante.historico_familiar : null);

    this.setFormValue(this.form.controls.antecedentes_obstetricos, this.cartao_gestante ? this.cartao_gestante.antecedentes_obstetricos : null);
    this.setFormValue(this.form.controls.sobre_a_gestacao, this.cartao_gestante ? this.cartao_gestante.sobre_a_gestacao : null);
    this.setFormValue(this.form.controls.vacinas, this.cartao_gestante ? this.cartao_gestante.vacinas : null);

    this.form.disable();

    if (this.cartao_gestante && this.cartao_gestante.hasOwnProperty('exames') && this.cartao_gestante.exames.hasOwnProperty('clinicos')) {
      this.dados_dos_graficos = this.cartao_gestante.exames.clinicos;
      this.dados_graficos_prontos = true;
    }

    this.events.publish('CartaoGestanteDataLoad');

  }

  /**
   * Seta o form value a partir do novo layout do form, para nao dar erro com mudanças de estrutura no firebase
   * @param form 
   * @param obj 
   */

  setFormValue(form, obj) {

    if (obj == null) { return; }

    const layout = form.value;
    for (const z in layout) {
      if (obj.hasOwnProperty(z)) { layout[z] = obj[z]; }
    }

    form.patchValue(layout);

  }

  Imprimir() {
    window.print();
  }

}
