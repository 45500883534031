import { Component, OnInit, Input } from '@angular/core';
import { Medico } from 'app/core/interfaces/medico.interface';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';

@Component({
    selector: 'app-list-info',
    templateUrl: './list-info.component.html',
    styleUrls: ['./list-info.component.scss']
})
export class ListInfoComponent implements OnInit {

    @Input('info') info: Paciente;
    @Input('text') align_text = 'left';
    @Input('medico') medico: Medico;

    constructor() { }

    ngOnInit() {
    }

    getLinkInstagram(user) {
        return `https://instagram.com/${user.replace('@', '')}`;
    }

}
