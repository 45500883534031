import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-historico-familiar',
    templateUrl: './historico-familiar.component.html',
    styleUrls: ['./../cartao-gestante.component.scss']
})
export class HistoricoFamiliarComponent implements OnInit {

    form: FormGroup = new FormGroup({
        hipertensao: new FormControl(''),
        diabetes: new FormControl(''),
        eplepsia: new FormControl(''),
        malformacao: new FormControl(''),
        gemelaridade: new FormControl(''),
        outros: new FormControl('')
    });

    constructor() { }

    ngOnInit() {
    }
}
