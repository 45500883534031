import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'peopleFilter',
})
export class PeopleFilterPipe implements PipeTransform {
    transform(items: any[], value: string, exibir?: string): any {
        if (!items) { return []; }
        return items.filter(it => {


            const searchValue = (value.trim() && value.trim().length > 0) ? (`${it.primeiro_nome} ${it.sobrenome}`).toLowerCase().indexOf(value.toLowerCase()) != -1 : true;

            let searchExibir = true;

            if (!it.ultimo_cartao) { return exibir ? false : searchValue; }

            if (exibir != undefined && exibir !== '' && it.ultimo_cartao !== undefined) {
                switch (exibir) {
                    case 'A':
                        searchExibir = it.ultimo_cartao.ativado == true;
                        break;
                    case 'E':
                        searchExibir = it.ultimo_cartao.encerrado !== undefined && it.ultimo_cartao.encerrado == true;
                        break;
                    case 'D':
                        searchExibir = it.ultimo_cartao.ativado == false;
                        break;
                }
            }

            return searchValue && searchExibir;

        });
    }
}
