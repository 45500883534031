import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatButtonModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatTooltipModule, MatCardModule } from '@angular/material';

import { SecretariaRoutingModule } from './secretaria-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { CalendarModule } from 'app/shared/components/calendario/calendar.module';

import { PipeModule } from 'app/core/pipes/pipes.module';
import { CoreModule } from 'app/core/core.module';

import { ModalAdicionarExameComponent } from './components/modal-adicionar-exame/modal-adicionar-exame.component';
import { ModalConsultasDiariaComponent } from './components/modal-consultas-diaria/modal-consultas-diaria.component';
import { SecretariaAlterarPacienteComponent } from './subpages/secretaria-alterar-paciente/secretaria-alterar-paciente.component';
import { SecretariaPacientesComponent } from './subpages/secretaria-pacientes/secretaria-pacientes.component';
import { SecretariaCadastrarPacienteComponent } from './subpages/secretaria-cadastrar-paciente/secretaria-cadastrar-paciente.component';
import { SecretariaEditarPerfilComponent } from './subpages/secretaria-editar-perfil/secretaria-editar-perfil.component';

import { RbFabSpeedDialModule } from 'rb-fab-speed-dial';

@NgModule({
  declarations: [
    SecretariaPacientesComponent, 
    SecretariaCadastrarPacienteComponent, 
    SecretariaEditarPerfilComponent, 
    SecretariaAlterarPacienteComponent, 
    ModalConsultasDiariaComponent, 
    ModalAdicionarExameComponent, 
  ],

  imports: [
    CommonModule,
    SecretariaRoutingModule, 
    SharedModule,
    CalendarModule,
    CoreModule,
    PipeModule,

    /** FormsModule */
    FormsModule,
    ReactiveFormsModule, 
    
    RbFabSpeedDialModule,
  
    /** Angular material */
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatDatepickerModule, 
    MatCardModule,
  ], 
  
  entryComponents: [
    ModalConsultasDiariaComponent,
    ModalAdicionarExameComponent,
  ]
})
export class SecretariaModule { }
