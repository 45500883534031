import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MedicoService } from '../../medico.service';
import { AlertsService } from 'app/shared/alerts.service';
import { FormGroup } from '@angular/forms';
import { Paciente, CartaoGestante } from 'app/core/interfaces/pacient-info.interface';
import { ModalsService } from 'app/shared/modals.service';
import { APIGestaCardService } from 'app/core/api/gestacard.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-medico-alterar-paciente',
  templateUrl: './medico-alterar-paciente.component.html',
  styleUrls: ['./medico-alterar-paciente.component.scss']
})
export class MedicoAlterarPacienteComponent implements OnInit, AfterViewInit {

  loading = true; 
  email_paciente: string; 
  paciente_id: string; 
  paciente: Paciente; 

  emails_medicos: string[];
  secretariaLogada: boolean;

  form: FormGroup; 

  @ViewChild('imageUploader') image_uploader; 
  @ViewChild('cadastro_paciente') componente_cadastro_paciente; 
  
  constructor(
    private route: ActivatedRoute, 
    private authService: AuthService,
    private router: Router, 
    private medicoService: MedicoService, 
    private snackService: AlertsService, 
    private modalService: ModalsService, 
    private _apigestacard: APIGestaCardService, 
  ) {

    try {

      const navigation = this.router.getCurrentNavigation();

      if (!navigation) this.router.navigateByUrl('/medico');
      if (!navigation.extras || !navigation.extras.state) this.router.navigateByUrl('/medico');
      
      const state = navigation.extras.state;
      if (!state.paciente || !state.id) this.router.navigateByUrl('/medico');

      this.email_paciente = state ? state.paciente : null; 
      this.paciente_id = state ? state.id : null; 
      this.secretariaLogada = this.authService.tipo_usuario === 'secretaria' ? true : false;

    } catch(err) {

      console.log('MedicoAlterarCartaoGestanteComponent -> constructor -> err', err);
      this.router.navigateByUrl('/medico');

    }

  }

  ngOnInit() {
    this.AtualizarEmailMedicos(); 
  }

  ngAfterViewInit() {
    this.form = this.componente_cadastro_paciente.form; 

    if (this.paciente_id) {
      this.BuscarPaciente(this.paciente_id);
    }
  }

  /** Buscar dados no firebase */
  AtualizarEmailMedicos() {
    this.medicoService.GetListaEmailsMedicos().subscribe((res) => {
      this.emails_medicos = res; 
    });
  }

  BuscarPaciente(id) {
     
    this.loading = true; 
    this.medicoService.GetPacientePorId(id).then((res) => {

      // Travando letra maiuscula no nome do plano
      if ('qual_plano' in res) { res['qual_plano'] = res['qual_plano'].toUpperCase(); }
      
      this.paciente = res; 
       

      // Atualizando os dados do form com os dados do usuario
      if (this.paciente) { this.form.patchValue(this.paciente); } 



      // Desabilitando alguns campos que não podem ser mudados 
      this.form.controls.email.disable(); 
      this.form.controls.medico_app.disable(); 
    }).catch((err) => {
       
    }).finally(() => {
      this.loading = false; 
    });
  }

  async Alterar() {

    if (this.form.valid) {

      var novo_objeto_paciente = { ...this.paciente, ...this.form.getRawValue() };

      // Salvar o dado no db
      if (this.image_uploader.foto_mudou) {

        try {

          var imagem = this.image_uploader.imagePath;
          var image_to_upload = imagem[0];
          var url_imagem = await this._apigestacard.UploadImage(image_to_upload);

          novo_objeto_paciente['url'] = url_imagem;

        } catch(err) {

          this.snackService.SnackMessage('Erro ao atualizar a imagem do paciente'); 
          return; 

        }

      }

      delete novo_objeto_paciente['cartao_gestante'];

      this.medicoService.AtualizarPaciente(novo_objeto_paciente).then((res) => {

        this.snackService.SnackMessage('Paciente atualizado com sucesso!'); 

        this.router.navigateByUrl('/medico'); 

      }).catch((err) => {

        this.snackService.SnackMessage('Erro ao atualizar o paciente'); 

      })

    }

  }

  AbrirCartaoGestante(cartao: CartaoGestante, cartao_id: string = null, email_paciente: string = null) {
     
    var navigationExtras: NavigationExtras;
    
    if (cartao != null) {

      navigationExtras = {
        state: {
          cartao_id:  cartao.id, 
          email_paciente: this.email_paciente
        }
      }; 

    } else {

      navigationExtras = {
        state: {
          cartao_id,
          email_paciente
        }
      };
      
    }

    this.router.navigate(['/medico-alterar-cartao-gestante'], navigationExtras);

  }

  AdicionarNovoCartao() {     

    this.medicoService.AdicionarCartaoAoPaciente(this.paciente.id)
      .then((res) => {
        
        this.snackService.SnackMessage("Cartão adicionado com sucesso");
        //this.BuscarPaciente(this.paciente_id);  
        this.AbrirCartaoGestante(null, res.id, this.paciente.email); 

      })
      .catch(err => this.snackService.SnackMessage(err)); 
  }

  DeletarPaciente() {
    this.modalService.AbrirModalDeConfirmacao('Excluir Paciente', 'Tem certeza que deseja excluir esse paciente? Essa ação não poderá ser desfeita!, deseja continuar?').subscribe((res: boolean) => {
      if (res) {
        this.medicoService.DeletarPaciente(this.email_paciente, this.paciente_id).then((res) => {
          this.snackService.SnackMessage('Usuario excluido com sucesso!'); 
          this.router.navigate(['/medico']); 
        });
      }
    });
  }

  ResetPassword() {

    this.modalService.AbrirModalDeConfirmacao('Redefinir Senha', 'Confirma o envio do link de redefinição de senha para o usuário, no email  ' + this.email_paciente + '?').subscribe((res: boolean) => {
      if (res) {
      this.medicoService.EmailResetPaciente(this.email_paciente).then((res) => {
        this.snackService.SnackMessage('Link de definição de senha enviado para o e-mail da paciente: ' + this.email_paciente); 
        this.router.navigate(['/medico']); 
      });
      }
    });

  }

  /** NAO EH POSSIVEL ALTERAR O EMAIL DO USUARIO EM OUTRA CONEXAO, SO QUANDO O MESMO ESTIVER LOGADO
  AlterarEmail() {

    this.modalService.AbrirModalAlterarEmail(this.paciente.email).subscribe((novoEmail:string) => {
      if(novoEmail) {

        this._apigestacard.AtualizarEmailPaciente(this.paciente.email, novoEmail).then(res => {
          this.snackService.SnackMessage('E-mail do paciente alterado com sucesso, novo e-mail: '+novoEmail+'.'); 
          this.router.navigate(['/medico']); 
        }, err => {
          this.snackService.SnackMessage(err); 
        });

      }
    });

  }
   */

  Voltar() {
    this.router.navigateByUrl('/medico');
  }

}
