import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalsService } from 'app/shared/modals.service';
import * as moment from 'moment';

@Component({
  selector: 'app-medico-encerrar-cartao',
  templateUrl: './medico-encerrar-cartao.component.html',
  styleUrls: ['./medico-encerrar-cartao.component.scss']
})
export class MedicoEncerrarCartaoComponent implements OnInit {

  form: FormGroup = new FormGroup({
    fim_data: new FormControl(new Date(), Validators.required),
    fim_hora: new FormControl(moment().format('HH:ss'), Validators.required),
    fim_parto: new FormControl('', Validators.required),
    fim_info: new FormControl(''),
    fim_rn_tsabo: new FormControl(''),
    fim_rn_rh: new FormControl(''),
    fim_rn_peso: new FormControl(''),
    fim_rn_estatura: new FormControl(''),
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MedicoEncerrarCartaoComponent>,
    private modalService: ModalsService
  ) {



  }

  ngOnInit() {

    if ('cartao' in this.data) {
      if (this.data.cartao.encerrado == true) { this.form.patchValue(this.data.cartao); } // Edição do encerramento, carregar os dados.
    }

  }

  Encerrar() {

    if (this.form.valid) {
      this.modalService.AbrirModalDeConfirmacao('Confirma Encerramento ?', 'Você tem certeza que deseja salvar essas informações no cartão ?  * Os dados inseridos não poderão mais ser alterados *  ')
        .subscribe((res) => {
          if (res) {
            this.dialogRef.close(this.form.value);
          }
        });
    }

  }

  Cancelar() {

    this.dialogRef.close();

  }

}
