import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MedicoPacientesComponent } from './subpages/medico-pacientes/medico-pacientes.component';
import { MedicoCadastrarPacienteComponent } from './subpages/medico-cadastrar-paciente/medico-cadastrar-paciente.component';
import { MedicoEditarPerfilComponent } from './subpages/medico-editar-perfil/medico-editar-perfil.component';
import { MedicoAlterarPacienteComponent } from './subpages/medico-alterar-paciente/medico-alterar-paciente.component';
import { MedicoAlterarCartaoGestanteComponent } from './subpages/medico-alterar-cartao-gestante/medico-alterar-cartao-gestante.component';
import { MedicoGuard } from 'app/core/guards/medico.guard';
import { MedicoSecretariasComponent } from './subpages/medico-secretarias/medico-secretarias.component';
import { MedicoCadastrarSecretariaComponent } from './subpages/medico-cadastrar-secretaria/medico-cadastrar-secretaria.component';
import { MedicoAlterarSecretariaComponent } from './subpages/medico-alterar-secretaria/medico-alterar-secretaria.component';

const routes: Routes = [
  {
    path: 'medico', 
    component: MedicoPacientesComponent, 
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-cadastrar-pacientes', 
    component: MedicoCadastrarPacienteComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-cadastrar-secretarias', 
    component: MedicoCadastrarSecretariaComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-alterar-pacientes', 
    component: MedicoAlterarPacienteComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-alterar-secretarias', 
    component: MedicoAlterarSecretariaComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-alterar-cartao-gestante', 
    component: MedicoAlterarCartaoGestanteComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-editar-perfil', 
    component: MedicoEditarPerfilComponent,
    canActivate: [MedicoGuard]
  },
  {
    path: 'medico-secretarias', 
    component: MedicoSecretariasComponent,
    canActivate: [MedicoGuard]
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MedicoRoutingModule { }
