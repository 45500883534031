import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-inserir-outro',
    templateUrl: './inserir-outro.component.html',
    styleUrls: ['./../modals-inserir.scss']
})
export class InserirOutroComponent implements OnInit {

    form: FormGroup = new FormGroup({
        data: new FormControl(new Date()),
        exame: new FormControl(''),
        resultado: new FormControl('')
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InserirOutroComponent>
    ) { }

    ngOnInit() {
    }

    Salvar() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }

}
