import { NgModule } from '@angular/core';
import { PeopleFilterPipe } from './filter.pipe';
import { TimestampToDate } from './timestamp.pipe';
import { IgPipe } from './ig.pipe';

@NgModule({
    imports: [],
    declarations: [
        PeopleFilterPipe,
        TimestampToDate, IgPipe
    ],
    exports: [
        PeopleFilterPipe,
        TimestampToDate,
        IgPipe
    ],
})

export class PipeModule {

    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
} 
