import { Component, OnInit } from '@angular/core';
import { SecretariaService } from '../../secretaria.service';
import { NavigationExtras, Router } from '@angular/router';
import { AlertsService } from 'app/shared/alerts.service';
import { CartaoContasService } from 'app/shared/components/cartao-gestante/cartao-contas.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Paciente } from 'app/core/interfaces/pacient-info.interface';

@Component({
  selector: 'app-secretaria-pacientes',
  templateUrl: './secretaria-pacientes.component.html',
  styleUrls: ['./secretaria-pacientes.component.scss']
})
export class SecretariaPacientesComponent implements OnInit {

  loading: boolean = true; 
  pacientes: Array<Paciente> = [];

  page = 1;
  limit = 12;
  total = 0;
  totalPages = 0;
  totalInPage = 0;

  form: FormGroup;

  constructor(
    private secretariaService: SecretariaService, 
    private router: Router,
    private alerts: AlertsService,
    private contasService: CartaoContasService, 
  ) { }


  ngOnInit() {

    this.form = new FormGroup({
      name: new FormControl(''),
      ativado: new FormControl(true),
      encerrado: new FormControl(false)
    });

    let stringFilter = localStorage.getItem('formPacientesFilter');

    if (stringFilter && stringFilter != '') {
      try {
        let formData = JSON.parse(stringFilter);

        this.form.setValue({ 
          name: formData.name,
          ativado: formData.ativado,
          encerrado: formData.encerrado 
        })
      } catch(error) {
        console.error(error)
        console.error(stringFilter, 'error JSON');
      }
    } 

    this.AtualizarInformacoes();

  }

  scrollToTop(): void {
    if (document.getElementById('container-3')) {
      document.getElementById('container-3').scrollTo({ top: 0 , behavior: 'smooth' })
    }
  }

  AtualizarInformacoes(page: number = null) {

    if (page) this.page = page;
    this.loading = true;
    this.scrollToTop();

    this.saveFormInLocalstorage();

    this.secretariaService.GetUserData().subscribe((dados) => {

      if (dados && dados.id) {
        this.secretariaService.GetListaPacientePage(dados.id, this.page, this.limit, this.form.controls.name.value, this.form.controls.ativado.value, this.form.controls.encerrado.value).subscribe((pagination) => {

          this.pacientes = pagination.data;
          this.page = pagination.page
          this.total = pagination.total;
          this.totalPages = pagination.totalPages;
          this.totalInPage = pagination.totalInPage;

          this.loading = false;

        }, err => {

          this.loading = false;

        });
      }

    });

  }

  saveFormInLocalstorage() {
    let formData = {
      name: this.form.controls.name.value,
      ativado: this.form.controls.ativado.value,
      encerrado: this.form.controls.encerrado.value,
    }

    localStorage.setItem('formPacientesFilter', JSON.stringify(formData));
  }

  visibleBtnClear() {
    
    return this.form.controls.name.value != '' || 
      (this.form.controls.ativado.value != true && this.form.controls.ativado.value != 'true') || 
      (this.form.controls.encerrado.value != false && this.form.controls.ativado.value != 'false');

  }

  async VerCartao(item) {
    try {
      var cartao_ativo = await this.secretariaService.LerUltimoCartao(item.id);

      if (cartao_ativo == null) {

        this.alerts.SnackMessage('Usuário não possui um cartão ativo'); 

      } else {

        var navigationExtras: NavigationExtras = {
          state: {
            cartao_id:  cartao_ativo.id, 
            email_paciente: item.email, 
            paciente_id: item.id
          }
        }; 

        this.router.navigate(['/secretaria-alterar-cartao-gestante'], navigationExtras);
        
      }

    } catch (error) {

    }

  }

  EditarPaciente(item) {
     
    var navigationExtras: NavigationExtras = {
      state: {
        paciente: item.email, 
        id: item.id
      }
    }; 

    this.router.navigate(['/secretaria-alterar-pacientes'], navigationExtras);

  }

  async NovaConsulta(item) {
    try {
      var cartao_ativo = await this.secretariaService.LerCartaoAtivoPaciente(item.id);

      if(cartao_ativo == null) {
        this.alerts.SnackMessage('Usuário não possui um cartão ativo'); 
      } else {
        var extras: NavigationExtras = {
          state: {
            paciente: item, 
            cartao: cartao_ativo, 
          }
        };
        
        this.router.navigate(['/secretaria-nova-consulta'], extras);
      }
    } catch (error) {
         
    }
  }

  ValorSemanasGestacaoDum(valor, parto?: string | Date) {
    var aux = this.contasService.SemanasGestacaoDum(valor, parto);
    return isNaN(aux) ? null : aux; 
  }

  ValorSemanasGestacaoUs(us: string | Date, semanas: string, dias: string, parto?: string | Date) {
    var aux = this.contasService.SemanasGestacaoUs(us, semanas, dias, parto);
    return isNaN(aux) ? null : aux; 
  }
  
  PrevisaoGestacaoDum(valor) {
    var aux = this.contasService.PrevisaoDum(valor);
    return aux;
  }

  PrevisaoGestacaoUSG(us, semanas, dias) {
    var aux = this.contasService.PrevisaoUltrassom(us, semanas, dias);
    return aux;
  }

  limparFiltro() {
    this.form = new FormGroup({
      name: new FormControl(''),
      ativado: new FormControl(true),
      encerrado: new FormControl(false)
    });

    this.AtualizarInformacoes();
  }
}
